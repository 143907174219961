import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators, FormControl } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { merge, Subject } from 'rxjs';
import { AccountsCreateComponent } from '../../accounts-create.component';
import { DropdownsService } from 'app/services';
import { AccountsService } from 'app/services/accounts.service';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'tab-financials',
  templateUrl: './tab-financials.component.html',
  styleUrls: ['./tab-financials.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class TabFinancialsComponent implements OnInit {

  form: FormGroup;
  viewRecord: boolean;
  participant: any;
  nameSearch: FormControl = new FormControl();
  searching: boolean;
  currencies: any;
  // tslint:disable-next-line: variable-name
  financial_accounts: FormArray;
  nameSearch$ = new Subject<string>();

  accNames = [];
  id: any;

  constructor(
    private route: ActivatedRoute,
    private _accountsService: AccountsService,
    private formBuilder: FormBuilder,
    private _dropdownsService: DropdownsService,
    private _accountsCreateComponent: AccountsCreateComponent
  ) {
    this.form = this._accountsCreateComponent.form;
  }

  ngOnInit(): void {
    this.financial_accounts = this.form.get('financial_accounts') as FormArray;
    if (this.financial_accounts.length === 0) {
      this.financial_accounts.push(this._accountsCreateComponent.createFinancialAcc());
    }

    this._dropdownsService.getCurrencies().subscribe((data: any) => {
      this.currencies = data;
    });

    this.form.get('currency').valueChanges.subscribe(() => {
    });

    merge(this.form.get('available_cash').valueChanges, this.form.get('other_assets').valueChanges, this.form.get('liabilities').valueChanges)
    .subscribe(() => {
      this.form.get('net_worth').setValue(((1 * this.form.get('available_cash').value + 1 * this.form.get('other_assets').value) - 1 * this.form.get('liabilities').value));
    });
    this.getAccountNameList();
  }

  getAccountNameList() {
    let objName = {};
    objName = {
      name: this.form.get('forename').value+' '+this.form.get('surname').value 
    }
    this.accNames.push(objName);
    for(let i=0; i < this.form.get('partners').value.length; i++){
      let forename = this.form.get('partners').value[i].forename
      let surname = this.form.get('partners').value[i].surname
      if(this.form.get('type').value == 'family'){
        if(forename && surname){
          objName = {
            name: forename+' '+surname, 
            type: 'company'
          }
          this.accNames.push(objName);
        }
      }
    }
    this.accNames.push('');
    this.participant = this.accNames;
  }
}


