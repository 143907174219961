import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// import { AuthGuard } from 'app/_guards';

const routes: Routes = [
    // {
    //   path: 'sample',
    //   loadChildren: () => import('./main/sample/sample.module').then(mod => mod.SampleModule),
    //   canActivate: [AuthGuard]
    // },
    {
        path: 'dashboard',
        loadChildren: () => import('./main/dashboard/dashboard.module').then(mod => mod.DashboardModule),
    },
    {
        path: 'mls-dashboard',
        loadChildren: () => import('./main/mls-dashboard/mls-dashboard.module').then(mod => mod.MlsDashboardModule),
    },
    {
        path: 'activities',
        loadChildren: () => import('./main/activities/activities.module').then(mod => mod.ActivitiesModule),
    },
    {
        path: 'master-data',
        loadChildren: () => import('./main/master-data/master-data.module').then(mod => mod.MasterDataModule),
    },
    {
        path: 'master-data',
        children: [
            {
                path: 'owners',
                loadChildren: () => import('./main/master-data/owners/owners.module').then(mod => mod.OwnersModule),
            },
            {
                path: 'companies',
                loadChildren: () => import('./main/master-data/companies/companies.module').then(mod => mod.CompaniesModule),
            },
            {
                path: 'urbanisation',
                loadChildren: () => import('./main/master-data/urbanisations/urbanisation.module').then(mod => mod.UrbanisationModule),
            },
        ]
    },
    {
        path: 'templates',
        loadChildren: () => import('./main/templates/templates.module').then(mod => mod.TemplatesModule),
    },
    {
        path: 'system-configurations',
        loadChildren: () => import('./main/system-configuration/system-configurations.module').then(mod => mod.SystemConfigurationsModule),
    },
    {
        path: 'holiday-rentals',
        loadChildren: () => import('./main/holiday-rentals/holiday-rentals.module').then(mod => mod.HolidayRentalsModule),
    },
    {
        path: 'properties',
        loadChildren: () => import('./main/properties/properties.module').then(mod => mod.PropertiesModule),
    },
    {
        path: 'moorings',
        loadChildren: () => import('./main/moorings/moorings.module').then(mod => mod.MooringsModule),
    },
    {
        path: 'boats',
        loadChildren: () => import('./main/moorings/moorings.module').then(mod => mod.MooringsModule),
    },
    {
        path: 'contracts',
        loadChildren: () => import('./main/contracts/contracts.module').then(mod => mod.ContractsModule),
    },
    {
        path: 'tools',
        loadChildren: () => import('./main/tools/tools.module').then(mod => mod.ToolsModule),
    },
    {
        path: 'commercials',
        loadChildren: () => import('./main/commercials/commercials.module').then(mod => mod.CommercialsModule),
    },
    {
        path: 'constructions',
        loadChildren: () => import('./main/constructions/constructions.module').then(mod => mod.ConstructionsModule),
    },
    {
        path: 'commercial-constructions',
        loadChildren: () => import('./main/obj-construction/obj-constructions.module').then(mod => mod.ObjConstructionsModule),
    },
    {
        path: 'exporter',
        loadChildren: () => import('./main/exporter/exporter.module').then(mod => mod.ExporterModule),
    },
    {
        path: 'imports',
        loadChildren: () => import('./main/importer/importer.module').then(mod => mod.ImporterModule),
    },
    {
        path: 'reporting',
        loadChildren: () => import('./main/reporting/reporting.module').then(mod => mod.ReportingModule),
    },
    {
        path: 'commercial-reporting',
        loadChildren: () => import('./main/commercial-reporting/reporting.module').then(mod => mod.ReportingModule),
    },
    {
        path: 'properties',
        loadChildren: () => import('./main/property-information/property-information.module').then(mod => mod.PropertyInformationModule),
    },
    // {
    //     path: 'users',
    //     loadChildren: () => import('./main/authentication/users/users.module').then(mod => mod.UsersModule),
    // },
    {
        path: 'users',
        loadChildren: () => import('./main/authentication/users/users.module').then(mod => mod.UsersModule),
    },
    {
        path: 'agencies',
        loadChildren: () => import('./main/authentication/agencies/agencies.module').then(mod => mod.AgenciesModule),
    },
    // {
    //     path: 'offices',
    //     loadChildren: () => import('./main/authentication/offices/offices.module').then(mod => mod.OfficesModule),
    // },
    {
        path: 'offices',
        loadChildren: () => import('./main/authentication/offices/offices.module').then(mod => mod.OfficesModule),
    },
    {
        path: 'sites_settings',
        loadChildren: () => import('./main/cms-sites/cms-sites.module').then(mod => mod.CmsSitesModule),
    },
    {
        path: 'all-notifications',
        loadChildren: () => import('./main/tools/notification/notifications.module').then(mod => mod.NotificationsModule),
    },
    {
        path: 'login',
        loadChildren: () => import('./main/authentication/login/login.module').then(mod => mod.LoginModule),
    },
    {
        path: 'forgot-password',
        loadChildren: () => import('./main/authentication/forgot-password/forgot-password.module').then(mod => mod.ForgotPasswordModule),
    },
    {
        path: 'new-password',
        loadChildren: () => import('./main/authentication/new-password/new-password.module').then(mod => mod.NewPasswordModule),
    },
    {
        path: 'register',
        loadChildren: () => import('./main/authentication/register/register.module').then(mod => mod.RegisterModule),
    },
    {
        path: 'mls-register',
        loadChildren: () => import('./main/authentication/mls-register/mls-register.module').then(mod => mod.MlsRegisterModule),
    },
    {
        path: 'tourist',
        loadChildren: () => import('./main/tourist-info/tourist-info.module').then(mod => mod.TouristInfoModule),
    },
    {
        path: 'market-place',
        loadChildren: () => import('./main/marketplace/marketplace.module').then(mod => mod.MarketplaceModule),
    },
    {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
    }

];

@NgModule({
    imports: [
        RouterModule.forRoot(routes)
    ],
    exports: [RouterModule],
    providers: []
})
export class AppRoutingModule { }
