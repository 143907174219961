import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TimeLineDataService {

  private modelDataSubject: BehaviorSubject<any>;
  public modelData: Observable<any>;
  private relatedToSubject: BehaviorSubject<any>;
  public relatedTo: Observable<any>;
  
  public defaultTabSubject: BehaviorSubject<any>;
  public defaultTab: Observable<any>;

  constructor() {
    this.modelDataSubject = new BehaviorSubject<any>({});
    this.modelData = this.modelDataSubject.asObservable();
    this.relatedToSubject = new BehaviorSubject<any>('');
    this.relatedTo = this.relatedToSubject.asObservable();
    this.defaultTabSubject = new BehaviorSubject<any>('Activities')
    this.defaultTab = this.relatedToSubject.asObservable();
  }

  public get modelDataValue(): any {
    return this.modelDataSubject.value;
  }

  public setModelDataValue(data: any): any {
    this.modelDataSubject.next(data);
  }

  public get relatedToValue(): any {
    return this.relatedToSubject.value;
  }

  public setRelatedToValue(data: any): any {
    this.relatedToSubject.next(data);
  }
  public get defaultTabValue(): any {
    return this.defaultTabSubject.value
  }

  public setDefaultTab(data: any): any {
    this.defaultTabSubject.next(data)
  }

}
