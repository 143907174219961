import { HttpClient } from '@angular/common/http';
import { Directive, ElementRef, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CountriesService, CitiesService, ProvincesService, RegionsService, DropdownsService } from 'app/services';
import { find, forEach } from 'lodash';
// import { merge } from 'rxjs';
// import { switchMap } from 'rxjs/operators';
declare var google: any;

@Directive({
    selector: '[googlePlaces]'
})
export class GooglePlacesDirective implements OnInit {

    @Input() address: FormControl = new FormControl('');
    // To-do: Needed to remove country, region & province currently using to avoid from build errors
    @Input() country: FormControl;
    @Input() region: FormControl;
    @Input() province: FormControl;
    @Input() city: FormControl;
    @Input() community: FormControl;
    @Input() sub_community: FormControl;
    @Input() postcode: FormControl;
    @Input() street: FormControl;
    // tslint:disable-next-line: variable-name
    @Input() street_number: FormControl;
    @Input() latitude: FormControl;
    @Input() longitude: FormControl;

    @Output() selectAddress: EventEmitter<any> = new EventEmitter();
    private element: HTMLInputElement;

    filter: any;
    cityData: any;
    communityData: any;
    subCommunityData: any;

    constructor(
        private elRef: ElementRef,
        private _dropdownService: DropdownsService,
        private http: HttpClient
    ) {
        this.filter = {
            options: { page: 1, limit: 30, sort: {} }
        };
        this.element = this.elRef.nativeElement;

    }

    ngOnInit(): void {


        const autocomplete = new google.maps.places.Autocomplete(this.element);
        google.maps.event.addListener(autocomplete, 'place_changed', async () => {
            // Emit the new address object for the updated place
            this.selectAddress.emit(autocomplete.getPlace());
            let address = autocomplete.getPlace();
            // let countryName: any;
            let cityName: any;
            let communityName: any;
            let subCommunityName: any;
            let postalCode: any;
            let streetName: any;
            let streetNumber: any;

            // Here 220 is the value for UAE
            if (this.country) { this.country.setValue(220); }
            if (this.city) { this.city.setValue(null); }
            if (this.community) { this.community.setValue(null); }
            if (this.sub_community) { this.sub_community.setValue(null); }
            if (this.postcode) { this.postcode.setValue(null); }
            if (this.street) { this.street.setValue(null); }
            if (this.street_number) { this.street_number.setValue(null); }
            if (this.latitude) { this.latitude.setValue(null); }
            if (this.longitude) { this.longitude.setValue(null); }

            address = await this.getAddress(address);

            setTimeout(() => {
                postalCode = find(address.address_components, (component: any) => component.types.indexOf('postal_code') > -1);
                streetName = find(address.address_components, (component: any) => component.types.indexOf('route') > -1);
                streetNumber = find(address.address_components, (component: any) => component.types.indexOf('street_number') > -1);
                if (postalCode && this.postcode) { this.postcode.setValue(postalCode.long_name); }
                if (streetName && this.street) { this.street.setValue(streetName.long_name); }
                if (streetNumber && this.street_number) { this.street_number.setValue(streetNumber.long_name); }
                if (address.formatted_address && this.address) { this.address.setValue(address.formatted_address); }
                // countryName = find(address.address_components, (component: any) => component.types.indexOf('country') > -1);
                cityName = find(address.address_components, (component: any) => component.types.indexOf('administrative_area_level_1') > -1);
                communityName = find(address.address_components, (component: any) => component.types.indexOf('sublocality_level_1') > -1);
                subCommunityName = find(address.address_components, (component: any) => component.types.indexOf('neighborhood') > -1);
                
                
                // TODO: if province and country is same than check either province and region are correct or need to assign region value to province and assign correct valu to region
                // if(countryName?.long_name == 'Netherlands' && (regionName?.long_name == 'Zuid-Holland' || regionName?.long_name == 'South Holland')){
                //     ProvinceName.long_name = regionName.long_name;
                //     ProvinceName.short_name = regionName.short_name
                //     regionName.long_name = 'West Holland';
                //     regionName.short_name = 'WH';
                // }
                if (this.longitude) { 
                    if(typeof address.geometry.location.lng == 'function')
                        this.longitude.setValue(address.geometry.location.lng()); 
                    else
                        this.longitude.setValue(address.geometry.location.lng); 
                }
                if (this.latitude) { 
                    if(typeof address.geometry.location.lat == 'function')
                        this.latitude.setValue(address.geometry.location.lat()); 
                    else
                        this.latitude.setValue(address.geometry.location.lat); 
                }
                /** Calling APi's */
                if (cityName && this.city) {
                    this.filter.query = {
                        'value.en': { $regex: '.*' + cityName.long_name + '.*', $options: 'i' }
                    };
                    this._dropdownService.getUaeLocations(this.filter, 0, 0)
                        .subscribe((data: any) => {
                            if (data.docs[0]) {
                                this.cityData = data.docs[0];
                                this.city.setValue(this.cityData.key);
                                
                                // Get Community Data
                                if (communityName && this.community) {
                                    this.filter.query = {
                                        'value.en': { $regex: '.*' + communityName.long_name + '.*', $options: 'i' }
                                    };
                                    this._dropdownService.getUaeLocations(this.filter, this.cityData.key, 1)
                                        .subscribe((data: any) => {
                                            if (data.docs[0]) {
                                                this.communityData = data.docs[0];
                                                this.community.setValue(this.communityData.key);

                                                // Get Sub Community data
                                                if (subCommunityName && this.sub_community) {
                                                    this.filter.query = {
                                                        'value.en': { $regex: '.*' + subCommunityName.long_name.replace(/(.)(?=.*\1)/g, '.*?') + '.*', $options: 'i' }
                                                    };
                                                    this._dropdownService.getUaeLocations(this.filter, this.communityData.key, 2)
                                                        .subscribe((data: any) => {
                                                            if (data.docs[0]) {
                                                                this.subCommunityData = data.docs[0];
                                                                this.sub_community.setValue(this.subCommunityData.key);
                                                            }
                                                        });
                                                }
                                            }
                                        });
                                }
                            }
                        });
                }

                /** Calling APi's */
                // if (countryName && this.country) {
                //     // this.filter.query = { 'value.en': { $regex: '.*' + countryName.long_name + '.*', $options: 'i' } };
                //     this._dropdownService.getCountries(countryName.long_name)
                //         .subscribe((data: any) => {
                //             if (data.docs[0]) {
                //                 this.countryData = data.docs[0];
                //                 this.country.setValue(this.countryData.key);
                //             }
                //         });
                // }

                // if (ProvinceName && this.province) {
                //     // this.filter.query = { 'value.en': { $regex: '.*' + ProvinceName.long_name + '.*', $options: 'i' } };
                //     this._dropdownService.getProvinces(ProvinceName.long_name)
                //         .subscribe((data: any) => {
                //             if (data.docs[0]) {
                //                 this.provinceData = data.docs[0];
                //                 this.province.setValue(this.provinceData.key);
                //             }
                //         });
                // }

                // if (regionName && this.region) {
                //     // this.filter.query = { 'value.en': { $regex: '.*' + regionName.long_name + '.*', $options: 'i' } };
                //     this._dropdownService.getRegions(regionName.long_name)
                //         .subscribe((data: any) => {
                //             if (data.docs[0] && this.region) {
                //                 this.regionData = data.docs[0];
                //                 this.region.setValue(this.regionData.key);
                //             }
                //         });
                // }

                // if (cityName && this.city) {
                //     merge(this.province.valueChanges, this.region.valueChanges)
                //         .pipe(switchMap(() => {
                //             return this._dropdownService.getCities(cityName.long_name, false, this.region.value, this.province.value);
                //         }))
                //         .subscribe((data: any) => {
                //             if (data.docs[0]) {
                //                 this.cityData = data.docs[0];
                //                 if (cityName.long_name === 'Ronda') {
                //                     forEach(data.docs, (content: any) => {
                //                         if ((content.value.en === cityName.long_name || content.value.es_AR === cityName.long_name)
                //                             && content.country === this.country.value) {
                //                             this.city.setValue(content.key);
                //                         }
                //                     });

                //                 }
                //                 else {
                //                     this.city.setValue(this.cityData.key);
                //                 }
                //                 if (this.region && !this.region.value) {
                //                     this.region.setValue(this.cityData.region);
                //                 }
                //                 if (this.province && !this.province.value) {
                //                     this.province.setValue(this.cityData.province);
                //                 }
                //             }
                //         });
                // }
            }, 2000);
        });
    }

    getAddress(address) {
        return new Promise((resolve, reject) => {
            let query = '';
            if (address.formatted_address) {
                query = address.formatted_address;
            } else if (address.name) {
                query = address.name;
            }
            if (query) {
                this.http.get('https://maps.googleapis.com/maps/api/geocode/json?address=' +
                    query + '&key=AIzaSyDVox2ev99XdZmnSD6tIe6ysqNqoWSZ4YY')
                    .subscribe(async (coord_results: any) => {
                        resolve(coord_results?.results[0])
                    });
            } else {
                resolve(address);
            }
        })

    }
}
