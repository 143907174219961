import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import * as _ from 'lodash';

@Injectable()
export class AppProviders {

    private languagesSubject: BehaviorSubject<any>;
    public languages: Observable<any>;
    private langIdsSubject: BehaviorSubject<any>;
    public langIds: Observable<any>;
    private menuItemsSubject: BehaviorSubject<any>;
    public menuItems: Observable<any>;

    constructor(private http: HttpClient) {
        this.languagesSubject = new BehaviorSubject<any>(null);
        this.languages = this.languagesSubject.asObservable();
        this.langIdsSubject = new BehaviorSubject<any>(null);
        this.langIds = this.langIdsSubject.asObservable();
        this.menuItemsSubject = new BehaviorSubject<any>(null);
        this.menuItems = this.menuItemsSubject.asObservable();
    }

    public get siteLanguages(): any {
        return this.languagesSubject.value;
    }

    public get siteLangIds(): any {
        return this.langIdsSubject.value;
    }

    public get siteMenuItems(): any {
        return this.menuItemsSubject.value;
    }

    load(): any {
        return new Promise((resolve, reject) => {
            Promise.all([
                this.getLanguages(),
                this.getMenu()
            ]).then(() => resolve(), reject);
        });
    }

    getLanguages(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.http
                .get(`${environment.apiUrl}crm/active_languages`)
                .pipe(
                    map((res: any) => {
                        this.languagesSubject.next(res);
                        this.langIdsSubject.next(_.map(res, 'id'));
                        return res;
                    })
                )
                .subscribe(() => resolve(true));
        });
    }
    getMenu(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.http
                .get(`${environment.apiUrl}crm_menu/accessible`)
                .pipe(
                    map((res: any) => {
                        this.menuItemsSubject.next(res);
                        return res;
                    })
                )
                .subscribe(() => resolve(true));
        });
    }
}
