import { Component, OnInit, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { indexOf } from 'lodash';
import { UserService, AuthenticationService } from 'app/services';
import { GLOBALS } from 'app/config/globals';

@Component({
    selector: 'windowcards-email',
    templateUrl: './windowcards-email.component.html',
    styleUrls: ['./windowcards-email.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class WindowcardsEmailComponent implements OnInit {

    form: FormGroup;
    addCcBcc = false;
    signature: boolean;
    @Output() sendEmail: EventEmitter<any> = new EventEmitter();
    currentUser: any;
    currentAgency: any;
    public tinyMce = GLOBALS.tinyMce; // To use centralized tinyMce
    constructor(
        private _formBuilder: FormBuilder,
        private _userService: UserService,
        private _authenticationService: AuthenticationService,
    ) {
        this.currentUser = this._authenticationService.currentUserValue;
        this.currentAgency = this._authenticationService.currentAgencyValue;
        this.form = this._formBuilder.group({
            from: ['', Validators.required],
            to: ['', Validators.required],
            cc: [],
            bcc: [],
            subject: ['', Validators.required],
            e_template: [],
            add: [],
            message: [''],
        });
    }

    ngOnInit(): void {
        this.form.get('from').setValue(this.currentUser.user_email);
        if (this.currentUser.mailbox_user && this.currentUser.mailbox_user.username) {
            this.form.get('from').setValue(this.currentUser.mailbox_user.username);
        }
        this.form.get('add').valueChanges
            .subscribe((add) => {
                if (indexOf(add, 'cc/bcc') > -1 && !this.addCcBcc) {
                    this.addCcBcc = true;
                }
                if (indexOf(add, 'cc/bcc') === -1 && this.addCcBcc) {
                    this.addCcBcc = false;
                }
                if (indexOf(add, 'signature') > -1 && !this.signature) {
                    this.signature = true;
                    this.addSignature();
                }
                if (indexOf(add, 'signature') === -1 && this.signature) {
                    this.signature = false;
                }
            });
    }

    addSignature(): void {
        this._userService.getById(this._authenticationService.currentUserValue._id)
            .subscribe((data: any) => {
                let message = this.form.get('message').value;
                message = message + '<br>' + data.signature;
                this.form.get('message').setValue(message);
            });
    }

}
