import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseDirectivesModule } from '@fuse/directives/directives';
import { AppDirectivesModule } from 'app/directives/directives';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { WhatsappMessageComponent } from './whatsapp-message.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { WhatsappConfirmSendDialogComponent } from './whatsapp-confirm-send-dialog/whatsapp-confirm-send-dialog.component';
@NgModule({
  declarations: [WhatsappMessageComponent,
    WhatsappConfirmSendDialogComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatToolbarModule,
    MatSelectModule,
    MatButtonModule,
    MatTableModule,
    MatInputModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    NgxMatSelectSearchModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatDialogModule,
    MatDialogModule,
    MatSnackBarModule,
    TranslateModule,
    FlexLayoutModule,
    FuseDirectivesModule,
    AppDirectivesModule,
    FormsModule,
    ReactiveFormsModule,
    EditorModule
  ],
  exports: [WhatsappMessageComponent],
  providers: [
    { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' }
],
  entryComponents: [WhatsappMessageComponent]
})
export class WhatsappMessageModule { }
