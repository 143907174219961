import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { MatCardModule } from '@angular/material/card';
import { MatTableModule } from '@angular/material/table';
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import { MatDialogModule } from '@angular/material/dialog';
import { NgxStripeModule } from 'ngx-stripe';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { SubscribtionComponent } from './subscribtion.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { environment } from 'environments/environment';

// import { RegisterComponent } from 'app/main/pages/authentication/register/register.component';



@NgModule({
    declarations: [SubscribtionComponent],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,

        FlexLayoutModule,
        TranslateModule,

        NgxStripeModule.forRoot(environment.stripeKey),
        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatSelectModule,
        MatStepperModule,
        MatCardModule,
        MatTableModule,
        MatDialogModule,
        MatSnackBarModule,
        MatProgressSpinnerModule,
        MatPasswordStrengthModule,
        NgxMatSelectSearchModule
    ],
    exports: [SubscribtionComponent],
    entryComponents: [SubscribtionComponent],
})
export class SubscribtionModule {
}
