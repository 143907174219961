import { Component, OnInit, ViewEncapsulation, ViewChild, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MeetingCreateFormComponent } from '../meetings-create/meeting-create-form/meeting-create-form.component';
import { AuthenticationService, MeetingsService } from 'app/services';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { TimeLineDataService } from 'app/components/time-line/time-line-data.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FormArray } from '@angular/forms';
import { forEach } from 'lodash';
import { WebsocketService } from 'app/services/websocket.service';
@Component({
    selector: 'app-meeting-create-modal',
    templateUrl: './meeting-create-modal.component.html',
    styleUrls: ['./meeting-create-modal.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class MeetingCreateModalComponent implements OnInit {

    data: any;
    @ViewChild(MeetingCreateFormComponent, { static: true }) meetingCreateForm: MeetingCreateFormComponent;
    borderColor: string;
    meeting: FormArray;
    participants: FormArray;
    toggle = false as boolean;
    isClick = false as boolean;
    currentUser: any;
    constructor(
        public matDialogRef: MatDialogRef<MeetingCreateModalComponent>,
        private _meetingsService: MeetingsService,
        private _snackBar: MatSnackBar,
        private _translateService: TranslateService,
        private _timeLineData: TimeLineDataService,
        private socket: WebsocketService,
        private _fuseSidebarService: FuseSidebarService,
        private _authenticationService: AuthenticationService,
        @Inject(MAT_DIALOG_DATA) public _data: any
    ) {
        this.currentUser = this._authenticationService.currentUserValue;
        if (!_.isEmpty(this._data)) {
            this.data = this._data;
            if (this.data && this.data.data && this.data.data.color) {
                this.borderColor = this.data.data.color;
            } else if (this.data && this.data.color) {
                this.borderColor = this.data.color;
            }
        }
    }
    ngOnInit(): void {
        if (!_.isEmpty(this.data)) {
            if (this.data.relatedTo === 'update') {
                setTimeout(() => {
                    if (this.data.data._id) {
                        this._meetingsService.getById(this.data.data._id).subscribe((data: any) => {
                            this.meetingCreateForm.activity.patchValue(data);
                            this.meeting = this.meetingCreateForm.activity.get('meeting') as FormArray;
                            this.participants = this.meetingCreateForm.activity.get('participants') as FormArray;
                            this.meetingCreateForm.clearFormArray(this.meeting);
                            if (data.meeting && data.meeting.length) {
                                forEach(data.meeting, (extra, key) => {
                                    this.meeting.push(this.meetingCreateForm.createMeeting());
                                    this.meetingCreateForm.activity.get('meeting.' + key).patchValue(extra);
                                });
                            } else {
                                this.meeting.push(this.meetingCreateForm.createMeeting());
                            }
                            this.meetingCreateForm.loadAccountsData(0);
                            this.meetingCreateForm.clearFormArray(this.participants);
                            if (data.participants && data.participants.length) {
                                forEach(data.participants, (extra, key) => {
                                    this.participants.push(this.meetingCreateForm.createOpening());
                                    this.meetingCreateForm.activity.get('participants.' + key).patchValue(extra);
                                });
                            } else {
                                this.participants.push(this.meetingCreateForm.createOpening());
                            }
                        });
                    }
                    else {
                        this.meetingCreateForm.activity.patchValue(this.data.data);
                        if (this.data.data.ical) {
                            this.meetingCreateForm.viewRecord = this.data.data.ical;
                        }
                    }
                }, 200);
            }
            else if (this.data.relatedTo === 'calendar') {
                setTimeout(() => {
                    this.meetingCreateForm.activity.get('start_date').setValue(this.data.date);
                    this.meetingCreateForm.activity.get('end_date').setValue(this.data.date);
                }, 200);
            }
            else if (this.data.relatedTo === 'accounts') {
                setTimeout(() => {
                    this.meetingCreateForm.meeting.controls[0].get('related_to').setValue('Account');
                    this.meetingCreateForm.meeting.controls[0].get('account').setValue(this.data.accountId);
                    this.meetingCreateForm.loadAccount(this.data.accountId);
                }, 200);
            } else if (this.data.relatedTo === 'owners') {
                setTimeout(() => {
                    this.meetingCreateForm.meeting.controls[0].get('related_to').setValue('Owner');
                    this.meetingCreateForm.meeting.controls[0].get('owner').setValue(this.data.ownerId);
                    this.meetingCreateForm.loadOwner(this.data.ownerId);
                }, 200);
            }
            else if (this.data.relatedTo === 'commercial_properties') {
                setTimeout(() => {
                    this.meetingCreateForm.meeting.controls[0].get('related_to').setValue('Commercial Properties');
                    this.meetingCreateForm.meeting.controls[0].get('commercial_property').setValue(this.data.accountId);
                }, 200);
            } else {
                this._meetingsService.getById(this.data._id).subscribe((data: any) => {
                    this.meetingCreateForm.activity.patchValue(data);
                    this.participants = this.meetingCreateForm.activity.get('participants') as FormArray;
                    this.meetingCreateForm.clearFormArray(this.participants);
                    if (data.participants && data.participants.length) {
                        forEach(data.participants, (extra, key) => {
                            this.participants.push(this.meetingCreateForm.createOpening());
                            this.meetingCreateForm.activity.get('participants.' + key).patchValue(extra);
                        });
                    } else {
                        this.participants.push(this.meetingCreateForm.createOpening());
                    }
                    this.meetingCreateForm.loadAccountsData(0);
                    this.meetingCreateForm.viewRecord = true;
                });
            }
        }
    }

    submitForm(): void {
        this.isClick = true;
        this.meetingCreateForm.activity.get('type').setValue('meeting');
        if (this.borderColor) {
            this.meetingCreateForm.activity.get('color').setValue(this.borderColor);
        }
        if (!_.isEmpty(this.data)) {
            if (this.meetingCreateForm.activity.valid) {
                this.meetingCreateForm.activity.get('type').setValue('meeting');
                // if (this.data.relatedTo === 'update') {
                if (this.data.data) {
                    this._meetingsService.update(this.meetingCreateForm.activity.getRawValue()).subscribe((data: any) => {
                        this.socket.emit('meeting', {
                            created_by: this.currentUser._id, updated_by: this.currentUser._id,
                            agency: this.currentUser.agency, participants: this.meetingCreateForm.activity.getRawValue()?.participants,
                            created_name: this.currentUser.full_name, status: 'update', model_id: data._id
                        });
                        this.matDialogRef.close('refresh');
                    }, (err) => {
                        this.isClick = false;
                    });
                } else if (this.data._id) {
                    this._meetingsService.update(this.meetingCreateForm.activity.getRawValue()).subscribe((data: any) => {
                        this.socket.emit('meeting', {
                            created_by: this.currentUser._id, updated_by: this.currentUser._id,
                            agency: this.currentUser.agency, participants: this.meetingCreateForm.activity.getRawValue()?.participants,
                            created_name: this.currentUser.full_name, status: 'update', model_id: data._id
                        });
                        this.matDialogRef.close('refresh');
                    }, (err: any) => {
                        this.isClick = false;
                    });
                } else {
                    this._meetingsService.create(this.meetingCreateForm.activity.getRawValue()).subscribe((data: any) => {
                        this.socket.emit('meeting', {
                            created_by: this.currentUser._id, updated_by: this.currentUser._id,
                            agency: this.currentUser.agency, participants: this.meetingCreateForm.activity.getRawValue()?.participants,
                            created_name: this.currentUser.full_name, status: 'create', model_id: data._id
                        });
                        this.matDialogRef.close('refresh');
                        this._snackBar.open(this._translateService.instant('Created successfully'), this._translateService.instant('Close'), {
                            duration: 2000,
                        });
                    }, (err: any) =>{
                        this.isClick = false;
                    });
                }
            } else {
                this.meetingCreateForm.activity.markAllAsTouched();
                this.isClick = false;
            }
        } else {
            this._meetingsService.create(_.pickBy(this.meetingCreateForm.activity.getRawValue(), _.identity)).subscribe((data: any) => {
                this.socket.emit('meeting', {
                    created_by: this.currentUser._id, updated_by: this.currentUser._id,
                    agency: this.currentUser.agency, participants: this.meetingCreateForm.activity.getRawValue()?.participants,
                    created_name: this.currentUser.full_name, status: 'create', model_id: data._id
                });
                this.matDialogRef.close();
            }, (err: any) => {
                this.isClick = false;
            });
        }


    }
    onOpenTimeline(event: any): void {
        this._timeLineData.setModelDataValue(event);
        this._timeLineData.setRelatedToValue('activities');
        this._fuseSidebarService.getSidebar('timeline').toggleOpen(1000000);
    }
}
