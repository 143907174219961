import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmailDraftsComponent } from './email-drafts.component';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseDirectivesModule } from '@fuse/directives/directives';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatMenuModule } from '@angular/material/menu';
import { MatFormFieldModule } from '@angular/material/form-field';



@NgModule({
  declarations: [EmailDraftsComponent],
  imports: [
    CommonModule,
    MatTableModule,
    MatToolbarModule,
    MatTooltipModule,
    MatIconModule,
    MatButtonModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    MatFormFieldModule,
    TranslateModule,
    FlexLayoutModule,
    FuseDirectivesModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [EmailDraftsComponent],
  entryComponents: [EmailDraftsComponent]
})
export class EmailDraftsModule { }
