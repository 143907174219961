import { Component, OnInit, ViewEncapsulation, Inject, ViewChild } from '@angular/core';
import { SalesService, AuthenticationService } from 'app/services';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { GlobalFuntions } from 'app/_helpers';
import { AttachmentsService } from 'app/services/attachments.service';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { Subject, merge } from 'rxjs';
import { startWith, tap, switchMap, takeUntil, filter, map } from 'rxjs/operators';
import { fuseAnimations } from '@fuse/animations';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-sales-create-modal',
  templateUrl: './sales-create-modal.component.html',
  styleUrls: ['./sales-create-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class SalesCreateModalComponent implements OnInit {

  sale: FormGroup;
  user: any;
  initial: any;
  id: any;
  saleData: any;
  viewRecord = false;
  sales: any = {};
  private _unsubscribeAll: Subject<any>;
  displayedColumns: string[] = ['created_at', 'title', 'sale', 'property', 'name', 'commercial_name', 'action'];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  dataReload: FormControl = new FormControl(null);
  loadingResults: boolean;
  selectAll = false;
  filter: any = {};
  
  constructor(
    public matDialogRef: MatDialogRef<SalesCreateModalComponent>,
    @Inject(MAT_DIALOG_DATA) public _data: any,
    private _salesService: SalesService,
    private formBuilder: FormBuilder,
    private router: Router,
    private _globalFunctions: GlobalFuntions,
    private _authenticationService: AuthenticationService,
    private _attachmentsService: AttachmentsService,
    private mediaObserver: MediaObserver,
    ) {
      this.user = this._authenticationService.currentUserValue;
      this._unsubscribeAll = new Subject();
      this.filter.options = {
        page: 1,
        limit: 10,
      };
    }

  ngOnInit(): void {

    if (this._data._id) {
      this.id = this._data._id;
      this._salesService.getById(this._data._id).subscribe((data: any) => {
        this.sale.patchValue(data.body);
        this.saleData = data.body;
      });
    }

    this.sale = this.formBuilder.group({
      related_to: { value: '', disabled: this.viewRecord },
      property_reference: { value: '', disabled: this.viewRecord },
      offer_id: { value: '', disabled: this.viewRecord },
      listing_agency_id: { value: '', disabled: this.viewRecord },
      listing_agent_id: { value: '', disabled: this.viewRecord },
      own_property: { value: '', disabled: this.viewRecord },
      company_agency_id: { value: '', disabled: this.viewRecord },
      sales_price: { value: '', disabled: this.viewRecord },
      listed_price: { value: '', disabled: this.viewRecord },
      currency: { value: '', disabled: this.viewRecord },
      offices: { value: '', disabled: this.viewRecord },
      owner: { value: '', disabled: this.viewRecord },
      account: { value: '', disabled: this.viewRecord },
      vat: { value: '', disabled: this.viewRecord },
      status: { value: '', disabled: this.viewRecord },
      companies: this.formBuilder.array([]),
      tottal_commission_value: { value: '', disabled: this.viewRecord },
      tottal_commission_amount: { value: '', disabled: this.viewRecord },
      tottal_vat_on_commission: { value: '', disabled: this.viewRecord },
      tottal_commission_payout: { value: '', disabled: this.viewRecord },
      commission: { value: '', disabled: this.viewRecord },
      commission_in_euro: { value: '', disabled: this.viewRecord },
      commission_in_iva: { value: '', disabled: this.viewRecord },
      commission_payout: { value: '', disabled: this.viewRecord },
      commission_value_one: { value: '', disabled: this.viewRecord },
      commission_out_one_euro: { value: '', disabled: this.viewRecord },
      commission_out_one_iva: { value: '', disabled: this.viewRecord },
      commission_payout_one: { value: '', disabled: this.viewRecord },
      commission_value_two: { value: '', disabled: this.viewRecord },
      commission_out_two_euro: { value: '', disabled: this.viewRecord },
      commission_out_two_iva: { value: '', disabled: this.viewRecord },
      commission_payout_two: { value: '', disabled: this.viewRecord },
      commission_out_listing_percent: { value: '', disabled: this.viewRecord },
      commission_out_listing_agent_net: { value: '', disabled: this.viewRecord },
      commission_out_listing_agent_vat: { value: '', disabled: this.viewRecord },
      commission_out_listing_agent_total: { value: '', disabled: this.viewRecord },
      commission_out_selling_percent: { value: '', disabled: this.viewRecord },
      commission_out_selling_agent_net: { value: '', disabled: this.viewRecord },
      commission_out_selling_agent_vat: { value: '', disabled: this.viewRecord },
      commission_out_selling_agent_total: { value: '', disabled: this.viewRecord },
      commission_out_three_euro: { value: '', disabled: this.viewRecord },
      commission_out_three_iva: { value: '', disabled: this.viewRecord },
      commission_payout_three: { value: '', disabled: this.viewRecord },
      commission_net: { value: '', disabled: this.viewRecord },
      commission_net_iva: { value: '', disabled: this.viewRecord },
      commission_total_vat: { value: '', disabled: this.viewRecord },
      comments: { value: '', disabled: this.viewRecord },
      created_by: (''),
      created_at: (''),
      _id: (''),
      sale_id: ('')
    });

    this.mediaObserver.asObservable()
      .pipe(
        takeUntil(this._unsubscribeAll),
        filter((changes: MediaChange[]) => changes.length > 0),
        map((changes: MediaChange[]) => changes[0])
      )
      .subscribe((change: MediaChange) => {
        if (change.mqAlias === 'xs') {
          this.displayedColumns = ['created_at', 'title', 'action'];
        } else {
          this.displayedColumns = ['created_at', 'title', 'sale', 'property', 'name', 'commercial_name', 'action'];
        }
      });
      
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange,
      this.paginator.page,
      this.dataReload.valueChanges)
      .pipe(
        startWith(''),
        tap(() => this.loadingResults = true),
        takeUntil(this._unsubscribeAll),
        switchMap(() => {

          if (this.dataReload.value === 'refresh') {
            this.paginator.pageIndex = 0;
            this.sort.active = null;
            this.sort.direction = '';
          }
          if (this.sort.active && this.sort.direction) {
            this.filter.sort = [this.sort.active, (this.sort.direction === 'asc' ? 'SORT_ASC' : 'SORT_DESC')];
          }
          this.filter.query = {
            for: 'Sales Stages',
          };
          this.dataReload.setValue(null, { emitEvent: false });
          return this._salesService.getSalesStages(this.filter);
        })
      )
      .subscribe((data: any) => {
        this.loadingResults = false;
        this.sales.data = data.body;
        this.sales.total = data.total;
        this.sales.limit = this.paginator.pageSize;
      }, () => this.loadingResults = false);
  }

  submitForm(): any {
    this._salesService.save(this._globalFunctions.cleanObject(this.sale.value), this.id).subscribe((data: any) => {
      this.matDialogRef.close();
    });
  }

}
