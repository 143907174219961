import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FaqsService } from 'app/services/faqs.service';
import { FormControl } from '@angular/forms';
import { merge, Subject } from 'rxjs';
import { startWith, tap, switchMap, debounceTime, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { FuseUtils } from '@fuse/utils';
@Component({
  selector: 'user-faqs',
  templateUrl: './user-faqs.component.html',
  styleUrls: ['./user-faqs.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UserFaqsComponent implements OnInit {
  loadingResults = false;
  faqsArray = [];
  faqs: any;
  subFaqs: any;
  systemlang: any;
  searchInput: FormControl = new FormControl('');
  currentLang: string;
  private _unsubscribeAll: Subject<any>;

  public tinyMc = {
    base_url: '/tinymce', suffix: '.min', inline: true,
  };
  constructor(
    public _translateService: TranslateService,
    private _faqService: FaqsService
  ) {
    this.systemlang = this._translateService.currentLang;
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {

    this._translateService.onLangChange
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        this.systemlang = this._translateService.currentLang;
      });

    merge(this.searchInput.valueChanges)
      .pipe(
        debounceTime(500),
        startWith(''),
        tap(() => this.loadingResults = true),
        switchMap(() => {
          return this._faqService.getGroups();
        })
      )
      .subscribe((data: any) => {
        this.faqsArray = [];
        this.faqs = data.body;
        for (const faq of this.faqs) {
          const faqsGroup = {
            content: {},
            faqSubGroup: []
          };
          faqsGroup.content = faq.content;
          this.faqsArray.push(faqsGroup);
        }
        this._faqService.getSubGroupsWithData()
          .subscribe((subFaqsData: any) => {
            this.subFaqs = subFaqsData.body;
            if (this.searchInput.value !== '') {
              this.subFaqs = FuseUtils.filterArrayByString(this.subFaqs, this.searchInput.value);
            }
            for (const sub of this.subFaqs) {
              // faqsSubGroup.content = sub.content;
              // faqsSubGroup.title = sub.title;
              for (const item of this.faqsArray) {
                if (item?.content?.en?.replace(/\s/g, '').toLowerCase() === sub?.faq_group?.replace(/\s/g, '').toLowerCase()) {
                  const faqsSubGroup = {
                    content: {},
                    title: {}
                  };
                  faqsSubGroup.content = sub.content;
                  faqsSubGroup.title = sub.title;
                  item.faqSubGroup.push(faqsSubGroup);
                }
              }
            }
            if (this.searchInput.value !== '') {
              this.faqsArray = FuseUtils.filterArrayByString(this.faqsArray, this.searchInput.value);
            }
            this.loadingResults = false;
            // console.log(this.faqsArray);
          }, () => this.loadingResults = false);
      });
  }
}
