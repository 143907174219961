import { Component, OnInit, ViewEncapsulation, NgZone, ViewChild, ElementRef, Input, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { fuseAnimations } from '@fuse/animations';
import { MapsAPILoader } from '@agm/core';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { filter, map } from 'rxjs/operators';
import { AuthenticationService } from './../../../services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
declare var google: any;

@Component({
  selector: 'app-address-map',
  templateUrl: './address-map.component.html',
  styleUrls: ['./address-map.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})

export class AddressMapComponent implements OnInit {

  longitude: any;
  latitude: any;
  lat = 23.4241;
  lng = 53.8478;
  zoom = 7;
  markerLat = null;
  markerLng = null;
  private geoCoder: any;
  address: any;
  compAddress: any;
  coordinates = [] as any;
  mapHeight: any;
  mqAlias: string;
  loadingResults = false;

  @ViewChild('search', { static: true })
  public searchElementRef: ElementRef;
  private _unsubscribeAll: Subject<any>;

  constructor(
    public matDialogRef: MatDialogRef<AddressMapComponent>,
    private mapsAPILoader: MapsAPILoader,
    private mediaObserver: MediaObserver,
    private _authenticationService: AuthenticationService,
    private ngZone: NgZone,
    @Inject(MAT_DIALOG_DATA) public _data: any
  ) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    const agency = JSON.parse(localStorage.getItem('currentAgency'));
    if (agency === null) {
      const roMap = JSON.parse(localStorage.getItem('roMap'))
      if(roMap != null) {
        this.checkData("roMap", roMap)
      }
    }else{
      this.checkData("", agency)
    }
  }

  checkData(model, data) {
    if (data?.map?.lat && data?.map?.lng) {
      this.lat = parseFloat(data.map.lat);
      this.lng = parseFloat(data.map.lng);
      if(data.map.zoop){
        this.zoom = +data.map.zoom;
      }
    }

    if(model != "roMap"){
      this._authenticationService.currentOffice
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((office: any) => {
            if(office?.map){
                if (office.map.lat) {
                  this.lat = parseFloat(office.map.lat);
                }
                if (office.map.lng) {
                  this.lng = parseFloat(office.map.lng);
                }
                if (office.map.zoom) {
                  this.zoom = +office.map.zoom;
                }
            }
        });
    }
    this.mediaObserver.asObservable()
      .pipe(
        filter((changes: MediaChange[]) => changes.length > 0),
        map((changes: MediaChange[]) => changes[0])
      )
      .subscribe((change: MediaChange) => {
        this.mqAlias = change.mqAlias;
        let adjustScreen = 265;
        if (this.mqAlias === 'xl' || this.mqAlias === 'gt-lg') {
          adjustScreen = 500;
        }
        this.mapHeight = (window.innerHeight - adjustScreen) + 'px';
      });

    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder();
      if (this._data.address) {
        this.loadingResults = true;
        this.loadAddress(this._data);
      } else if (this._data.longitude && this._data.latitude && !this._data.address) {
        this.markerLat = this._data.latitude.value;
        this.markerLng = this._data.longitude.value;
        this.lat = this.markerLat;
        this.lng = this.markerLng;
        this.getAddress(this.markerLat, this.markerLng);
      }
      // else if (!this._data.longitude && !this._data.latitude && !this._data.address) {
      //   this.markerLat = this.lat;
      //   this.markerLng = this.lng;
      //   this.getAddress(this.markerLat, this.markerLng);
      // }
    });
  }
  onMapReady(): void {
    if (this._data.latitude && this._data.longitude) {
      this.lat = parseFloat(this._data.latitude.value);
      this.lng = parseFloat(this._data.longitude.value);
    }
  }
  mapClicked($event: any): void {
    this.markerLat = $event.coords.lat;
    this.markerLng = $event.coords.lng;
    this.getAddress(this.markerLat, this.markerLng);
  }

  getAddress(latitude: any, longitude: any): void {
    this.geoCoder.geocode({ location: { lat: latitude, lng: longitude } }, (results: any, status: any) => {
      if (status === 'OK') {
        if (results[0]) {
          this.address = results[0].address_components;
          this.compAddress = results[0].formatted_address;
        } else {
          return;
        }
      } else {
        return;
      }
      this.coordinates['lat'] = latitude;
      this.coordinates['lng'] = longitude;
    });
  }

  loadAddress(_data: any): void {
    this.geoCoder.geocode({ address: _data.address }, (results: any, status: any) => {
      let lat = _data?.latitude?.value;
      let lng = _data?.longitude?.value;
      if (status === 'OK') {
        this.zoom = 13;
        this.address = results[0].address_components;
        this.compAddress = results[0].formatted_address;
        // this.coordinates['lat'] = results[0].geometry.location.lat();
        // this.coordinates['lng'] = results[0].geometry.location.lng();
        // this.coordinates['lat'] = results[0].geometry.location.lat();
        // this.coordinates['lng'] = results[0].geometry.location.lng();
        // this.lat = results[0].geometry.location.lat();
        // this.lng = results[0].geometry.location.lng();
        // this.markerLat = results[0].geometry.location.lat();
        // this.markerLng = results[0].geometry.location.lng();

        //https://gitlab.optimasit.com/arsl/optima-crm-v2/-/issues/2810
        if(!lat){
          lat = results[0].geometry.location.lat();
        }
        if(!lng){
          lng = results[0].geometry.location.lng();
        }
        this.coordinates['lat'] = lat;
        this.coordinates['lng'] = lng;
        this.lat = lat;
        this.lng = lng;
        this.markerLat = lat;
        this.markerLng = lng;
        this.loadingResults = false;
      } else {
        this.loadingResults = false;
        return;
      }
    });
  }



}

