import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { find, flow, property, partialRight, some, map as lodashMap, forEach, isArray } from 'lodash';
import { AuthenticationService } from './authentication.service';


@Injectable({
    providedIn: 'root'
})
export class CrmMenuService {
    private propertyModuleSubject: BehaviorSubject<any>;
    public propertyModule: Observable<any>;
    private holidayRentalModuleSubject: BehaviorSubject<any>;
    public holidayRentalModule: Observable<any>;
    private commHolidayRentalModuleSubject: BehaviorSubject<any>;
    public commHolidayRentalModule: Observable<any>;
    private longTermRentalModuleSubject: BehaviorSubject<any>;
    public longTermRentalModule: Observable<any>;
    private commercialModuleSubject: BehaviorSubject<any>;
    public commercialModule: Observable<any>;
    private invoiceModuleSubject: BehaviorSubject<any>;
    public invoiceModule: Observable<any>;
    private viewingTourModuleSubject: BehaviorSubject<any>;
    public viewingTourModule: Observable<any>;
    private mooringModuleSubject: BehaviorSubject<any>;
    public mooringModule: Observable<any>;
    private boatModuleSubject: BehaviorSubject<any>;
    public boatModule: Observable<any>;
    private urbanisationModuleSubject: BehaviorSubject<any>;
    public urbanisationModule: Observable<any>;
    private supportTicketsModuleSubject: BehaviorSubject<any>;
    public supportTicketsModule: Observable<any>;

    constructor(
        private http: HttpClient,
        private _authenticationService: AuthenticationService,
    ) {
        this.propertyModuleSubject = new BehaviorSubject<any>(false);
        this.propertyModule = this.propertyModuleSubject.asObservable();
        this.holidayRentalModuleSubject = new BehaviorSubject<any>(false);
        this.holidayRentalModule = this.holidayRentalModuleSubject.asObservable();
        this.commHolidayRentalModuleSubject = new BehaviorSubject<any>(false);
        this.commHolidayRentalModule = this.commHolidayRentalModuleSubject.asObservable();
        this.longTermRentalModuleSubject = new BehaviorSubject<any>(false);
        this.longTermRentalModule = this.longTermRentalModuleSubject.asObservable();
        this.commercialModuleSubject = new BehaviorSubject<any>(false);
        this.commercialModule = this.commercialModuleSubject.asObservable();
        this.invoiceModuleSubject = new BehaviorSubject<any>(false);
        this.invoiceModule = this.invoiceModuleSubject.asObservable();
        this.viewingTourModuleSubject = new BehaviorSubject<any>(false);
        this.viewingTourModule = this.viewingTourModuleSubject.asObservable();
        this.mooringModuleSubject = new BehaviorSubject<any>(false);
        this.mooringModule = this.mooringModuleSubject.asObservable();
        this.boatModuleSubject = new BehaviorSubject<any>(false);
        this.boatModule = this.boatModuleSubject.asObservable();
        this.urbanisationModuleSubject = new BehaviorSubject<any>(false);
        this.urbanisationModule = this.urbanisationModuleSubject.asObservable();
        this.supportTicketsModuleSubject = new BehaviorSubject<any>(false);
        this.supportTicketsModule = this.supportTicketsModuleSubject.asObservable();
    }

    public get isPropertyModule(): any {
        return this.propertyModuleSubject.value;
    }

    public get isHolidayRentalModule(): any {
        return this.holidayRentalModuleSubject.value;
    }

    public get isCommHolidayRentalModule(): any {
        return this.commHolidayRentalModuleSubject.value;
    }

    public get isLongTermRentalModule(): any {
        return this.longTermRentalModuleSubject.value;
    }

    public get isCommercialModule(): any {
        return this.commercialModuleSubject.value;
    }
    public get isInvoiceModule(): any {
        return this.invoiceModuleSubject.value;
    }
    public get isMooringModule(): any {
        return this.mooringModuleSubject.value;
    }
    public get isBoatModule(): any {
        return this.boatModuleSubject.value;
    }
    public get isUrbanisationModule(): any {
        return this.urbanisationModuleSubject.value;
    }
    public get isSupportTicketsModule(): any {
        return this.supportTicketsModuleSubject.value;
    }


    getData(filter: any): Observable<any[]> {
        return this.http.post<any[]>(`${environment.apiUrl}crm_menu`, filter);
    }

    createData(data: any): Observable<object> {
        delete data._id;        
        delete data.id;        
        return this.http.post(`${environment.apiUrl}crm_menu/create`, data);
    }

    updateData(data: any): Observable<object> {
        return this.http.put(`${environment.apiUrl}crm_menu/update/${data._id}`, data);
    }

    deleteData(data: any): Observable<any> {
        return this.http.delete(`${environment.apiUrl}crm_menu/delete/${data._id}`, data);
    }

    getListData(filter: any): Observable<object> {
        return this.http.post<any[]>(`${environment.apiUrl}crm_menu`, filter);
    }

    getChildItems(search?: string): Observable<any[]> {
        search = search ? search : '';
        return this.http.get<any[]>(`${environment.apiUrl}crm_menu/child_items?search=${search}`);
    }

    getMenuStructure(all?: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.http
                .post(`${environment.apiUrl}crm_menu/structure${(all ? all : '')}`, {})
                .pipe(
                    map((res: any) => {
                        // const propertyModule = find(res, flow(property('children'), partialRight(some, { url: '/properties/index' })));
                        let holidayRentalModule = find(res, flow(property('children'), partialRight(some, { url: '/properties/holiday-rentals' })));
                        if (!holidayRentalModule) {
                            holidayRentalModule = find(res, flow(property('children'), partialRight(some, { url: 'properties/holiday-rentals' })));
                        }
                        // let commHolidayRentalModule =  find(res, flow(property('children'), partialRight(some, { url: 'commercials/holiday-rentals' })));     
                        //const longTermRentalModule = find(res, flow(property('children'), partialRight(some, { url: '/properties/long-term-rentals' })));
                        // this check added for commercial in Rental tab for LT Rental 
                        // let longTermRentalModule = find(res, flow(property('children'), partialRight(some, { url: '/properties/long-term-rentals' })));
                        // const commercialModule = find(res, flow(property('children'), partialRight(some, { url: '/commercials/index' })));
                        
                        // Agency module settings
                        let propertyModule = false;
                        if (this._authenticationService?.currentAgencyValue?.residential_properties) {
                            propertyModule = true;
                        }
                        let longTermRentalModule = false;
                        if (this._authenticationService?.currentAgencyValue?.rental_contracts_module) {
                            longTermRentalModule = true;
                        }
                        // this will from commercial Rental Price / module
                        let commHolidayRentalModule = false;     
                        if (this._authenticationService?.currentAgencyValue?.booking_module) {
                            commHolidayRentalModule = true;
                        }
                        let commercialModule = false;
                        if (this._authenticationService?.currentAgencyValue?.commercial_properties) {
                            commercialModule = true;
                        }

                        const invoiceModule = find(res, flow(property('children'), partialRight(some, { url: '/templates/invoices/outgoing' })));
                        const viewingTourModule = find(res, flow(property('children'), partialRight(some, { url: '/activities/viewings' })));
                        const mooringModule = find(res, flow(property('children'), partialRight(some, { url: '/moorings/index' })));
                        const boatModule = find(res, flow(property('children'), partialRight(some, { url: '/boats/index' })));
                        const urbanisationModule = find(res, flow(property('children'), partialRight(some, { url: '/master-data/urbanisation/list' })));
                        const supportTicketsModule = find(res, flow(property('children'), partialRight(some, { url: '/tools/support-tickets/list' })));
                        this.propertyModuleSubject.next(propertyModule ? true : false);
                        this.holidayRentalModuleSubject.next(holidayRentalModule ? true : false);
                        this.commHolidayRentalModuleSubject.next(commHolidayRentalModule ? true : false);
                        this.longTermRentalModuleSubject.next(longTermRentalModule ? true : false);
                        this.commercialModuleSubject.next(commercialModule ? true : false);
                        this.invoiceModuleSubject.next(invoiceModule ? true : false);
                        this.mooringModuleSubject.next(mooringModule ? true : false);
                        this.boatModuleSubject.next(boatModule ? true : false);
                        this.urbanisationModuleSubject.next(urbanisationModule ? true : false);
                        this.supportTicketsModuleSubject.next(supportTicketsModule ? true : false);
                        this.viewingTourModuleSubject.next(viewingTourModule ? true : false);
                        return res;
                    })
                )
                .subscribe((data) => resolve(data));
        });
    }

    setMenuAccess(data?: any): Observable<any[]> {
        return this.http.post<any[]>(`${environment.apiUrl}crm_menu/set_access`, data);
    }

    getMenuAccess(data?: any): Observable<any[]> {
        return this.http.post<any[]>(`${environment.apiUrl}crm_menu/get_access`, data);
    }

    getMenuWithUrls(): Observable<any[]> {
        return this.http.get<any[]>(`${environment.apiUrl}crm_menu/with_urls`);
    }

}
