import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TrafficLightComponent } from './traffic-light.component';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatToolbarModule } from '@angular/material/toolbar';



@NgModule({
  declarations: [TrafficLightComponent],
  entryComponents: [TrafficLightComponent],
  imports: [
    CommonModule,
    FuseSharedModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    MatToolbarModule
  ]
})
export class TrafficLightModule { }
