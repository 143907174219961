import { Component, OnInit, Input, AfterViewChecked, ChangeDetectorRef, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, FormArray, FormBuilder, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MeetingsService, DropdownsService, AuthenticationService } from 'app/services';
import { merge, Subject } from 'rxjs';
import { startWith, tap, switchMap } from 'rxjs/operators';
import { forEach, find, map, includes, isString, isEmpty, clone, cloneDeep } from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { fuseAnimations } from '@fuse/animations';
import { GlobalFuntions } from 'app/_helpers';
import * as moment from 'moment';
import { CrmMenuService } from 'app/services/crm-menu.service';
import { GetCommercialReferencePipe } from 'app/pipes/get-commercial-reference.pipe';

function invalidDatesValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        let startDate = clone(this.activity.get('start_date').value);
        let endDate = clone(this.activity.get('end_date').value);
        if (isString(startDate) && isString(endDate) && !isEmpty(startDate) && !isEmpty(endDate)) {
            startDate = new Date(startDate);
            endDate = new Date(endDate);
            if (startDate > endDate) {
                return { invalidDates: { value: control.value } };
            }
        }
        return null;
    };
}
@Component({
    selector: 'app-meeting-create-form',
    templateUrl: './meeting-create-form.component.html',
    styleUrls: ['./meeting-create-form.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
    providers: [GetCommercialReferencePipe]
})
export class MeetingCreateFormComponent implements OnInit, AfterViewChecked {

    @Output() openTimeline: EventEmitter<any>;
    @Input() modal: boolean;
    activity: FormGroup;
    date = (moment()).format();
    public viewRecord: boolean;
    tasks: any = [];
    id: any;
    user: any;
    officeData: any;
    offices: any;
    searchOffices$ = new Subject<string>();
    filter: any;
    meetings: any;
    accounts: any;
    owners: any;
    properties: any;
    companies: any;
    projects: any;
    urbanisations: any;
    sales: any;
    commercials: any;
    bookings: any;
    rentals: any;
    participant: any;
    old_end_date: any;
    nameSearch$ = new Subject<string>();
    projectSearch$ = new Subject<string>();
    urbanisationSearch: FormControl = new FormControl();
    saleSearch: FormControl = new FormControl();
    commercialSearch$ = new Subject<string>();
    bookingSearch: FormControl = new FormControl();
    rentalSearch: FormControl = new FormControl();
    accountSearch$ = new Subject<string>();
    propertySearch$ = new Subject<string>();
    ownerSearch$ = new Subject<string>();
    companySearch$ = new Subject<string>();
    public searching: boolean;
    public countries: any = [];
    public cities: any = [];
    public citySearch: FormControl = new FormControl();
    public communities: any = [];
    public communitySearch: FormControl = new FormControl();
    public subCommunities: any = [];
    public subCommunitySearch: FormControl = new FormControl();
    public locations: any = [];
    public locationSearch: FormControl = new FormControl();
    participants: FormArray;
    meeting: FormArray;
    systemLang: any;
    loadingResults = false as any;
    currentAgency: any;
    mooringSearch: FormControl = new FormControl();
    boatSearch: FormControl = new FormControl();
    mooringsData: any;
    boatsData: any;
    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private route: ActivatedRoute,
        private _meetingsService: MeetingsService,
        private _dropdownsService: DropdownsService,
        private _translateService: TranslateService,
        private cdRef: ChangeDetectorRef,
        private _authenticationService: AuthenticationService,
        public _globalFuntions: GlobalFuntions, // for dateChange
        public _crmMenuService: CrmMenuService,
        private cpReference: GetCommercialReferencePipe
    ) {
        _translateService.onLangChange.pipe(startWith('')).subscribe(() => {
            this.systemLang = this._translateService.currentLang === 'es' ? 'es_AR' : _translateService.currentLang;
        });
        this.openTimeline = new EventEmitter();
        this.user = this._authenticationService.currentUserValue;
        this.officeData = this._authenticationService.currentOfficeValue;
        this.filter = {};
        this.filter.options = {
            page: 1,
            limit: 1000,
            sort: {
                value: 1
            }
        };
        this.filter.query = {
            status: 'Active'
        };
    }

    ngOnInit(): void {

        this._dropdownsService.getTaskTypes(this.filter).subscribe((data: any) => {
            this.tasks = data.docs;
        });

        this._dropdownsService.getMeetingTypes().subscribe((data: any) => {
            this.meetings = data.body;
        });

        let officeRecord = false;
        // this.viewRecord = false;
        if (includes(this.router.url, 'view')) {
            this.viewRecord = true;
            officeRecord = true;
        }

        if (this._globalFuntions.getUserRole(this.user.user_role)) {
            officeRecord = true;
        }

        // tslint:disable-next-line: prefer-const
        let timeFrom = new Date();
        timeFrom.setHours(timeFrom.getHours() + 1);
        const timeTo = new Date();
        timeTo.setHours(timeTo.getHours() + 2);

        this.activity = this.formBuilder.group({

            // Ticket #1152
            // assignee: [{ value: [this.user._id], disabled: this.viewRecord }],
            subject: [{ value: '', disabled: this.viewRecord }, Validators.required],
            private: [{ value: '', disabled: this.viewRecord }],
            status: [{ value: 'Scheduled', disabled: this.viewRecord }],
            start_date: [{ value: this.date, disabled: this.viewRecord }, [invalidDatesValidator]],
            start_time: [{ value: timeFrom, disabled: this.viewRecord }],
            end_date: [{ value: this.date, disabled: this.viewRecord }, [invalidDatesValidator]],
            end_time: [{ value: timeTo, disabled: this.viewRecord }],
            repeate_type: [{ value: 'None', disabled: this.viewRecord }],
            notification: this.formBuilder.group({
                type: [{ value: 'email', disabled: this.viewRecord }],
                duration: [{ value: '30', disabled: this.viewRecord }],
                time: [{ value: 'minutes', disabled: this.viewRecord }],
            }),
            reminder_time: [{ value: '15m', disabled: this.viewRecord }],
            task_type: [{ value: '', disabled: this.viewRecord }],
            task_type_value: this.formBuilder.group({
                en: ('')
            }),
            country: [{ value: '', disabled: this.viewRecord }],
            city: [{ value: '', disabled: this.viewRecord }],
            community: [{ value: '', disabled: this.viewRecord }],
            sub_community: [{ value: '', disabled: this.viewRecord }],
            location: [{ value: '', disabled: this.viewRecord }],
            postal_code: [{ value: '', disabled: this.viewRecord }],
            street: [{ value: '', disabled: this.viewRecord }],
            street_number: [{ value: '', disabled: this.viewRecord }],
            google_map: [{ value: '', disabled: this.viewRecord }],
            comments: [{ value: '', disabled: this.viewRecord }],
            description: [{ value: '', disabled: this.viewRecord }],
            participants: this.formBuilder.array([this.createOpening()]),
            meeting: this.formBuilder.array([this.createMeeting()]),
            offices: [{ value: [this.officeData._id], disabled: officeRecord }, Validators.required],
            meeting_type: [{ value: '', disabled: this.viewRecord }],
            type: [{ value: '', disabled: false }],
            created_at: [{ value: '', disabled: false }],
            created_by: [{ value: '', disabled: false }],
            agency: [{ value: '', disabled: false }],
            _id: [],
            color: [{ value: '', disabled: false }]

        });
        this.participants = this.activity.get('participants') as FormArray;
        this.meeting = this.activity.get('meeting') as FormArray;
        this.currentAgency = this._authenticationService.currentAgencyValue;

        this._dropdownsService.getUaeCountries().subscribe((data: any) => {
            this.countries = data;
        });

        merge(this.citySearch.valueChanges, this.activity.get('city').valueChanges)
            .pipe(
                startWith(''),
                tap(() => this.searching = true),
                switchMap(() => {
                    let search = this.activity.get('city').value;
                    if (this.citySearch.value) {
                        search = this.citySearch.value;
                    }
                    return this._dropdownsService.getUaeLocations(search, 0, 0);
                })
            )
            .subscribe((data: any) => {
                this.searching = false;
                this.cities = data.docs;
            });

            merge(this.communitySearch.valueChanges, this.activity.get('community').valueChanges)
            .pipe(
                startWith(''),
                tap(() => this.searching = true),
                switchMap(() => {
                    let search = this.activity.get('community').value;
                    if (this.communitySearch.value) {
                        search = this.communitySearch.value;
                    }
                    return this._dropdownsService.getUaeLocations(search, this.activity.get('city').value, 1);
                })
            )
            .subscribe((data: any) => {
                this.searching = false;
                this.communities = data.docs;
            });
        
        merge(this.subCommunitySearch.valueChanges, this.activity.get('sub_community').valueChanges)
            .pipe(
                startWith(''),
                tap(() => this.searching = true),
                switchMap(() => {
                    let search = this.activity.get('sub_community').value;
                    if (this.subCommunitySearch.value) {
                        search = this.subCommunitySearch.value;
                    }
                    return this._dropdownsService.getUaeLocations(search, this.activity.get('community').value, 2);
                })
            )
            .subscribe((data: any) => {
                this.searching = false;
                this.subCommunities = data.docs;
            });
            
        merge(this.locationSearch.valueChanges, this.activity.get('location').valueChanges)
            .pipe(
                startWith(''),
                tap(() => this.searching = true),
                switchMap(() => {
                    let search = this.activity.get('location').value;
                    
                    if (this.locationSearch.value) {
                        search = this.locationSearch.value;
                    }
                    return this._dropdownsService.getUaeLocations(search, this.activity.get('sub_community').value, 3);
                })
            )
            .subscribe((data: any) => {
                this.searching = false;
                this.locations = data?.docs;
            });


        this.searchOffices$
            .pipe(
                startWith(''),
                switchMap((search: string) => {
                    return this._dropdownsService.getOffices(search);
                }),
            )
            .subscribe((data: any) => {
                this.offices = data.body;
            });

        this.accountSearch$
            .pipe(
                startWith(''),
                tap(() => this.searching = true),
                switchMap((searchValue: string) => {
                    let search = '';
                    if (searchValue) {
                        search = searchValue;
                    }
                    return this._dropdownsService.getAccounts(search);
                })
            )
            .subscribe((data: any) => {
                this.searching = false;
                const accounts = map(data.body, (element) => {
                    element.label = element?.full_name ? element.full_name.trim() : '';
                    if (!element.label) {
                        element.label = element?.mobile_phone && element.mobile_phone.length > 0 ? element.mobile_phone[0] : '';
                    }
                    return element;
                });
                this.accounts = accounts;
            });

        this.ownerSearch$
            .pipe(
                startWith(''),
                tap(() => this.searching = true),
                switchMap((searchValue: string) => {
                    let search = '';
                    if (searchValue) {
                        search = searchValue;
                    }
                    return this._dropdownsService.getOwners(search, '', false);
                })
            )
            .subscribe((data: any) => {
                this.searching = false;
                const owners = map(data.body, (element) => {
                    if (element.type === 'company') {
                        element.label = element.company_name;
                    } else {
                        element.label = element.full_name;
                    }
                    return element;
                });
                this.owners = owners;
            });

        this.propertySearch$
            .pipe(
                startWith(''),
                tap(() => this.searching = true),
                switchMap((searchValue: string) => {
                    let search = '';
                    if (searchValue) {
                        search = searchValue;
                    }
                    return this._dropdownsService.getProperty(search);
                })
            )
            .subscribe((data: any) => {
                const properties = map(data.body, (property) => {
                    if (this.currentAgency.pdf_external_ref === 'external_reference' && property.agency_reference) {
                        property.label = property.agency_reference;
                    } else if (this.currentAgency.pdf_external_ref === 'agency_reference' && property.external_reference) {
                        property.label = property.external_reference;
                    } else if (this.currentAgency.pdf_external_ref === 'reference') {
                        property.label = property.reference;
                    } else {
                        property.label = property.reference;
                    }
                    if (property.hasOwnProperty('type_one')) {
                        property.label = property.label + ' - ' + property.type_one;
                    }
                    if (property.hasOwnProperty('location')) {
                        property.label = property.label + ' - ' + property.location;
                    }
                    return property;
                });
                this.properties = properties;
                this.searching = false;
            });

        this.commercialSearch$
            .pipe(
                startWith(''),
                tap(() => this.searching = true),
                switchMap((search: string) => {
                    const searchFilter = {} as any;
                    if (search) {
                        const queryOr = [];
                        queryOr.push({ reference: Number(search) });
                        queryOr.push({ external_reference: { $regex: '.*' + search + '.*', $options: 'i' } });
                        queryOr.push({ other_reference: { $regex: '.*' + search + '.*', $options: 'i' } });
                        searchFilter.$or = queryOr;
                    }
                    return this._dropdownsService.getCommercialProperties(searchFilter);
                })
            )
            .subscribe((data: any) => {
                forEach(data.body, (value) => {
                    value.label = this.cpReference.transform(value) + 
                        ((value?.property_type_one) ?
                            ( ' ' + value?.property_type_one?.value[this.systemLang]) : '') + 
                        ((value?.property_city?.value[this.systemLang]) ?
                        ( (' - ') + value?.property_city?.value[this.systemLang]) : '')
                });
                this.searching = false;
                this.commercials = data.body;
            });

        this.companySearch$
            .pipe(
                startWith(''),
                tap(() => this.searching = true),
                switchMap((searchValue: string) => {
                    let search = '';
                    if (searchValue) {
                        search = searchValue;
                    }
                    return this._dropdownsService.getCompany(search);
                })
            )
            .subscribe((data: any) => {
                this.searching = false;
                this.companies = data.body;
            });

        this.projectSearch$
            .pipe(
                startWith(''),
                tap(() => this.searching = true),
                switchMap((searchValue: string) => {
                    let search = '';
                    if (searchValue) {
                        search = searchValue;
                    }
                    return this._dropdownsService.getProject(search);
                })
            )
            .subscribe((data: any) => {
                const projects = map(data.body, (project) => {
                    project.label = this.user.user_agency_data.agency_reference;
                    if (project.hasOwnProperty('reference')) {
                        project.label = project.label + '-' + project.reference;
                    }
                    if (project.hasOwnProperty('agency_reference')) {
                        project.label = project.label + ' / ' + project.agency_reference;
                    }
                    if (project.hasOwnProperty('agency_reference')) {
                        project.label = project.label + ' / ' + project.agency_reference;
                    }
                    return project;
                });
                this.searching = false;
                this.projects = projects;
            });

        this.urbanisationSearch.valueChanges
            .pipe(
                startWith(''),
                tap(() => this.searching = true),
                switchMap((searchValue: string) => {
                    let search = '';
                    if (searchValue) {
                        search = searchValue;
                    }
                    return this._dropdownsService.getUrbanisation(search);
                })
            )
            .subscribe((data: any) => {
                this.searching = false;
                this.urbanisations = data.docs;
            });

        this.saleSearch.valueChanges
            .pipe(
                startWith(''),
                tap(() => this.searching = true),
                switchMap(() => {
                    let search = this.saleSearch.value;
                    if (this.saleSearch.value) {
                        search = this.saleSearch.value;
                    }
                    return this._dropdownsService.getSale();
                })
            )
            .subscribe((data: any) => {
                this.searching = false;
                this.sales = data.body;
            });

        this.rentalSearch.valueChanges
            .pipe(
                startWith(''),
                tap(() => this.searching = true),
                switchMap(() => {
                    let search = this.rentalSearch.value;
                    if (this.rentalSearch.value) {
                        search = this.rentalSearch.value;
                    }
                    return this._dropdownsService.getRental(search);
                })
            )
            .subscribe((data: any) => {
                this.searching = false;
                this.rentals = data.docs;
            });

        this.nameSearch$
            .pipe(
                startWith(''),
                tap(() => this.searching = true),
                switchMap((searchValue: string) => {
                    let search = '';
                    if (searchValue) {
                        search = searchValue;
                    }
                    return this._dropdownsService.getParticipants(search);
                })
            )
            .subscribe((data: any) => {
                this.searching = false;
                this.participant = data.body;
            });
            
            this.mooringSearch.valueChanges
            .pipe(
                startWith(''),
                tap(() => this.searching = true),
                switchMap(() => {
                    const filter = {
                        query: {
                            archived: { $ne: true },
                        } as any,
                        options: {} as any,
                    } as any;
                    if (this.mooringSearch.value) {
                        const queryOr = [];
                        queryOr.push({
                            reference: Number(this.mooringSearch.value),
                        });
                        queryOr.push({
                            type_one: {
                                $regex:
                                    '.*' + this.mooringSearch.value + '.*',
                                $options: 'i',
                            },
                        });
                        // queryOr.push({
                        //     property_city: {
                        //         $regex:
                        //             '.*' + this.mooringSearch.value + '.*',
                        //         $options: 'i',
                        //     },
                        // });
                        filter.query.$or = queryOr;
                    }
                    return this._dropdownsService.getMoorings('mooring_properties',filter);
                })
            )
            .subscribe((data: any) => {
                this.searching = false;
                this.mooringsData = data.body;
            });

            this.boatSearch.valueChanges
            .pipe(
                startWith(''),
                tap(() => this.searching = true),
                switchMap(() => {
                    const filter = {
                        query: {
                            archived: { $ne: true },
                        } as any,
                        options: {} as any,
                    } as any;
                    if (this.boatSearch.value) {
                        const queryOr = [];
                        queryOr.push({
                            reference: Number(this.boatSearch.value),
                        });
                        queryOr.push({
                            type_one: {
                                $regex:
                                    '.*' + this.boatSearch.value + '.*',
                                $options: 'i',
                            },
                        });
                        // queryOr.push({
                        //     property_city: {
                        //         $regex:
                        //             '.*' + this.boatSearch.value + '.*',
                        //         $options: 'i',
                        //     },
                        // });
                        filter.query.$or = queryOr;
                    }
                    return this._dropdownsService.getMoorings('boats', filter);
                })
            )
            .subscribe((data: any) => {
                this.searching = false;
                this.boatsData = data.body;
            });

        this.id = this.route.snapshot.paramMap.get('id');
        if (this.id) {
            this._meetingsService.getById(this.id).subscribe((data: any) => {
                // to remove null values
                data = Object.entries(data).reduce((acc, [key, value]) => {
                  if (value !== null) {
                    acc[key] = value;
                  }
                  return acc;
                }, {});
                this.activity.patchValue(data);
                if (data.address && data.address.formatted_address) {
                    this.activity.get('google_map').setValue(data.address.formatted_address);
                }
                this.clearFormArray(this.participants);
                if (data.participants && data.participants.length) {
                    forEach(data.participants, (extra, key) => {
                        this.participants.push(this.createOpening());
                        this.activity.get('participants.' + key).patchValue(extra);
                    });
                } else {
                    this.participants.push(this.createOpening());
                }

                this.clearFormArray(this.meeting);
                if (data.meeting && data.meeting.length) {
                    forEach(data.meeting, (extra, key) => {
                        this.meeting.push(this.createMeeting());
                        this.activity.get('meeting.' + key).patchValue(extra);
                    });
                } else {
                    this.meeting.push(this.createMeeting());
                }
                this.loadAccountsData(0);
            });
        }
        // merge(this.activity.get('start_date').valueChanges, this.activity.get('end_date').valueChanges)
        //     .subscribe(() => {
        //         let startDate = clone(this.activity.get('start_date').value);
        //         let endDate = clone(this.activity.get('end_date').value);
        //         if (isString(startDate) && isString(endDate) && !isEmpty(startDate) && !isEmpty(endDate)) {
        //             startDate = new Date(startDate);
        //             endDate = new Date(endDate);
        //             console.log(startDate);
        //             console.log(endDate);
        //             if (startDate > endDate) {

        //             }
        //         }
        //     });
    }

    loadAccountsData(index): void {
        if (this.meeting.get(index + '.related_to').value === 'Account') {
            if (this.meeting.get(index + '.account').value) {
                this._dropdownsService.getAccounts('', this.meeting.get(index + '.account').value)
                    .pipe(tap(() => this.searching = true))
                    .subscribe((data: any) => {
                        this.searching = false;
                        const accounts = map(data.body, (element) => {
                            if (element.type === 'company') {
                                element.label = element.company_name;
                            } else {
                                element.label = element.full_name;
                            }
                            return element;
                        });
                        this.accounts = accounts;
                        if (this.meeting.length - 1 > index) {
                            this.loadAccountsData(index + 1);
                        }
                    });
            } else {
                if (this.meeting.length - 1 > index) {
                    this.loadAccountsData(index + 1);
                }
            }
        } else {
            if (this.meeting.length - 1 > index) {
                this.loadAccountsData(index + 1);
            }
        }
    }

    loadAccount(_id: string): void {
        this._dropdownsService.getAccounts('', _id)
            .pipe(tap(() => this.searching = true))
            .subscribe((data: any) => {
                this.searching = false;
                const accounts = map(data.body, (element) => {
                    if (element.type === 'company') {
                        element.label = element.company_name;
                    } else {
                        element.label = element.full_name;
                    }
                    return element;
                });
                this.accounts = accounts;
            });
    }

    loadOwner(_id: string): void {
        this._dropdownsService.getOwners('', _id)
            .pipe(tap(() => this.searching = true))
            .subscribe((data: any) => {
                this.searching = false;
                this.owners = data.body;
            });
    }

    createOpening(): FormGroup {
        return this.formBuilder.group({
            name: [{ value: '', disabled: this.viewRecord }, Validators.required],
            email: [{ value: '', disabled: this.viewRecord }, Validators.required]
        });
    }

    createMeeting(): FormGroup {
        return this.formBuilder.group({
            related_to: [{ value: '', disabled: this.viewRecord }],
            account: [{ value: '', disabled: this.viewRecord }],
            owner: [{ value: '', disabled: this.viewRecord }],
            property: [{ value: '', disabled: this.viewRecord }],
            company: [{ value: '', disabled: this.viewRecord }],
            // project: [{ value: '', disabled: this.viewRecord }],
            // urbanisation: [{ value: '', disabled: this.viewRecord }],
            sale: [{ value: '', disabled: this.viewRecord }],
            commercial_property: [{ value: '', disabled: this.viewRecord }],
            booking: [{ value: '', disabled: this.viewRecord }],
            rental_contracts: [{ value: '', disabled: this.viewRecord }],
            mooring: [{ value: '', disabled: this.viewRecord }],
            boat: [{ value: '', disabled: this.viewRecord }],
        });
    }

    ngAfterViewChecked(): void {
        this.cdRef.detectChanges();
    }

    clearFormArray = (formArray: FormArray) => {
        while (formArray.length !== 0) {
            formArray.removeAt(0);
        }
    }

    setEmail(event, index): void {
        if(event == undefined){
            this.activity.get('participants.' + index + '.email').setValue('');
        }else{
            this.activity.get('participants.' + index + '.email').setValue(event.email);
        }
    }

    /**
     * Add 1 hour tot the start time and set it to the end time
     * @param startTime ${Date}
     */
    onTimeChange(startTime:Date){
        // We are not using startTime Directly as it update the time also in start_time control
        let timeTo = cloneDeep(startTime);
        timeTo.setHours(timeTo.getHours() + 1);
        this.activity.get('end_time').setValue(timeTo);
    }

    checkEndDateFormat(dateFrom: any, dateTo: any): void {
        if (this.old_end_date != dateTo) {
            this.old_end_date = dateTo;
            this._globalFuntions.dateToChanges(dateFrom, dateTo);
        }
    }

    citySelect(): void {
        this.activity.patchValue({
            'community':null,
            'sub_community':null,
            'location':null
        })
    }
    communitySelect(): void {
        this.activity.patchValue({
            'sub_community':null,
            'location':null
        })
    }
    subCommunitySelect(): void {
        this.activity.patchValue({
            'location':null
        })
    }

}
