import { Component, OnInit, ViewEncapsulation, Input, Injector } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { AccountsService } from 'app/services/accounts.service';
import { FormGroup } from '@angular/forms';
import { AccountsCreateComponent } from '../../accounts-create.component';
import { ActivatedRoute, Router } from '@angular/router';
import { TimeLineDataService } from 'app/components/time-line/time-line-data.service';
@Component({
  selector: 'tab-notes',
  templateUrl: './tab-notes.component.html',
  styleUrls: ['./tab-notes.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class TabNotesComponent implements OnInit {

  @Input() form: FormGroup;
  accountsCreateComponent: AccountsCreateComponent;
  id: string;
  viewTimeLine = false;
  constructor(
    private injector: Injector,
    private _accountsService: AccountsService,
    private route: ActivatedRoute,
    private router: Router,
    private _timeLineData: TimeLineDataService,
  ) {
    this.id = this.route.snapshot.parent.paramMap.get('id');
  }

  ngOnInit(): void {
    if (!this.form) {
      this.accountsCreateComponent = this.injector.get(AccountsCreateComponent) as AccountsCreateComponent;
      this.form = this.accountsCreateComponent.form;
    }
    if (this.id) {
      this._accountsService.getById(this.id).subscribe((data: any) => {
        this.viewTimeLine = true;
        this._timeLineData.setModelDataValue(data);
        this._timeLineData.setRelatedToValue('account');
      });
    }
  }
  submit(): void {
    this._accountsService.create(this.form.value).subscribe((data: any) => {
      this.router.navigate(['/master-data/accounts/update/' + data._id]);
    });
  }
}
