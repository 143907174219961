import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';
import { updateLocale } from 'moment';

@Injectable({
  providedIn: 'root'
})
export class ViewingToursService {

  constructor(
    private http: HttpClient,
  ) { }

  getData(filter: any, page: any, perPage?: any): Observable<object> {
    return this.http.post<object>(`${environment.yiiUrl}viewings/get-search?expand=assignee_user,account_name,agency_data,account_id,created_by_data,account_data&page=`
      + page + `&per_page=` + perPage, filter, { observe: 'response' as 'body' })
      .pipe(map(data => {
        return data;
      }));
  }

  create(data: any): Observable<any> {
    return this.http.post(`${environment.yiiUrl}viewings`, data, { observe: 'response' as 'body' });
  }

  getById(id: any): Observable<any> {
    return this.http.get(`${environment.yiiUrl}viewings/${id}?expand=assignee_user,account_name,agency_data,account_id,created_by_data`);
  }

  delete(_id, _createdby): Observable<any> {
    return this.http.delete(`${environment.yiiUrl}viewings/${_id}?created_by=${_createdby}`);
  }

  update(_id, _data): Observable<any> {
    return this.http.put(`${environment.yiiUrl}viewings/${_id}`, _data);
  }


  getUrbanisationViewingTours(filter: any, viewingID: string): Observable<any[]> {
    viewingID = viewingID ? viewingID : '-1';
    return this.http.post<any[]>(`${environment.apiUrl}urbanisations/viewings/${viewingID}`, filter);
  }

  getViewingOffices(data: any): Observable<any> {
    return this.http.post(`${environment.yiiUrl}offices/viewing-office`, data, { observe: 'response' as 'body' });
  }

  getViewingAgencies(data: any): Observable<any> {
    return this.http.get(`${environment.yiiUrl}agencies/${data}`, { observe: 'response' as 'body' });
  }

  downloadSheet(pdfData: any, sheet: any, lang: any, attachment?: any): Observable<any> {
    return this.http.post(`${environment.yiiUrl}pdf/genrate-sheet-pdf?sheet=${sheet}&lang=${lang}&attachment=${attachment}`, pdfData);
  }

  getOnlyProperty(id: any): Observable<any> {
    // tslint:disable-next-line: max-line-length
    return this.http.get(`${environment.yiiUrl}properties/${id}?expand=property_location,property_city,property_urbanisation,property_region,property_province,property_type,property_type_two,property_location,agency_logo`);
  }

  getListedProperties(data: any): Observable<any> {
    // tslint:disable-next-line: max-line-length
    return this.http.post(`${environment.yiiUrl}viewings/get-listed-properties?expand=property_location,images,property_community,property_subCommunity,property_type,property_type_two`, data, { observe: 'response' as 'body' });
  }

  getSheetPdf(data: any, attachment?: any): Observable<any> {
    return this.http.post<any[]>(`${environment.yiiUrl}pdf/genrate-sheet-pdf?sheet=sheet2&attachment=${attachment}`, data);
}

}
