import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MlsRequestComponent } from './mls-request.component';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSelectModule } from '@angular/material/select';



@NgModule({
  declarations: [MlsRequestComponent],
  imports: [
    CommonModule,

    FuseSharedModule,

    MatButtonModule,
    MatDatepickerModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatToolbarModule,
    MatTooltipModule,
    MatSelectModule,
    MatSnackBarModule
  ],
  exports: [MlsRequestComponent],
  entryComponents: [MlsRequestComponent]
})
export class MlsRequestModule { }
