import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WindowcardsListDialogComponent } from './windowcards-list-dialog.component';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseDirectivesModule } from '@fuse/directives/directives';
import { AppPipesModule } from 'app/pipes/app-pipes.module';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';



@NgModule({
  declarations: [WindowcardsListDialogComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatToolbarModule,
    MatButtonModule,
    MatTooltipModule,
    TranslateModule,
    FlexLayoutModule,
    FuseDirectivesModule,
    AppPipesModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    MatRadioModule
  ],
  exports: [WindowcardsListDialogComponent],
  entryComponents: [WindowcardsListDialogComponent]
})
export class WindowcardsListDialogModule { }
