import { Component, OnInit, ViewEncapsulation, AfterViewChecked, Inject, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { DropdownsService } from 'app/services';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'owners-advance-search',
    templateUrl: './advance-search.component.html',
    styleUrls: ['./advance-search.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AdvanceSearchComponent implements OnInit, AfterViewChecked {

    currentLang: any;
    searchForm: FormGroup;
    isHideGdpr = false as boolean;

    constructor(
        public matDialogRef: MatDialogRef<AdvanceSearchComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _formBuilder: FormBuilder,
        private _dropdownsService: DropdownsService,
        private _translateService: TranslateService,
        private cdRef: ChangeDetectorRef
    ) {
        this.currentLang = this._translateService.currentLang === 'es' ? 'es_AR' : this._translateService.currentLang;
        this.searchForm = this.createEventForm();
    }

    ngOnInit(): void {

    }

    createEventForm(): FormGroup {
        return new FormGroup({
            id: new FormControl(),
            status: new FormControl(),
            home_phone: new FormControl(),
            work_phone: new FormControl(),
            mobile: new FormControl(),
            email: new FormControl(),
            skype_id: new FormControl(),
            country: new FormControl(),
            community: new FormControl(),
            city: new FormControl(),
            postcode: new FormControl(),
            street: new FormControl(),
            streetnumber: new FormControl(),
            // gdpr_status: new FormControl(),
            automated_email: new FormControl(),
            created_date_from: new FormControl(),
            created_date_to: new FormControl(),
            last_updated_from: new FormControl(),
            last_updated_to:  new FormControl(),
            newsletter:  new FormControl(),
        });
    }

    search(): void {
        this.matDialogRef.close(this.searchForm);
    }

    ngAfterViewChecked(): void {
        this.cdRef.detectChanges();
    }
}
