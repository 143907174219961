import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimeLineComponent } from './time-line.component';
import { FuseSharedModule } from '@fuse/shared.module';
import { MeatingCreateModalModule } from 'app/main/activities/meetings/meeting-create-modal/meating-create-modal.module';
import { ViewingToursModalModule } from 'app/main/activities/viewingTours/viewing-tours-modal/viewing-tours-modal.module';
import { TasksCreateModalModule } from 'app/main/activities/tasks/tasks-create-modal/tasks-create-modal.module';
import { SalesCreateModalModule } from 'app/main/activities/sales/sales-create-modal/sales-create-modal.module';
import { OffersModalModule } from 'app/main/activities/offers/offers-modal/offers-modal.module';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatRippleModule, MatOptionModule } from '@angular/material/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import { MatRadioModule } from '@angular/material/radio';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatBadgeModule } from '@angular/material/badge';

@NgModule({
  declarations: [TimeLineComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatTabsModule,
    MatTooltipModule,
    MatRippleModule,
    MatProgressSpinnerModule,
    FuseSharedModule,
    MatMenuModule,
    MatSelectModule,
    MatOptionModule,
    MatCardModule,
    MatRadioModule,
    MatDialogModule,
    MatExpansionModule,
    MeatingCreateModalModule,
    ViewingToursModalModule,
    TasksCreateModalModule,
    SalesCreateModalModule,
    OffersModalModule,
    MatBadgeModule
  ],
  exports: [TimeLineComponent]
})
export class TimeLineModule { }
