import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { GLOBALS } from '../../../../../../config/globals';
import { BaseService } from '../../../../../../_helpers/base/base.service';
import { FormBuilder, Validators } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { environment } from 'environments/environment';
import { AuthenticationService } from 'app/services';
import { WebsocketService } from '../../../../../../services/websocket.service';
import { TranslateService } from '@ngx-translate/core';
import { startWith } from 'rxjs/operators';
@Component({
    selector: 'app-comment',
    templateUrl: './comment.component.html',
    styleUrls: ['./comment.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class CommentComponent implements OnInit {

    public post;

    @Input() 
    set _post(val: any){
        this.post = val;
    }

    public comment;

    public env = environment;
    private _confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;

    public loaders = { ...GLOBALS.loaders }; // To display/hide loading spinner
    public animations = { ...GLOBALS.animations }; // To use centralized animations
    public pageActions = GLOBALS.pageActions; // To use same naming conventions for opening one form for multiple purposes

    public replyBox = { flag: false, comment: '' };
    public commentBox = true;

    public selectedToReply;

    public currentLang: string;

    public postComment = this._formBuilder.group({
        comment: this._formBuilder.control('', Validators.required)
    });

    public replyCmnt = this._formBuilder.group({
        comment: this._formBuilder.control('', Validators.required)
    });

    constructor(
        private _matDialog: MatDialog,
        private _gService: BaseService,
        private _formBuilder: FormBuilder,
        public _authenticationService: AuthenticationService,
        private socket: WebsocketService,
        private _translateService: TranslateService) { }

    ngOnInit(): void {
        this._translateService.onLangChange
        .pipe(startWith(''))
        .subscribe(() => {
            this.currentLang = this._translateService.currentLang;
        });
        this.loaders.box = true;
        this._gService.post('mp/boardmessage/comments/get/all/' + this.post, {}, 'nodejs').subscribe(res => {
            this.loaders.box = false;
            if (res.status === true) {
                this.comment = res.data;
            }
        });

    }
    addComment(): void {
        this.loaders.box = true;
        this.postComment.value.message = this.post;
        this._gService.post('mp/boardmessage/comment/create', this.postComment.value, 'nodejs').subscribe(res => {
            this.loaders.box = false;
            if (res.status === true) {
                this.postComment.reset();
                this.socket.emit('postComment', {
                    created_by: this._authenticationService.currentUserValue._id, updated_by: this._authenticationService.currentUserValue._id,
                    agency: this._authenticationService.currentUserValue.agency, post: this.post,
                    created_name: this._authenticationService.currentUserValue.full_name, model_id: res.commentId
                });
                this.ngOnInit();
            }
        });
    }

    public reply(comment): void {
        if (this.replyBox.flag === true) {
            this.replyBox.flag = false;
            this.replyCmnt.reset();
        } else {
            this.replyBox = { flag: true, comment: comment._id };
            this.selectedToReply = comment;

        }
    }


    public replyComment(reply): void {

        if (this.replyBox.flag === true) {
            this.replyBox.flag = false;
            this.replyCmnt.reset();
        } else {
            this.replyBox = { flag: true, comment: reply._id };
            this.selectedToReply = reply;
        }
    }

    public replyToComment(): void {
        this.loaders.box = true;

        if (this.selectedToReply.parent_comment) {
            this.replyCmnt.value.parent_comment = this.selectedToReply.parent_comment;
        } else {
            this.replyCmnt.value.parent_comment = this.selectedToReply._id;
        }

        this._gService.post('mp/boardmessage/comment/create', this.replyCmnt.value, 'nodejs').subscribe(res => {
            this.loaders.box = false;
            if (res.status === true) {
                this.socket.emit('postCommentReply', {
                    created_by: this._authenticationService.currentUserValue._id, updated_by: this._authenticationService.currentUserValue._id,
                    agency: this._authenticationService.currentUserValue.agency, comment: this.selectedToReply,
                    created_name: this._authenticationService.currentUserValue.full_name, model_id: res.commentId,
                    status: (this.selectedToReply.parent_comment) ? 'replyOfReply' : 'commentReply' , post : this.post 
                });
                this.replyCmnt.reset();
                this.replyBox.flag = false;
                this.ngOnInit();
            }
        });
    }

    public deleteComment(id): void {
        
        this._confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
            disableClose: false
        });

        this._confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete?';

        this._confirmDialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.loaders.box = true;
                this._gService.delete('mp/boardmessage/comment/delete/' + id, {}, 'nodejs').subscribe(res => {
                    if (res.status === true) {
                        this.loaders.box = false;
                        this._gService.succussMessage('Deleted!', 'Success');
                        this.ngOnInit();
                    } else {
                        this.loaders.box = false;
                    }
                });
            }
            this._confirmDialogRef = null;
        });
    }

    public toggleCommentDiv(): void {
        if (this.commentBox === true){
            this.commentBox = false;
        }else{
            this.commentBox = true;
        }

    }

}
