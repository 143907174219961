import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';

@Injectable({
    providedIn: 'root'
})
export class GridBoardsService {

    private gridSearch: BehaviorSubject<any> = new BehaviorSubject<any>([]);
    public onGridSearch: Observable<any> = this.gridSearch.asObservable();
    currentUser: any;

    constructor(
        private http: HttpClient,
        private _authenticationService: AuthenticationService
    ) {
        this.currentUser = this._authenticationService.currentUserValue;
    }

    public get getGridSearch(): any {
        return this.gridSearch.value;
    }

    public setGridSearch(data: any): any {
        this.gridSearch.next(data);
    }

    getBoards(filter: any): Observable<any[]> {
        return this.http.post<any[]>(`${environment.apiUrl}grid_boards`, filter);
    }

    getBoard(id: any): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}grid_boards/${id}`);
    }

    deleteBoard(id: any): Observable<any> {
        return this.http.delete<any>(`${environment.apiUrl}grid_boards/${id}`);
    }

    addEditBoards(data: any, id?: string): Observable<any[]> {
        return this.http.post<any[]>(`${environment.apiUrl}grid_boards/add_edit?id=${id ? id : ''}`, data);
    }

    getAccountsData(data: any, all?: boolean): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}grid_boards/accounts_data?all=${all}`, data);
    }
    getCommercialData(data: any, all?: boolean): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}grid_boards/commercial_data?all=${all}`, data);
    }

    getOwnersData(data: any, all?: boolean): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}grid_boards/owners_data?all=${all}`, data);
    }

    getCsv(data: any): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}accounts/download-csv`, data);
    }

    uploadCsv(file: File): Observable<any> {
        const formData: any = new FormData();
        formData.append('file', file);
        return this.http.post(`${environment.yiiUrl}xml-export-formats/csv-account-uploader?user=${this.currentUser._id}`, formData);
    }

    activitiesCount(ids: any): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}accounts/activities-count`, ids);
    }

    ownersCount(ids: any): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}owners/activities-count`, ids);
    }

    getActivities(data: any, page?: any): Observable<any> {
        page = page ? page : 1;
        return this.http.post<any>(
            `${environment.yiiUrl}activities/account-activities?page=${page}&expand=assignee_user,account_name,agency_data,account_id,created_by_data`,
            data,
            { observe: 'response' as 'body' }
        );
    }

}
