import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
    selector: 'app-report',
    templateUrl: './report.component.html',
    styleUrls: ['./report.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ReportComponent implements OnInit {

    public form: FormGroup;

    constructor(
        private formBuilder: FormBuilder,
        private dialogRef: MatDialogRef<ReportComponent>,
        @Inject(MAT_DIALOG_DATA) data
    ) {
    }

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            description: this.formBuilder.control('', Validators.required)
        });
    }

    save(): void {
        this.dialogRef.close({ status: true, form: this.form.value });
    }

    close(): void {
        this.dialogRef.close({ status: false });
    }

}
