import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { fuseAnimations } from '@fuse/animations';
import { Subject } from 'rxjs';
import { DropdownsService, AuthenticationService } from 'app/services';
import { startWith, takeUntil, switchMap } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-save-bookmark',
  templateUrl: './save-bookmark.component.html',
  styleUrls: ['./save-bookmark.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class SaveBookmarkComponent implements OnInit {

  form: FormGroup;
  officeData: any;
  bookMark = '' as any;
  searchOffices$ = new Subject<string>();
  offices = [] as any;
  private _unsubscribeAll: Subject<any>;

  constructor(
    public matDialogRef: MatDialogRef<SaveBookmarkComponent>,
    private _authenticationService: AuthenticationService,
    public _dropdownsService: DropdownsService,
    private formBuilder: FormBuilder
  ) {
    this.officeData = this._authenticationService.currentOfficeValue;
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {

    this.form = this.formBuilder.group({
      offices: [{ value: [this.officeData._id], disabled: false }, Validators.required],
      title: [{ value: '', disabled: false }, Validators.required],
      menu_access: { value: true, disabled: false }
    });

    this.searchOffices$
      .pipe(
        startWith(''),
        takeUntil(this._unsubscribeAll),
        switchMap((search: string) => {
          return this._dropdownsService.getOffices(search);
        }),
      )
      .subscribe((data: any) => {
        this.offices = data.body;
      });
  }

  saveBookmark(): void {
    if (this.form.valid) {
      this.matDialogRef.close(this.form);
    } else {
      this.form.markAllAsTouched();
    }
  }

  // saveBookMark(value: any): void {
  //   this.bookMark = value;
  //   this.form.get('title').setValue(value);
  // }

}
