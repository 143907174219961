export class ShortCodesFakeDb {
    public static shortCodes = [
        {
            title: 'Property',
            templates: ['property', 'booking', 'lt_retal_contract'
                , 'offer', 'sale', 'property_alerts', 'viewings_comment',
                'Properties', 'residentials', 'Offers', 'Sales', 'Rental', 'Rental(create + upload)', 'properties_to_accounts', 'viewings', 'to_listing_agent_cross_agency_request_for_viewing',
            'to_ori_generating_user_viewing_confirmation', 'to_listing_agent_viewing_cancelled', 'to_ori_generating_user_viewing_cancelled', 'on_client_registration_request', 'on_property_reservation_request'],
            codes: {
                price: '[price]',
                reference: '[reference]',
                st_rental_price: '[st_rental_price]',
                lt_rental_price: '[lt_rental_price]',
                initial_amount: '[initial_amount]',
                crm_property_id: '[crm_property_id]',
                other_reference: '[other_reference]',
                external_system_reference: '[external_system_reference]',
                location: '[location]',
                urbanisation: '[urbanisation]',
                street: '[street]',
                sleeps: '[property attribute = sleeps]',
                street_number: '[street_number]',
                house_number: '[house_number]',
                block_number: '[block_number]',
                cadastral_numbers: '[cadastral_numbers]',
                cadastral_values: '[cadastral_values]',
                cadastral_years: '[cadastral_years]',
                kilo_watt: '[kilo_watt]',
                co_two: '[co2]',
                idufil_cru: '[property attribute = idufil_cru]',
                numero_de_finca: '[finca_number]',
                property_register: '[property_register]',
                license_number: '[property attribute = license_number]',
                total_commission: '[total_commission]',
                reservation_amount: '[property attribute = reservation_amount]',
                portal: '[portal]',
                doorbell: '[doorbell]',
                parking_number: '[parking_number]',
                city: '[city]',
                province: '[province]',
                country: '[country]',
                type: '[type]',
                sub_type: '[sub_type]',
                bedrooms: '[bedrooms]',
                bathrooms: '[bathrooms]',
                toilets: '[toilets]',
                plot: '[plot]',
                built: '[built]',
                title: '[title]',
                floor: '[floor]',
                description: '[description lang=EN]',
                seo_title: '[seo_title lang=EN]',
                seo_description: '[seo_description lang=EN]',
                images: '[images]',
                rental_description: '[property attribute = rental_description lang=EN]',
                single_image: '[images select=1]',
                property_url: '[property_url]',
                website_url: '[website_url lang=EN type=SALE]',
                thumbnails_links: '[thumbnails_links lang=EN]',
                thumbnails_links_two: '[thumbnails_links_two lang=EN]',
                thumbnails_links_three: '[thumbnails_links_three lang=EN type=SALE]',
                key_safe: '[key_safe]',
                wifi_router: '[wifi_router]',
                wifi_password: '[wifi_password]',
                energy_certificate: '[energy_certificate]',
                terrace: '[terrace]',
                mooring: '[mooring]',
                furnished: '[furnished]',
                year_built: '[property attribute = year_built]',
                year_reformed: '[property attribute = year_reformed]',
                miscellaneous_tax: '[property attribute = additional_tax]',
                rubbish_annual: '[property attribute = basura]',
                cadastral_percentage1: '[property attribute = cadastral_percentage1]',
                cadastral_percentage_tax1: '[property attribute = cadastral_percentage_tax1]',
                cadastral_percentage2: '[property attribute = cadastral_percentage2]',
                real_estate_tax_annual: '[property attribute = ibi]',
                dimensions: '[property attribute = dimensions]',
                community_fees: '[property attribute = community_fees]',
                usefull_area: '[property attribute = usefull_area]',
                facade_width: '[property attribute = facade_width]',
                electricity_certificate: '[property attribute = electricity_certificate]',
                protected_heritage: '[property attribute = protected_heritage]',
                summons: '[property attribute = summons]',
                flood_risk: '[property attribute = flood_risk]',
                destination: '[property attribute = destination]',
                pool_communal: '[features_pool type=pool_communal]',
                freshwater: '[features_pool type=freshwater]',
                hot_tub: '[features_pool type=hot_tub]',
                infinity_pool: '[features_pool type=infinity_pool]',
                plunge_pool: '[features_pool type=plunge_pool]',
                childrens_pool: '[features_pool type=childrens_pool]',
                fenced_pool: '[features_pool type=fenced_pool]',
                pool_heated: '[features_pool type=pool_heated]',
                pool_indoor: '[features_pool type=pool_indoor]',
                ladder_access: '[features_pool type=ladder_access]',
                outside_shower: '[features_pool type=outside_shower]',
                outside_toilets: '[features_pool type=outside_toilets]',
                pool_private: '[features_pool type=pool_private]',
                roman_steps_into_pool: '[features_pool type=roman_steps_into_pool]',
                soler_heated_pool: '[features_pool type=soler_heated_pool]',
                room_for_pool: '[features_pool type=room_for_pool]',
                sun_beds: '[features_pool type=sun_beds]',
                whirlpool: '[features_pool type=whirlpool]',
                communal_garage: '[features_parking type=communal_garage]',
                parking_communal: '[features_parking type=parking_communal]',
                covered: '[features_parking type=covered]',
                more_than_one: '[features_parking type=more_than_one]',
                open: '[features_parking type=open]',
                garage: '[features_parking type=garage]',
                parking_optional: '[features_parking type=parking_optional]',
                private: '[features_parking type=private]',
                public_parking_nearby_against_a_fee: '[features_parking type=public_parking_nearby_against_a_fee]',
                parking_street: '[features_parking type=parking_street]',
                underground: '[features_parking type=underground]',
                car_hire_facility: '[features_features type=car_hire_facility]',
                courtesy_bus: '[features_features type=courtesy_bus]',
                wooden_beams: '[features_features type=wooden_beams]',
                patio_open: '[features_features type=patio_open]',
                patio_enclosed: '[features_features type=patio_enclosed]',
                well_used_access_track: '[features_features type=well_used_access_track]',
                car_access: '[features_features type=car_access]',
                land_gently_sloping: '[features_features type=land_gently_sloping]',
                land_mostly_steeply_sloping: '[features_features type=land_mostly_steeply_sloping]',
                land_mostly_flat: '[features_features type=land_mostly_flat]',
                land_flat: '[features_features type=land_flat]',
                land_terraced: '[features_features type=land_terraced]',
                stream_on_land: '[features_features type=stream_on_land]',
                private_entrance: '[features_features type=private_entrance]',
                basement: '[features_features type=basement]',
                baby_equipment: '[features_features type=baby_equipment]',
                covered_terrace: '[features_features type=covered_terrace]',
                day_care: '[features_features type=day_care]',
                disabled_access: '[features_features type=disabled_access]',
                domotics: '[features_features type=domotics]',
                en_suite_bathroom: '[features_features type=en_suite_bathroom]',
                fitted_wardrobes: '[features_features type=fitted_wardrobes]',
                american_fridge: '[features_kitchen type=american_fridge]',
                fully_fitted: '[features_kitchen type=fully_fitted]',
                open_plan: '[features_kitchen type=open_plan]',
                fridge: '[features_kitchen type=fridge]',
                partially_fitted: '[features_kitchen type=partially_fitted]',
                not_fitted: '[features_kitchen type=not_fitted]',
                guest_apartment: '[features_features type=guest_apartment]',
                guest_house: '[features_features type=guest_house]',
                lift: '[features_features type=lift]',
                marble_flooring: '[features_features type=marble_flooring]',
                private_terrace: '[features_features type=private_terrace]',
                restaurant_on_site: '[features_features type=restaurant_on_site]',
                storage_room: '[features_features type=storage_room]',
                servants_quarters: '[features_features type=servants_quarters]',
                utility_room: '[features_features type=utility_room]',
                wood_flooring: '[features_features type=wood_flooring]',
                roof_terrace: '[features_features type=roof_terrace]',
                minimarket_on_site: '[features_features type=minimarket_on_site]',
                dining_room: '[features_features type=dining_room]',
                guest_toilet: '[features_features type=guest_toilet]',
                study_room: '[features_features type=study_room]',
                wine_cellar: '[features_features type=cellar]',
                office_room: '[features_features type=office_room]',
                listed_until: '[listed_until]',
                created_at: '[created_at]',
                communal_garden: '[features_garden type=garden_communal]',
                private_garden: '[features_garden type=garden_private]',
                air_conditioning: '[features_climate_control type=air_conditioning]'
            }
        },
        {
            title: 'Commercial Properties',
            templates: ['commercial_property', 'booking',
                'offer', 'sale', 'viewings_comment', 'Properties',
                'commercials', 'Offers', 'Sales', 'Rental', 'Rental(create + upload)', 'viewings', 'commercials_to_accounts'],
            codes: {
                price: '[cp_price]',
                status: '[cp_status]',
                lt_rental_price: '[cp_rental_seasons]',
                st_rental_price: '[cp_st_rental_price]',
                reference: '[cp_reference]',
                other_reference: '[cp_other_reference]',
                external_system_reference: '[cp_external_system_reference]',
                location: '[cp_location]',
                urbanisation: '[cp_urbanisation]',
                street: '[cp_street]',
                street_number: '[cp_street_number]',
                house_number: '[cp_house_number]',
                block_number: '[cp_block_number]',
                cadastral_numbers: '[cp_cadastral_number]',
                cadastral_values: '[cp_cadastral_values]',
                cadastral_years: '[cp_cadastral_years]',
                total_commission: '[cp_total_commission]',
                portal: '[cp_portal]',
                doorbell: '[cp_doorbell]',
                // parking_number: '[cp_parking_number]',
                parking_quantity: '[cp_parking_quantity]',
                city: '[cp_city]',
                // province: '[cp_province]',
                community: '[cp_community]',
                sub_community: '[cp_sub_community]',
                country: '[cp_country]',
                type: '[cp_type]',
                sub_type: '[cp_sub_type]',
                bedrooms: '[cp_bedrooms]',
                beds: '[cp_beds]',
                bathrooms: '[cp_bathrooms]',
                toilets: '[cp_toilets]',
                plot: '[cp_plot]',
                built: '[cp_built]',
                title: '[cp_title limit="200"]',
                description: '[cp_description limit="500"]',
                cp_description_with_images: '[cp_description_with_images]',
                seo_title: '[cp_seo_title]',
                seo_description: '[cp_seo_description]',
                rental_title: '[commercial_property attribute=rental_title]',
                // new shortCode    
                kilo_watt: '[cp_kilo_watt]',
                co_two: '[cp_co2]',
                year_built: '[commercial_property attribute=year_built]',
                year_reformed: '[commercial_property attribute=year_reformed]',
                living_rooms: '[commercial_property attribute=living_rooms]',
                floors: '[cp_floors]',
                total_floors: '[cp_total_floors]',
                sleeps: '[commercial_property attribute=sleeps]',
                building_price: '[commercial_property attribute=building_price]',
                business_price: '[commercial_property attribute=business_price]',
                facade_size: '[commercial_property attribute=facade_size]',
                usefull_area: '[commercial_property attribute=usefull_area]',
                restaurant_size: '[commercial_property attribute=restaurant_size]',
                restaurant_seats: '[commercial_property attribute=restaurant_seats]',
                bar_size: '[commercial_property attribute=bar_size]',
                bar_seats: '[commercial_property attribute=bar_seats]',
                foyer_size: '[commercial_property attribute=foyer_size]',
                foyer_seats: '[commercial_property attribute=foyer_seats]',
                outdoor_size: '[commercial_property attribute=outdoor_size]',
                outdoor_seats: '[commercial_property attribute=outdoor_seats]',
                other_size: '[commercial_property attribute=other_size]',
                other_seats: '[commercial_property attribute=other_seats]',
                electricity_certificate: '[commercial_property attribute = electricity_certificate]',
                protected_heritage: '[commercial_property attribute=protected_heritage]',
                summons: '[commercial_property attribute=summons]',
                flood_risk: '[commercial_property attribute=flood_risk]',
                destination: '[commercial_property attribute=destination]',
                allotment_permit: '[cp_allotment_permit]',
                soil_certificate: '[cp_soil_certificate]',
                right_to_sell: '[cp_right_to_sell]',
                environmental_permit: '[cp_environmental_permit]',
                terraces: '[commercial_property attribute=terraces]',
                rental_description: '[commercial_property attribute=rental_description]',
                images: '[cp_images]',
                // single_image: '[commercial_property attribute=images select=1]',
                property_url: '[cp_property_url]',
                key_safe: '[cp_key_safe]',
                wifi_router: '[cp_wifi_router]',
                wifi_password: '[cp_wifi_password]',
                pool_communal: '[cp_communal_pool]',
                fresh_water: '[cp_fresh_water]',
                hot_tub: '[cp_hot_tub]',
                infinity_pool: '[cp_infinity_pool]',
                plunge_pool: '[cp_plunge_pool]',
                children_pool: '[cp_children_pool]',
                fenced_pool: '[cp_fenced_pool]',
                pool_heated: '[cp_heated_pool]',
                pool_indoor: '[cp_indoor_pool]',
                ladder_access: '[cp_ladder_access]',
                outside_shower: '[cp_outside_shower]',
                outside_toilets: '[cp_outside_toilets]',
                pool_private: '[cp_private_pool]',
                roman_steps_into_pool: '[cp_roman_steps_into_pool]',
                soler_heated_pool: '[cp_solar_heated_pool]',
                space_for_pool: '[cp_space_for_pool]',
                sun_beds: '[cp_sun_beds_pool]',
                whirlpool: '[cp_whirlpool]',
                more_than_one: '[cp_more_than_one]',
                // open: '[commercial_property attribute=features_parking type=open]',
                private: '[cp_settings_private]',
                public_parking_nearby: '[cp_public_parking_nearby]',
                own_parking: '[cp_own_parking]',
                // parking_street: '[commercial_property attribute=features_parking type=parking_street]',
                underground: '[cp_underground_parking]',
                car_hire_facility: '[cp_car_hire_facility]',
                courtesy_bus: '[cp_courtesy_bus]',
                wooden_beams: '[cp_wooden_beams]',
                patio_open: '[cp_patio_open]',
                patio_enclosed: '[cp_patio_enclosed]',
                well_used_access_track: '[cp_well_used_access_track]',
                // car_access: '[commercial_property attribute=features_features type=car_access]',
                land_gently_sloping: '[cp_land_gently_sloping]',
                land_mostly_steeply_sloping: '[cp_land_mostly_steeply_sloping]',
                land_mostly_flat: '[cp_land_mostly_flat]',
                land_flat: '[cp_land_flat]',
                land_terraced: '[cp_land_terraced]',
                stream_on_land: '[cp_stream_on_land]',
                private_entrance: '[cp_private_entrance]',
                basement: '[cp_basement]',
                baby_equipment: '[cp_baby_equipment]',
                covered_terrace: '[cp_covered_terrace]',
                day_care: '[cp_day_care]',
                disabled_access: '[cp_disabled_access]',
                domotics: '[cp_domotics]',
                // en_suite_bathroom: '[commercial_property attribute=features_features type=en_suite_bathroom]',
                fitted_wardrobes: '[cp_fitted_wardrobes]',
                guest_apartment: '[cp_guest_apartment]',
                guest_house: '[cp_guest_house]',
                lift: '[cp_lift]',
                marble_flooring: '[cp_marble_flooring]',
                private_terrace: '[cp_private_terrace]',
                restaurant_on_site: '[cp_restaurant_on_site]',
                storage_room: '[cp_storage_room]',
                servants_quarters: '[cp_servants_quarters]',
                utility_room: '[cp_utility_room]',
                wood_flooring: '[cp_wood_flooring]',
                roof_terrace: '[cp_roof_terrace]',
                minimarket_on_site: '[cp_minimarket_on_site]',
                dining_room: '[cp_dining_room]',
                guest_toilet: '[cp_guest_toilet]',
                // study_room: '[commercial_property attribute=features_features type=study_room]',
                wine_cellar: '[cp_wine_cellar]',
                office_room: '[cp_office_room]',
                listed_until: '[cp_listed_until]',
                communal_garden: '[commercial_property attribute=features_garden type=communal_garden]',
                private_garden: '[commercial_property attribute=features_garden type=garden_private]',
                air_conditioning: '[commercial_property attribute=features_climate_control type=air_conditioning]',
                thumbnails_links_three: '[cp_thumbnails_links_three lang=EN type=SALE]',
                numero_de_finca: '[cp_finca_number]',
                property_register: '[cp_property_register]',
                garage: '[cp_features_parking type=garage]',
                // Smart Invest
                floors_underground: '[commercial_property attribute=floors_underground]',
                floors_above_ground: '[commercial_property attribute=floors_above_ground]',
                percentage_property: '[commercial_property attribute=percentage_property]',
                power: '[commercial_property attribute=power]',
                size_underground: '[commercial_property attribute=size_underground]',
                size_above_ground: '[commercial_property attribute=size_above_ground]',
                number_of_rooms: '[commercial_property attribute=number_of_rooms]',
                number_of_properties: '[commercial_property attribute=number_of_properties]',
                office_quantities: '[commercial_property attribute=office_quantities]',
                commercial_spaces: '[commercial_property attribute=commercial_spaces]',
                office_rooms: '[commercial_property attribute=office_room_spaces]',
                building_capacity: '[commercial_property attribute=building_capacity]',
                commercial_premises: '[commercial_property attribute=commercial_premises]',
                capital_gain: '[commercial_property attribute=capital_gain]',
                debt_value: '[commercial_property attribute=debt_value]',
                appraised_value: '[commercial_property attribute=appraised_value]',
                capex: '[commercial_property attribute=capex]',
                room_price: '[commercial_property attribute=room_price]',
                price_capex_m2: '[commercial_property attribute=price_capex_m2]',
                price_plot_m2: '[commercial_property attribute=price_plot_m2]',
                price_with_capex: '[commercial_property attribute=price_with_capex]',
                parking_spot_price: '[commercial_property attribute=parking_spot_price]',
                dossier_status: '[commercial_property attribute=dossier_status]',
                object_comments: '[commercial_property attribute=object_comments]',
                rental_duration: '[commercial_property attribute=rental_duration]',
                obligated_period: '[commercial_property attribute=obligated_period]',
                renter_business: '[commercial_property attribute=renter_business]',
                commissions_paid_by: '[commercial_property attribute=commissions_paid_by]',
                start_rental_date: '[commercial_property attribute=start_rental_date]',
                last_mailing_date: '[commercial_property attribute=last_mailing_date]',
                tier: '[commercial_property attribute=tier]',
                operation_strategy: '[commercial_property attribute=operational_strategy]',
                importance: '[commercial_property attribute=commercial_importance]',
                land_uses: '[commercial_property attribute=land_uses]',
                asset_types: '[commercial_property attribute=asset_types]',
                actual_tenant: '[commercial_property attribute=actual_tenant]',
                source_company: '[commercial_property attribute=source_company]'
            }
        },
        {
            title: 'Owner',
            templates: ['owner', 'booking', 'property', 'offer', 'commercial_property',
                'sale', 'viewings_comment', 'Owner', 'Properties', 'residentials', 'commercials',
                'Offers', 'Sales', 'Rental', 'Rental(create + upload)', 'yovendo_registeration', 'viewings', 'invoice'],
            codes: {
                'Owner': '[owner]',
                'Type': '[owner attribute=type]',
                'Classification': '[owner attribute=classification]',
                'Partners': '[owner attribute=partners]',
                'Partners document type': '[owner attribute=partners_document_type]',
                'Partners document number': '[owner attribute=partners_document_number]',
                'Partners nationality': '[owner attribute=partners_nationality]',
                'Partners civil status': '[owner attribute=partners_civil_status]',
                'Partners document type two': '[owner attribute=partners_document_type_two]',
                'Partners document number two': '[owner attribute=partners_document_number_two]',
                'First name': '[owner attribute=forename]',
                'Last name': '[owner attribute=surname]',
                'Title': '[owner attribute=title]',
                'Gender': '[owner attribute=gender]',
                'Language': '[owner attribute=language]',
                'financial_account_holder': '[owner attribute=financial_account_holder]',
                'financial_iban': '[owner attribute=financial_iban]',
                'financial_bic_swift': '[owner attribute=financial_bic_swift]',
                'financial_avg_balance': '[owner attribute=financial_avg_balance]',
                'financial_account_currency': '[owner attribute=financial_account_currency]',
                'Nationality': '[owner attribute=nationality]',
                'Document type': '[owner attribute=document_type]',
                'Document number': '[owner attribute=document_number]',
                'Google address': '[owner attribute=address]',
                'Street name': '[owner attribute=street]',
                'Street number': '[owner attribute=streetnumber]',
                'Postal code': '[owner attribute=postcode]',
                'City': '[owner attribute=city]',
                'Province': '[owner attribute=province]',
                'Region': '[owner attribute=region]',
                'Country': '[owner attribute=country]',
                'Home phone': '[owner attribute=home_phone]',
                'Mobile phone': '[owner attribute=mobile]',
                'Work phone': '[owner attribute=work_phone]',
                'Email': '[owner attribute=email]',
                'Skype': '[owner attribute=skype_id]',
                'Properties': '[owner attribute=properties]',
                'Comments': '[owner attribute=comments]',
                'age': '[owner attribute=age]',
                'civil_status': '[owner attribute=civil_status]',
                'document_type_two': '[owner attribute=document_type_two]',
                'document_number_two': '[owner attribute=document_number_two]'
            }
        },
        {
            title: 'Account',
            templates: ['account', 'booking', 'lt_retal_contract', 'offer',
                'sale', 'property_alerts', 'Offers', 'Sales',
                'Accounts', 'Rental', 'Rental(create + upload)', 'Buyer', 'properties_to_accounts', 'viewings', 'commercials_to_accounts', 'invoice', 'account_enquiry_to_assigned_user', 'account_update_enquiry_to_assigned_user',
                'on_client_registration_request', 'on_property_reservation_request'],
            codes: {
                'Account ID': '[account attribute=client_id]',
                'Type': '[account attribute=type]',
                'Classification': '[account attribute=classification]',
                'Office': '[account attribute=office]',
                'Account': '[account]',
                'Account Invite': '[account attribute=invite_url lang=en]',
                'First name': '[account attribute=forename]',
                'Last name': '[account attribute=surname]',
                'Title': '[account attribute=title]',
                'Gender': '[account attribute=gender]',
                'Date of birth': '[account attribute=date_of_birth]',
                'Language': '[account attribute=language]',
                'Nationality': '[account attribute=nationality]',
                'Partner Title': '[account attribute=partners_title]',
                'Partner First name': '[account attribute=partners_forename]',
                'Partner Last name': '[account attribute=partners_surname]',
                'Partner mobile phone': '[account attribute=partners_phone]',
                'Partner email': '[account attribute=partners_email]',
                'partners nationality': '[account attribute=partners_nationality]',
                'partners id type': '[account attribute=partners_id_type]',
                'partners id number': '[account attribute=partners_id_number]',
                'partners id type two': '[account attribute=partners_id_type_two]',
                'partners id number two': '[account attribute=partners_id_number_two]',
                'Document type': '[account attribute=id_type]',
                'Document number': '[account attribute=id_number]',
                'Google address': '[account attribute=address]',
                'Street name': '[account attribute=street]',
                'Street number': '[account attribute=street_number]',
                'Suffix': '[account attribute=suffix_apartment]',
                'Postal code': '[account attribute=postal_code]',
                'City': '[account attribute=city]',
                'Province': '[account attribute=province]',
                'Region': '[account attribute=region]',
                'Country': '[account attribute=country]',
                'Home phone': '[account attribute=home_phone]',
                'Mobile phone': '[account attribute=mobile_phone]',
                'Work phone': '[account attribute=work_phone]',
                'Email': '[account attribute=email]',
                'financial_account_holder': '[account attribute=financial_account_holder]',
                'financial_iban': '[account attribute=financial_iban]',
                'financial_bic_swift': '[account attribute=financial_bic_swift]',
                'financial_avg_balance': '[account attribute=financial_avg_balance]',
                'financial_account_currency': '[account attribute=financial_account_currency]',
                'Secondary email': '[account attribute=secondary_email]',
                'Skype': '[account attribute=skype_id]',
                'Social media link(s)': '[account attribute=social_media_link]',
                'Source': '[account attribute=source]',
                'Profile - Transaction types': '[account attribute=transaction_types]',
                'Profile - Purpose': '[account attribute=purpose]',
                'Profile - Property types 1': '[account attribute=property_types]',
                'Profile - Property types 2': '[account attribute=sub_types',
                'Profile - Minimum bedrooms': '[account attribute=min_beds]',
                'Profile - Minimum bathrooms': '[account attribute=min_baths]',
                'Profile - Countries': '[account attribute=countries]',
                'Profile - Regions': '[account attribute=regions]',
                'Profile - Provinces': '[account attribute=provinces]',
                'Profile - Cities': '[account attribute=cities]',
                'Profile - Location groups': '[account attribute=locations_group]',
                'Profile - Locations': '[account attribute=locations]',
                'Profile - Urbanisation': '[account attribute=urbanization]',
                'Profile - Urbanisation type': '[account attribute=urbanization_type]',
                'Profile - Custom categories': '[account attribute=custom_categories]',
                'Profile - Building style': '[account attribute=building_style]',
                'Profile - Gated (yes/no)': '[account attribute=gated]',
                'Profile - Minimum built size': '[account attribute=min_built]',
                'Profile - Minimum plot size': '[account attribute=min_plot_size]',
                'Profile - Parking': '[account attribute=parking]',
                'Profile - Mooring': '[account attribute=mooring]',
                'Profile - Pool': '[account attribute=pool]',
                'Profile - Elevator': '[account attribute=lift]',
                'Profile - Furniture': '[account attribute=furniture]',
                'Projects only (yes/no)': '[account attribute=filter_projects]',
                'Profile - Buy - from date': '[account attribute=buy_from_date]',
                'Profile - Purchase price (low)': '[account attribute=buy_price_low]',
                'Profile - Purchase price (high)': '[account attribute=buy_price_high]',
                'Profile - Rent - from date': '[account attribute=rent_from_date]',
                'Profile - Short term price (low)': '[account attribute=short_term_Rent_price_low]',
                'Profile - Short term price (high)': '[account attribute=short_term_Rent_price_high]',
                'Profile - Long term price (low)': '[account attribute=long_term_Rent_price_low]',
                'Profile - Long term price (high)': '[account attribute=long_term_Rent_price_high]',
                'Last time updated': '[account attribute=last_time_contacted]',
                'Profile - id_number_two': '[account attribute=id_number_two]',
                'Profile - id_type_two': '[account attribute=id_type_two]'
            }
        },
        {
            title: 'Offer',
            templates: ['offer', 'Offers'],
            codes: {
                'Offer reference': '[offer attribute=offer_ref]',
                'Property': '[offer attribute=property]',
                'Agency': '[offer attribute=agency]',
                'Listing agency': '[offer attribute=listing_agency]',
                'Listing agent (ID)': '[offer attribute=listing_agent_id]',
                'Listing agent (name)': '[offer attribute=listing_agent]',
                'Owner of property': '[offer attribute=property_owner]',
                'Buyer': '[offer attribute=account]',
                'Mobile phone of buyer': '[offer attribute=account_phone]',
                'Email of buyer': '[offer attribute=account_email]',
                'Source of account': '[offer attribute=account_source]',
                'Account assigned to': '[offer attribute=account_assigned_to]',
                'Mortgage indicator (yes/no)': '[offer attribute=mortgage lang=en]',
                'Mortgage percentage': '[offer attribute=mortgage_percent]',
                'Arranged mortgage percentage': '[offer attribute=mortgage_per_arranged lang=en]',
                'Expected completion date': '[offer attribute=completion_date]',
                'Comments': '[offer attribute=comments]',
                'Accepted offer date': '[offer attribute=accepted_offer_date]',
                'Accepted offer amount': '[offer attribute=accepted_offer_amount]',
                'Accepted offer comments': '[offer attribute=accepted_offer_comments]'
            }
        },
        {
            title: 'Sales',
            templates: ['sale', 'Sales'],
            codes: {
                'Sales ID': '[sale attribute=sale_id]',
                'Status': '[sale attribute=status]',
                'Reference': '[sale attribute=property_reference]',
                'Offer ID': '[sale attribute=offer_id]',
                '3rd party listing agency ID': '[sale attribute=listing_agency_id]',
                'Listing agent': '[sale attribute=listing_agent]',
                'Listing price': '[sale attribute=listed_price]',
                'Sales price': '[sale attribute=sales_price]',
                'Currency': '[sale attribute=currency]',
                'VAT percentage': '[sale attribute=vat]',
                'Owner': '[sale attribute=owner]',
                'Own agency (name)': '[sale attribute=commission_one_payable]',
                'External agency 1 (name)': '[sale attribute=commission_two_payable]',
                'External agency 2 (name)': '[sale attribute=commission_three_payable]',
                'Listing agent (name)': '[sale attribute=listing_agent_payable]',
                'Listing agent (% over own commission)': '[sale attribute=commission_out_listing_percent]',
                'Sales agent (name)': '[sale attribute=selling_agent_payable]',
                'Sales agent (% over own commission)': '[sale attribute=commission_out_selling_percent]',
                'Commission value': '[sale attribute=tottal_commission_value]',
                'Commission amount': '[sale attribute=tottal_commission_amount]',
                'Vat on commission': '[sale attribute=tottal_vat_on_commission]',
                'Tottal commission': '[sale attribute=tottal_commission_payout]',
                'Commission own agency (%)': '[sale attribute=commission]',
                'Commission own agency (net)': '[sale attribute=commission_in_euro]',
                'Commission own agency (VAT)': '[sale attribute=commission_in_iva]',
                'Commission own agency (gross)': '[sale attribute=commission_payout_three]',
                'Commission external agency 1 (%)': '[sale attribute=commission_value_one]',
                'Commission external agency 1 (net)': '[sale attribute=commission_out_one_euro]',
                'Commission external agency 1 (VAT)': '[sale attribute=commission_out_one_iva]',
                'Commission external agency 1 (gross)': '[sale attribute=commission_payout_one]',
                'Commission external agency 2 (%)': '[sale attribute=commission_value_two]',
                'Commission external agency 2 (net)': '[sale attribute=commission_out_two_euro]',
                'Commission external agency 2 (VAT)': '[sale attribute=commission_out_two_iva]',
                'Commission external agency 2 (gross)': '[sale attribute=commission_payout_two]',
                'Commission listing agent (net)': '[sale attribute=commission_out_listing_agent_net]',
                'Commission listing agent (VAT)': '[sale attribute=commission_out_listing_agent_vat]',
                'Buyer': '[sale attribute=commission_out_listing_agent_total]',
                'Commission sales agent (net)': '[sale attribute=commission_out_selling_agent_net]',
                'Commission sales agent (VAT)': '[sale attribute=commission_out_selling_agent_vat]',
                'Commission sales agent (gross)': '[sale attribute=commission_out_selling_agent_total]',
                'Commission total payable (net)': '[sale attribute=commission_net]',
                'Commission total payable (VAT)': '[sale attribute=commission_net_iva]',
                'Commission total payable (gross)': '[sale attribute=commission_total_vat]',
                'Commission own netto (value)': '[sale attribute=commission_out_three_euro]',
                'Commission own netto (IVA)': '[sale attribute=commission_out_three_iva]',
                'Commission own netto (gross)': '[sale attribute=commission_payout_three]',
                'Comments': '[sale attribute=comments]'
            }
        },
        {
            title: 'user',
            templates: ['account', 'lt_retal_contract', 'yovendo_registeration', 'invoice', 'account_enquiry_to_assigned_user', 'account_update_enquiry_to_assigned_user', 'to_listing_agent_cross_agency_request_for_viewing',
            'to_ori_generating_user_viewing_confirmation', 'to_listing_agent_viewing_cancelled', 'to_ori_generating_user_viewing_cancelled', 'on_client_registration_request', 'on_property_reservation_request'],
            codes: {
                firstname: '[user attribute=firstname]',
                lastname: '[user attribute=lastname]',
                username: '[user attribute=username]',
                status: '[user attribute=status]',
                role: '[user attribute=role]',
                reports_to: '[user attribute=reports_to]',
                agency: '[user attribute=agency]',
                email: '[user attribute=email]',
                phone: '[user attribute=phone]',
                office_phone: '[user attribute=office_phone]',
                dp: '[user attribute=dp]',
                address: '[user attribute=address]',
                country: '[user attribute=country]',
                city: '[user attribute=city]',
                state: '[user attribute=state]',
                postcode: '[user attribute=postcode]',
                street: '[user attribute=street]',
                street_number: '[user attribute=street_number]'
            }
        },
        {
            title: 'company',
            templates: ['companies', 'Company'],
            codes: {
                legal_name: '[company attribute=legal_name]',
                commercial_name: '[company attribute=commercial_name]',
                type: '[company attribute=type]',
                logo: '[company attribute=logo]',
                legal_form: '[company attribute=legal_form]',
                tax_id: '[company attribute=tax_id]',
                nationality: '[company attribute=nationality]',
                country_code: '[company attribute=country_code]',
                phone: '[company attribute=phone]',
                fax: '[company attribute=fax]',
                email: '[company attribute=email]',
                website: '[company attribute=website]',
                address: '[company attribute=address]',
                fiscal_address: '[company attribute=fiscal_address]'
            }
        },
        {
            title: 'Renral booking',
            templates: ['booking', 'Rental(create + upload)'],
            codes: {
                client_name: '[client_name]',
                booking_reference: '[booking_reference]',
                booking_reference_id: '[booking_reference_id]',
                booking_date: '[booking_date]',
                date_from: '[date_from]',
                date_to: '[date_to]',
                nights: '[nights]',                
                total_guests: '[total_guests]',
                booking_price: '[booking_price]',
                discount: '[discount]',
                new_price: '[new_price]',
                extras: '[extras]',
                mandatory_extras: '[mandatory_extras]',
                total_extras_cost: '[total_extras_cost]',
                total_mandatory_extras_cost: '[total_mandatory_extras_cost]',
                end_price: '[end_price]',
                extern_comments: '[extern_comments]',
                flight_number: '[flight_number]',
                flight_arrival_time: '[flight_arrival_time]',
                urbanisation: '[urbanisation]',
                street: '[street]',
                street_number: '[street_number]',
                house_number: '[house_number]',
                deposit_amount: '[deposit_amount]',
                deposit_amount_duedate: '[deposit_amount_duedate]',
                final_payment_duedate: '[final_payment_duedate]',
                security_deposit_amount: '[security_deposit_amount]',
                security_deposit_amount_duedate: '[security_deposit_amount_duedate]',
                booking_created_at: '[booking_created_at]',
                outstanding_amount: '[outstanding_amount]',
                security_deposit_and_outstanding: '[security_deposit_and_outstanding]'
            }
        },
        {
            title: 'Rental contracts',
            templates: ['lt_retal_contract', 'Rental'],
            codes: {
                contract_id: '[rental_contracts attribute=contract_id]',
                status: '[rental_contracts attribute=status]',
                contract_date: '[rental_contracts attribute=contract_date]',
                rented_from: '[rental_contracts attribute=rented_from]',
                rented_to: '[rental_contracts attribute=rented_to]',
                invoice_required: '[rental_contracts attribute=invoice_required]',
                billing_data: '[rental_contracts attribute=billing_data]',
                rental_price: '[rental_contracts attribute=rental_price]',
                rental_price_tottal: '[rental_contracts attribute=rental_price_tottal]',
                vat: '[rental_contracts attribute=vat]',
                gross_rental: '[rental_contracts attribute=gross_rental]',
                deposit: '[rental_contracts attribute=deposit]',
                months_rent_prepaid: '[rental_contracts attribute=months_rent_prepaid]',
                value_prepaid_rent: '[rental_contracts attribute=value_prepaid_rent]',
                agency_commission: '[rental_contracts attribute=agency_commission]',
                managment_commission: '[rental_contracts attribute=managment_commission]',
                payments: '[rental_contracts attribute=payments]',
                utilities: '[rental_contracts attribute=utilities]',
            }
        },
        {
            title: 'Constructions',
            templates: ['construction', 'constructions', 'viewings'],
            codes: {
                reference: '[construction attribute=reference]',
                user_reference: '[construction attribute=user_reference]',
                agency_reference: '[construction attribute=agency_reference]',
                status: '[construction attribute=status]',
                licensed: '[construction attribute=licensed]',
                type: '[construction attribute=type]',
                sharing: '[construction attribute=sharing]',
                show_on: '[construction attribute=show_on]',
                featured: '[construction attribute=featured]',
                own: '[construction attribute=own]',
                owner: '[construction attribute=owner]',
                year_building_start: '[construction attribute=year_building_start]',
                country: '[construction attribute=country]',
                region: '[construction attribute=region]',
                city: '[construction attribute=city]',
                postal_code: '[construction attribute=postal_code]',
                street: '[construction attribute=street]',
                street_number: '[construction attribute=street_number]',
                alternative_latitude: '[construction attribute=alternative_latitude]',
                alternative_longitude: '[construction attribute=alternative_longitude]',
                area: '[construction attribute=area]',
                location: '[construction attribute=location]',
                bedrooms_from: '[construction attribute=bedrooms_from]',
                bedrooms_to: '[construction attribute=bedrooms_to]',
                bathrooms_from: '[construction attribute=bathrooms_from]',
                bathrooms_to: '[construction attribute=bathrooms_to]',
                dimensions_uom: '[construction attribute=dimensions_uom]',
                built_size_from: '[construction attribute=built_size_from]',
                built_size_to: '[construction attribute=built_size_to]',
                title: '[construction attribute=title limit=100]',
                description: '[construction attribute=description limit=600]',
                currency: '[construction attribute=currency]',
                province: '[construction attribute=province]',
                exclusive: '[construction attribute=exclusive]',
                address: '[construction attribute=address]',
                latitude: '[construction attribute=latitude]',
                longitude: '[construction attribute=longitude]',
                price_from: '[construction attribute=price_from]',
                price_to: '[construction attribute=price_to]',
                images: '[construction attribute=images width=auto height=250 size=500]',
                activities_room: '[construction attribute=general_features type=activities_room]',
                bbq_area: '[construction attribute=general_features type=bbq_area]',
                community_pool: '[construction attribute=general_features type=community_pool]',
                elevators: '[construction attribute=general_features type=elevators]',
                fitness_centre: '[construction attribute=general_features type=fitness_centre]',
                floors: '[construction attribute=general_features type=floors]',
                furniture: '[construction attribute=general_features type=furniture]',
                gardens: '[construction attribute=general_features type=gardens]',
                kitchens: '[construction attribute=general_features type=kitchens]',
                private_garden: '[construction attribute=general_features type=private_garden]',
                private_pools: '[construction attribute=general_features type=private_pools]',
                spa: '[construction attribute=general_features type=spa]',
                storage_rooms: '[construction attribute=general_features type=storage_rooms]',
                terraces: '[construction attribute=general_features type=terraces]',
                underground_parking: '[construction attribute=general_features type=underground_parking]',
            }
        },
        {
            title: 'Task',
            templates: ['task'],
            codes: {
                subject: '[task attribute=subject]',
                priority: '[task attribute=priority]',
                repeate_type: '[task attribute=repeate_type]',
                start_date: '[task attribute=start_date]',
                start_time: '[task attribute=start_time]',
                status: '[task attribute=status]',
                end_date: '[task attribute=end_date]',
                end_time: '[task attribute=end_time]',
                from: '[task attribute=from]',
                assignee: '[task attribute=assignee]',
                related_to: '[task attribute=related_to]',
                description: '[task attribute=description]',
                contacts: '[task attribute=contacts]'
            }
        },
        {
            title: 'Viewing',
            templates: ['viewings_comment', 'to_listing_agent_cross_agency_request_for_viewing', 'to_ori_generating_user_viewing_confirmation', 'to_listing_agent_viewing_cancelled', 'to_ori_generating_user_viewing_cancelled'],
            codes: {
                submited_questionnaire: '[submited_questionnaire]',
                comment_questionaire: '[comment_questionaire]'
            }
        },
        {
            title: 'Invoice',
            templates: ['invoice'],
            codes: {
                invoice_number: '[invoice attribute=invoice_number]',
                billingAddress: '[invoice attribute=billingAddress]',
                subject: '[invoice attribute=subject]',
                invoiceDate: '[invoice attribute=invoice_date]',
                dueDate: '[invoice attribute=due_date]',
                entityName: '[invoice attribute=entityName]',
                entityEmail: '[invoice attribute=entityEmail]',
                entityLanguage: '[invoice attribute=entityLanguage]',
                invoiceStatus: '[invoice attribute=invoiceStatus]',
                totalInvoice: '[invoice attribute=total_invoice]',
                totalPaid: '[invoice attribute=total_paid]',
                outstandingBalance: '[invoice attribute=outstanding_balance]',
                outstandingPercentage: '[invoice attribute=outstanding_percentage]',
                type: '[invoice attribute=type]',
                Currency: '[invoice attribute=currency]',
                paymentMethod: '[invoice attribute= paymentMethod]',
            }
        },
        {
            title: 'Moorings',
            templates: ['moorings', 'viewings'],
            codes: {
                reference: '[mooring attribute=reference]',
                other_reference: '[mooring attribute=external_reference]',
                external_reference: '[mooring attribute=agency_reference]',
                status: '[mooring attribute=status]',
                occupancy_status: '[mooring attribute=occupancy_status]',
                available_for: '[mooring attribute=show_on]',
                currency: '[mooring attribute=currency]',
                listed_until: '[mooring attribute=listed_until]',
                own: '[mooring attribute=switch type=own]',
                sale: '[mooring attribute=switch type=sale]',
                mls_sale: '[mooring attribute=switch type=mls_sale]',
                fractional: '[mooring attribute=switch type=fractional]',
                mls: '[mooring attribute=switch type=mls]',
                rent: '[mooring attribute=switch type=rent]',
                mls_rent: '[mooring attribute=switch type=mls_rent]',
                exclusive: '[mooring attribute=switch type=exclusive]',
                agency: '[mooring attribute=agency]',
                listing_agent: '[mooring attribute=listing_agent]',
                year_built: '[mooring attribute=year_built]',
                type_one: '[mooring attribute=type_one]',
                mooring_number: '[mooring attribute=mooring_number]',
                port_name: '[mooring attribute=port_name]',
                address: '[mooring attribute=address]',
                city: '[mooring attribute=city]',
                province: '[mooring attribute=province]',
                region: '[mooring attribute=region]',
                country: '[mooring attribute=country]',
                postal_code: '[mooring attribute=postal_code]',
                street: '[mooring attribute=street]',
                street_number: '[mooring attribute=street_number]',
                latitude: '[mooring attribute=latitude]',
                longitude: '[mooring attribute=longitude]',
                dimensions: '[mooring attribute=dimensions]',
                length: '[mooring attribute=length]',
                beam_width: '[mooring attribute=beam]',
                depth: '[mooring attribute=depth]',
                height_above_water: '[mooring attribute=height_above_water]',
                car_parking: '[mooring attribute=car_parking]',
                max_boat_underwater_draft: '[mooring attribute=max_boat_underwater_draft]',
                total_number_of_berths: '[mooring attribute=total_number_of_berths]',
                email: '[mooring attribute=email]',
                max_boat_width: '[mooring attribute=max_boat_width]',
                max_boat_length: '[mooring attribute=max_boat_length]',
                country_phone_code: '[mooring attribute=country_phone_code]',
                phone: '[mooring attribute=phone]',
                secondary_phone: '[mooring attribute=secondary_phone]',
                max_boat_air_draft: '[mooring attribute=max_boat_air_draft]',
                web: '[mooring attribute=web]',
                vhf: '[mooring attribute=vhf]',
                wifi_connection: '[mooring attribute=marina_features type=wifi-connection]',
                adsl_internet_connection: '[mooring attribute=marina_features type=adsl-internet-connection]',
                water: '[mooring attribute=marina_features type=water]',
                electricity: '[mooring attribute=marina_features type=electricity]',
                title: '[mooring attribute=title lang=EN]',
                description: '[mooring attribute=description lang=EN]',
                seo_title: '[mooring attribute=seo_title lang=EN]',
                seo_description: '[mooring attribute=seo_description lang=EN]',
                rental_title: '[mooring attribute=rental_title lang=EN]',
                rental_seo_title: '[mooring attribute=rental_seo_title lang=EN]',
                rental_description: '[mooring attribute=rental_description lang=EN]',
                price: '[mooring attribute=price]',
                previous_price: '[mooring attribute=previous_price]',
                price_per_uom: '[mooring attribute=price_per_uom]',
                recommended_price: '[mooring attribute=recommended_price]',
                price_on_demand: '[mooring attribute=switch type=price_on_demand]',
                commission_unit: '[mooring attribute=commission_unit]',
                commission: '[mooring attribute=commission]',
                commission_unit_owner: '[mooring attribute=commission_unit_owner]',
                commission_owner: '[mooring attribute=commission_owner]',
                commission_unit_split: '[mooring attribute=commission_unit_split]',
                commission_split: '[mooring attribute=commission_split]',
                commission_notes: '[mooring attribute=commission_notes]',
                rnt_commission_unit: '[mooring attribute=rnt_commission_unit]',
                rnt_commission: '[mooring attribute=rnt_commission]',
                rnt_commission_unit_owner: '[mooring attribute=rnt_commission_unit_owner]',
                rnt_commission_owner: '[mooring attribute=rnt_commission_owner]',
                rnt_commission_unit_split: '[mooring attribute=rnt_commission_unit_split]',
                rnt_commission_split: '[mooring attribute=rnt_commission_split]',
                rnt_management_commission: '[mooring attribute=rnt_management_commission]',
                rnt_rental_deposit: '[mooring attribute=rnt_rental_deposit]',
                rnt_security_deposit: '[mooring attribute=rnt_security_deposit]',
                rnt_minimum_stay: '[mooring attribute=rnt_minimum_stay]',
                rnt_stay_type: '[mooring attribute=rnt_stay_type]',
                conditions: '[mooring attribute=conditions]',
                st_rental_price: '[mooring attribute=st_rental_price]',
                images: '[mooring attribute=images select=1]',
            }
        },
        {
            title: 'Boats',
            templates: ['boats', 'viewings'],
            codes: {
                reference: '[boat attribute=reference]',
                other_reference: '[boat attribute=external_reference]',
                external_reference: '[boat attribute=agency_reference]',
                status: '[boat attribute=status]',
                occupancy_status: '[boat attribute=occupancy_status]',
                available_for: '[boat attribute=show_on]',
                currency: '[boat attribute=currency]',
                listed_until: '[boat attribute=listed_until]',
                own: '[boat attribute=switch type=own]',
                sale: '[boat attribute=switch type=sale]',
                mls_sale: '[boat attribute=switch type=mls_sale]',
                fractional: '[boat attribute=switch type=fractional]',
                mls: '[boat attribute=switch type=mls]',
                rent: '[boat attribute=switch type=rent]',
                mls_rent: '[boat attribute=switch type=mls_rent]',
                exclusive: '[boat attribute=switch type=exclusive]',
                agency: '[boat attribute=agency]',
                listing_agent: '[boat attribute=listing_agent]',
                year_built: '[boat attribute=year_built]',
                type_one: '[boat attribute=type_one]',
                type_two: '[boat attribute=type_two]',
                mooring_reference: '[boat attribute=mooring_reference]',
                boat_name: '[boat attribute=boat_name]',
                boat_builder: '[boat attribute=boat_builder]',
                make_name: '[boat attribute=make_name]',
                boat_model: '[boat attribute=boat_model]',
                address: '[boat attribute=address]',
                city: '[boat attribute=city]',
                province: '[boat attribute=province]',
                region: '[boat attribute=region]',
                country: '[boat attribute=country]',
                postal_code: '[boat attribute=postal_code]',
                street: '[boat attribute=street]',
                street_number: '[boat attribute=street_number]',
                latitude: '[boat attribute=latitude]',
                longitude: '[boat attribute=longitude]',
                hull_material: '[boat attribute=hull_material]',
                boat_color: '[boat attribute=boat_color]',
                purpose: '[boat attribute=purpose]',
                max_passengers: '[boat attribute=max_passengers]',
                control_decks: '[boat attribute=control_decks]',
                dimensions: '[boat attribute=dimensions]',
                weights: '[boat attribute=weights]',
                volume: '[boat attribute=volume]',
                consumption: '[boat attribute=consumption]',
                engines: '[boat attribute=engines]',
                engine_power: '[boat attribute=engine_power]',
                engine_hours: '[boat attribute=engine_hours]',
                cruising_speed: '[boat attribute=cruising_speed]',
                maximum_speed: '[boat attribute=maximum_speed]',
                drive_type: '[boat attribute=drive_type]',
                fuel_type: '[boat attribute=fuel_type]',
                fuel_tanks: '[boat attribute=fuel_tanks]',
                fuel_tank_capacity: '[boat attribute=fuel_tank_capacity]',
                shaft_horsepower: '[boat attribute=shaft_horsepower]',
                fresh_water_tanks_quantity: '[boat attribute=fresh_water_tanks_quantity]',
                fresh_water_tanks_capacity: '[boat attribute=fresh_water_tanks_capacity]',
                holding_tanks_quantity: '[boat attribute=holding_tanks_quantity]',
                holding_tanks_capacity: '[boat attribute=holding_tanks_capacity]',
                cube: '[boat attribute=cube]',
                cabins: '[boat attribute=cabins]',
                single_berths: '[boat attribute=single_berths]',
                double_berths: '[boat attribute=double_berths]',
                twin_berths: '[boat attribute=twin_berths]',
                bathrooms: '[boat attribute=bathrooms]',
                heads: '[boat attribute=heads]',
                length_overall: '[boat attribute=length_overall]',
                length_at_waterline: '[boat attribute=length_at_waterline]',
                length_between_perpendiculars: '[boat attribute=length_between_perpendiculars]',
                beam_overall: '[boat attribute=beam_overall]',
                beam_on_centerline: '[boat attribute=beam_on_centerline]',
                depth: '[boat attribute=depth]',
                dry_weight: '[boat attribute=dry_weight]',
                displacement: '[boat attribute=displacement]',
                draft: '[boat attribute=draft]',
                air_draft: '[boat attribute=air_draft]',
                freeboard: '[boat attribute=freeboard]',
                refrigerator_american: '[boat attribute=kitchen type=refrigerator_(american)]',
                refrigerator_normal: '[boat attribute=kitchen type=refrigerator_(normal)]',
                bank_repossession: '[boat attribute=categories type=bank_repossession]',
                bargain: '[boat attribute=categories type=bargain]',
                excellent: '[boat attribute=condition type=excellent]',
                satellite: '[boat attribute=entertainment type=satellite]',
                television: '[boat attribute=entertainment type=television]',
                bar: '[boat attribute=leisure type=bar]',
                barbecue: '[boat attribute=leisure type=barbecue]',
                boat_tracking: '[boat attribute=security type=boat_tracking]',
                motion_sensor: '[boat attribute=security type=motion_sensor]',
                title: '[boat attribute=title lang=EN]',
                description: '[boat attribute=description lang=EN]',
                seo_title: '[boat attribute=seo_title lang=EN]',
                seo_description: '[boat attribute=seo_description lang=EN]',
                rental_title: '[boat attribute=rental_title lang=EN]',
                rental_seo_title: '[boat attribute=rental_seo_title lang=EN]',
                rental_description: '[boat attribute=rental_description lang=EN]',
                price: '[boat attribute=price]',
                previous_price: '[boat attribute=previous_price]',
                price_per_uom: '[boat attribute=price_per_uom]',
                recommended_price: '[boat attribute=recommended_price]',
                price_on_demand: '[boat attribute=switch type=price_on_demand]',
                commission_unit: '[boat attribute=commission_unit]',
                commission: '[boat attribute=commission]',
                commission_unit_owner: '[boat attribute=commission_unit_owner]',
                commission_owner: '[boat attribute=commission_owner]',
                commission_unit_split: '[boat attribute=commission_unit_split]',
                commission_split: '[boat attribute=commission_split]',
                commission_notes: '[boat attribute=commission_notes]',
                rnt_commission_unit: '[boat attribute=rnt_commission_unit]',
                rnt_commission: '[boat attribute=rnt_commission]',
                rnt_commission_unit_owner: '[boat attribute=rnt_commission_unit_owner]',
                rnt_commission_owner: '[boat attribute=rnt_commission_owner]',
                rnt_commission_unit_split: '[boat attribute=rnt_commission_unit_split]',
                rnt_commission_split: '[boat attribute=rnt_commission_split]',
                rnt_management_commission: '[boat attribute=rnt_management_commission]',
                rnt_rental_deposit: '[boat attribute=rnt_rental_deposit]',
                rnt_security_deposit: '[boat attribute=rnt_security_deposit]',
                rnt_minimum_stay: '[boat attribute=rnt_minimum_stay]',
                rnt_stay_type: '[boat attribute=rnt_stay_type]',
                conditions: '[boat attribute=conditions]',
                st_rental_price: '[boat attribute=st_rental_price]',
                boat_registration_no: '[boat attribute=boat_registration_no]',
                owner: '[boat attribute=owner]',
                private_info: '[boat attribute=private_info]',
                images: '[boat attribute=images select=1]',
            }
        },
        {
            title: 'Today Date',
            templates: ['Owner','Buyer','Properties','Offers','Sales','Rental','Rental(create + upload)','Company','constructions','moorings','boats', 'account', 'Accounts'],
            codes: {
                today_date : '[today_date]'
            }
        },
        {
            title: 'agency',
            templates: ['viewing_tours'],
            codes: {
                'logo': '[agency attribute=logo]',
                'phone': '[agency attribute=phone]',
                'fax': '[agency attribute=fax]',
                'email': '[agency attribute=email]',
                'commercial_name': '[agency attribute=commercial_name]',
                'address_street': '[agency attribute=complete_address_street]',
                'address_street_number': '[agency attribute=complete_address_street_number]',
                'address_postal_code': '[agency attribute=complete_address_postal_code]',
                'address_city': '[agency attribute=complete_address_city]',
                'address_province': '[agency attribute=complete_address_province]',
                'address_community': '[agency attribute=complete_address_community]',
                'address_sub_community': '[agency attribute=complete_address_sub_community]',
                'address_country': '[agency attribute=complete_address_country]',
            }
        },
        {
            title: 'viewing_tours',
            templates: ['viewing_tours', 'agency'],
            codes: {
                'list_commercial_reference': '[viewing attribute=list_commercial_reference,index=0]',
                'list_commercial_address': '[viewing attribute=list_commercial_address,index=0]',
                'list_lat': '[viewing attribute=list_lat,index=0]',
                'list_lng': '[viewing attribute=list_lng,index=0]',
                'list_time': '[viewing attribute=list_time,index=0]',
                'date': '[viewing attribute=date]',
                'account_name': '[viewing attribute=account_name]',
                'assignee_name': '[viewing attribute=assignee]',
                'viewing_table': '[viewing_table]'
            }
        },
    ];

}
