import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FuseDirectivesModule } from '@fuse/directives/directives';
import { FusePipesModule } from '@fuse/pipes/pipes.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { ComposeDialogModule } from 'app/directives/compose-email/compose-dialog/compose-dialog.module';
import { EmailDraftsModule } from 'app/directives/compose-email/email-drafts/email-drafts.module';
import { PreviewEmailModule } from 'app/directives/compose-email/preview-email/preview-email.module';
import { FormDialogModule } from 'app/directives/custom-formly/form-dialog/form-dialog.module';
import { AppDirectivesModule } from 'app/directives/directives';
import { CreateListModule } from 'app/directives/distribution-list/dialogs/create-list/create-list.module';
import { DistributionListModule } from 'app/directives/distribution-list/dialogs/distribution-list/distribution-list.module';
import { ViewListModule } from 'app/directives/distribution-list/dialogs/view-list/view-list.module';
import { EditContentModule } from 'app/directives/editable-content/form-dialog/form-dialog.module';
import { EventsModule } from 'app/directives/event-sequences/dialogs/events/events.module';
import { SequencesModule } from 'app/directives/event-sequences/dialogs/sequences/sequences.module';
import { JsonViewerModule } from 'app/directives/json-viewer/json-viewer/json-viewer.module';
import { MailchimpListModule } from 'app/directives/mailchimp/mailchimp-list/mailchimp-list.module';
import { AddressMapModule } from 'app/directives/maps/address-map/address-map.module';
import { DrawMapModule } from 'app/directives/maps/draw-map/draw-map.module';
import { PlansDialogModule } from 'app/directives/mls-plans/plans-dialog/plans-dialog.module';
import { PdfListDialogModule } from 'app/directives/pdf-templates/pdf-list-dialog/pdf-list-dialog.module';
import { AttachmentListDialogModule } from 'app/directives/download-attachments/attachment-list-dialog/attachment-list-dialog.module';
import { PdfViewDialogModule } from 'app/directives/pdf-templates/pdf-view-dialog/pdf-view-dialog.module';
import { SendlinblueListModule } from 'app/directives/sendinblue/sendinblue-list/sendlinblue-list.module';
import { SendgridListModule } from '../app/directives/sendgrid/sendgrid-list/sendgrid-list.module' ;
import { TemplateViewerModule } from 'app/directives/template-viewer/template-viewer/template-viewer.module';
import { WindowcardsListDialogModule } from 'app/directives/window-cards/windowcards-list-dialog/windowcards-list-dialog.module';
import { WindowcardsViewDialogModule } from 'app/directives/window-cards/windowcards-view-dialog/windowcards-view-dialog.module';
import { AppPipesModule } from 'app/pipes/app-pipes.module';
import { GlobalFuntions } from 'app/_helpers';
import { WhatsappMessageModule } from 'app/directives/whatsapp/whatsapp-message/whatsapp-message.module';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,

        FlexLayoutModule,

        FuseDirectivesModule,
        AppDirectivesModule,
        FusePipesModule,
        AppPipesModule,
        ComposeDialogModule,
        PlansDialogModule,
        JsonViewerModule,
        TemplateViewerModule,
        PdfListDialogModule,
        AttachmentListDialogModule,
        PdfViewDialogModule,
        PreviewEmailModule,
        WindowcardsListDialogModule,
        WindowcardsViewDialogModule,
        EmailDraftsModule,
        SequencesModule,
        AddressMapModule,
        EventsModule,
        DistributionListModule,
        MailchimpListModule,
        CreateListModule,
        ViewListModule,
        DrawMapModule,
        FormDialogModule,
        EditContentModule,
        SendlinblueListModule,
        SendgridListModule,
        WhatsappMessageModule
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,

        FlexLayoutModule,

        FuseDirectivesModule,
        AppDirectivesModule,
        FusePipesModule,
        AppPipesModule,
        ComposeDialogModule,
        PlansDialogModule,
        JsonViewerModule,
        TemplateViewerModule,
        PdfListDialogModule,
        AttachmentListDialogModule,
        PdfViewDialogModule,
        WindowcardsListDialogModule,
        WindowcardsViewDialogModule,
        PreviewEmailModule,
        EmailDraftsModule,
        SequencesModule,
        AddressMapModule,
        EventsModule,
        DistributionListModule,
        MailchimpListModule,
        CreateListModule,
        ViewListModule,
        DrawMapModule,

        TranslateModule,
        NgSelectModule,
        FormDialogModule,
        EditContentModule,
        SendlinblueListModule,
        SendgridListModule,
        WhatsappMessageModule
    ],
    providers: [GlobalFuntions],
})
export class FuseSharedModule {}
