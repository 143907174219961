import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MlsNotificationsComponent } from './mls-notifications.component';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MlsRequestModule } from '../mls-request/mls-request.module';



@NgModule({
  declarations: [MlsNotificationsComponent],
  imports: [
    CommonModule,

    FuseSharedModule,

    MatButtonModule,
    MatDatepickerModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatToolbarModule,
    MatTooltipModule,
    MatSnackBarModule,

    MlsRequestModule

  ],
  exports: [MlsNotificationsComponent],
  entryComponents: [MlsNotificationsComponent]
})
export class MlsNotificationsModule { }
