import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AttachmentListDialogComponent } from './attachment-list-dialog.component';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseDirectivesModule } from '@fuse/directives/directives';
import { AppPipesModule } from 'app/pipes/app-pipes.module';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';

@NgModule({
    declarations: [AttachmentListDialogComponent],
    imports: [
        CommonModule,
        FormsModule,
        MatIconModule,
        MatToolbarModule,
        MatButtonModule,
        MatTooltipModule,
        TranslateModule,
        FlexLayoutModule,
        FuseDirectivesModule,
        AppPipesModule,
        MatProgressSpinnerModule,
        MatMenuModule,
        MatRadioModule,
        MatSlideToggleModule,
        MatCheckboxModule
    ],
    exports: [AttachmentListDialogComponent],
    entryComponents: [AttachmentListDialogComponent]
})
export class AttachmentListDialogModule { }
