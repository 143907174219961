import { Component, OnInit, ViewEncapsulation, Inject, ViewChild, ChangeDetectorRef, AfterViewChecked, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { isEmpty, cloneDeep, forEach, includes } from 'lodash';
import { FormGroup, FormBuilder } from '@angular/forms';
import { isDate } from 'date-fns';
import * as moment from 'moment';
import * as _ from 'lodash';
import { BookingsService, AuthenticationService, DropdownsService } from 'app/services';
import { ConfirmDialogComponent } from 'app/components/confirm-dialog/confirm-dialog.component';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { GlobalFuntions } from 'app/_helpers';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { CommercialPropertiesService } from 'app/services/commercial-properties.service';
import { differenceInDays } from 'date-fns';

@Component({
    selector: 'app-bookings-create-modal',
    templateUrl: './bookings-create-modal.component.html',
    styleUrls: ['./bookings-create-modal.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class BookingsCreateModalComponent implements OnInit, AfterViewChecked, AfterViewInit {

    date: Date;
    bookings: FormGroup;
    propertyId = '';
    propertyRef: Number;
    id: any;
    showDelete = false as boolean;
    private _unsubscribeAll: Subject<any>;
    officeData: any;
    user: any;
    viewRecord = false as boolean;
    model: any;
    loadingResults: any;
    changedProperties = {} as any;

    constructor(
        public matDialogRef: MatDialogRef<BookingsCreateModalComponent>,
        private formBuilder: FormBuilder,
        private _matDialog: MatDialog,
        private _bookingsService: BookingsService,
        private _commercialService: CommercialPropertiesService,
        private cdRef: ChangeDetectorRef,
        private _globalFuntions: GlobalFuntions,
        @Inject(MAT_DIALOG_DATA) public _data: any,
        public _authenticationService: AuthenticationService,
        private _snackBar: MatSnackBar,
        private _translateService: TranslateService,
        private router: Router,
        public _dropdownService: DropdownsService

    ) {
        if (this._authenticationService.currentUserValue.user_role === 'admin') {
            this.viewRecord = true;
        }
        if (!isEmpty(this._data)) {
            this.date = new Date();
            if (this._data.date) {
                if (isDate(this._data.date)) {
                    this.date = this._data.date;
                } else {
                    this.date = new Date(this._data.date);
                }
            }
            if (this._data?.propertyId) {
                this.propertyId = this._data.propertyId;
            }
            if (this._data?.propertyRef && this._data.propertyRef != '') {
                this.propertyRef = Number(this._data.propertyRef);
            }
            if (this._data?.bookingId) {
                this.id = this._data.bookingId;
            }
        }
        this.officeData = this._authenticationService.currentOfficeValue;
        this.user = this._authenticationService.currentUserValue;

        let officeRecord = false;
        if (this._globalFuntions.getUserRole(this.user.user_role)) {
            officeRecord = true;
        }

        this.bookings = this.formBuilder.group({
            _id: [],
            client: { value: '', disabled: this.viewRecord },
            reference: { value: '', disabled: true },
            external_reference: { value: '', disabled: this.viewRecord },
            // client_email: { value: '', disabled: true },
            // client_mobile: { value: '', disabled: true },
            client_email: (''),
            client_mobile: (''),
            client_name: (''),
            created_by: { value: '', disabled: true },
            created_at: { value: '', disabled: true },
            refund_security_deposit_by_date: { value: '', disabled: false },
            // account_data: this.formBuilder.group({
            //     email: { value: '', disabled: true },
            //     client_mobile: { value: '', disabled: true },
            // }),
            offices: { value: [this.officeData._id], disabled: officeRecord },
            date_from: { value: '', disabled: this.viewRecord },
            date_until: { value: '', disabled: this.viewRecord },
            nights: { value: '', disabled: true },
            property: { value: this.propertyId, disabled: this.viewRecord },
            property_reference: { value: this.propertyRef, disabled: this.viewRecord },
            sleeps: { value: '', disabled: true },
            total_guests: { value: '', disabled: this.viewRecord },
            adults: { value: '', disabled: this.viewRecord },
            children: { value: '', disabled: this.viewRecord },
            infants: { value: '', disabled: this.viewRecord },
            source: { value: '', disabled: this.viewRecord },
            status: { value: '', disabled: this.viewRecord },
            price: { value: 0, disabled: this.viewRecord },
            discount: { value: 0, disabled: this.viewRecord },
            net_price: { value: 0, disabled: this.viewRecord },
            new_price: { value: 0, disabled: this.viewRecord },
            commission_base: { value: '', disabled: this.viewRecord },
            commission: { value: '', disabled: this.viewRecord },
            company: { value: '', disabled: this.viewRecord },
            extras: this.formBuilder.array([]),
            total_extras: { value: '', disabled: true },
            extras_cleaning: this.formBuilder.array([]),
            total_clean: { value: '', disabled: true },
            end_price: { value: '', disabled: this.viewRecord },
            deposit_price: { value: '', disabled: this.viewRecord },
            received: { value: '', disabled: true },
            last_payment: { value: '', disabled: this.viewRecord },
            outstanding_price: { value: '', disabled: true },
            final_payment: { value: '', disabled: this.viewRecord },
            refundable_security_deposit: { value: '', disabled: this.viewRecord },
            refundable_deposit_received: { value: '', disabled: true },
            refundable_deposit_outstanding: { value: '', disabled: true },
            refunded_deposit: { value: '', disabled: true },
            refunded: { value: '', disabled: true },
            external_commission: { value: '', disabled: this.viewRecord },
            external_commission_amount: { value: '', disabled: this.viewRecord },
            total_amount_third_parties: { value: '', disabled: true },
            total_amount_owner: { value: '', disabled: true },
            total_net_amount_agency: { value: '', disabled: true },
            comments: { value: '', disabled: this.viewRecord },
            extern_comments: { value: '', disabled: this.viewRecord },
            cleaning_comments: { value: '', disabled: this.viewRecord },
            clean_arrival: { value: true, disabled: this.viewRecord },
            clean_departure: { value: true, disabled: this.viewRecord },
            flight_number: { value: '', disabled: this.viewRecord },
            flight_arrival_time: { value: '', disabled: this.viewRecord },
            property_arrival_time: { value: '', disabled: this.viewRecord },
            flight_departure_time: { value: '', disabled: this.viewRecord },
            property_departure_time: { value: '', disabled: this.viewRecord },
            guests: this.formBuilder.array([]),
        });

        this.bookings.get('date_from').setValue(moment(this.date.toString()).format());
        const dateUntil = moment(this.bookings.get('date_from').value).add(7, 'days');
        this.bookings.get('date_until').setValue(dateUntil.format());
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {

        if (this.id) {
            this.showDelete = true;
            this._bookingsService.getById(this.id).subscribe((data: any) => {
                this.bookings.patchValue(data.body);
                if (data.body && data.body.date_from) {
                    this.bookings.get('date_from').setValue(moment(data.body.date_from * 1000));
                }
                if (data.body && data.body.date_until) {
                    this.bookings.get('date_until').setValue(moment(data.body.date_until * 1000));
                }
                if (data.body && data.body.created_by) {
                    this.bookings.get('created_by').setValue(data.body.user_data.full_name);
                }
                if (data?.body && data?.body?.p_data) {
                    if(data?.body?.p_data?.res_id){
                        this.bookings.get('property').setValue(data?.body?.p_data?._id);
                    }
                }
            });
        } else {
            this.showDelete = false;
            this.bookings.get('status').setValue('active');
            let df = new Date(this.bookings.get('date_from').value);
            df.setHours(0, 0, 0, 0);
            let dt = new Date(this.bookings.get('date_until').value);
            dt.setHours(0, 0, 0, 0);
            let nights = differenceInDays(dt, df);
            this.bookings.get('nights').setValue(nights);
        }


        /*  Calculating Extras  */
        this.bookings.get('extras').valueChanges
            .subscribe(() => {
                let total = 0;
                forEach(this.bookings.get('extras').value, (extra) => {
                    total = total + extra.price;
                });
                this.bookings.get('total_extras').setValue(total);
            });

        /*  Calculating Cleanings  */
        this.bookings.get('extras_cleaning').valueChanges
            .subscribe(() => {
                let total = 0;
                forEach(this.bookings.get('extras_cleaning').value, (extra) => {
                    total = total + extra.price;
                });
                this.bookings.get('total_clean').setValue(total);
            });
    }

    // submitForm(): void {
    //     const booking = cloneDeep(this.bookings.value);
    //     const dateFrom = moment(booking.date_from)
    //         .set({
    //             hour: 12,
    //             minute: 0,
    //             second: 0,
    //             millisecond: 0
    //         })
    //         .unix();
    //     const dateUntil = moment(booking.date_until)
    //         .set({
    //             hour: 12,
    //             minute: 0,
    //             second: 0,
    //             millisecond: 0
    //         })
    //         .unix();

    //     booking.date_from = dateFrom;
    //     booking.date_until = dateUntil;
    //     if (this.id) {
    //         this._bookingsService.update(booking, this.id).subscribe((data: any) => {
    //             this.matDialogRef.close('refresh');
    //         });
    //     } else {
    //         delete booking._id;
    //         this._bookingsService.create(booking).subscribe((data: any) => {
    //             this.matDialogRef.close('refresh');
    //         });
    //     }
    // }

    submitForm(value: any): void {
        this.loadingResults = true
        const booking = _.cloneDeep(this.bookings.value);
        if (booking.source_beta && !booking.source) {
            booking.source = booking.source_beta;
            delete booking.source_beta;
        } else if (!booking.source_beta) {
            delete booking.source_beta;
        }
        const dateFrom = moment.tz(booking.date_from, 'Asia/Dubai').unix();
        const dateUntil = moment.tz(booking.date_until, 'Asia/Dubai').unix();
        booking.date_from = dateFrom + 40000;
        booking.date_until = dateUntil + 40000;
        if (this.id) {
            this._bookingsService.update(booking, this.id).subscribe({
                next: (data) => {
                    this.loadingResults = false;
                    this.matDialogRef.close(data);
                    this._snackBar.open(this._translateService.instant('Booking updated successfully'), this._translateService.instant('Close'), {
                        duration: 2000,
                    });
                    this.changedProperties = { booking : 'Booking was updated' }
                    this.saveHistory(this.bookings.get('property').value)
                // this.saveGuestSignatures(this.id).then();
            },
                error: (e) => {
                    this.loadingResults = false;
                },
            });
            
        } else {
            booking.created_by = this.user;
            if (!booking.office) {
                booking.offices = [this.user.user_office_data._id];
            }
            if (includes(this.router.url, 'commercial-calender') || includes(this.router.url, 'commercial-calendar')) {
                booking.model = 'commercial'
            }

            this._bookingsService.create(booking).subscribe({
                    next: (data) => {
                        this.loadingResults = false;
                        this.matDialogRef.close(data);
                        this._snackBar.open(this._translateService.instant('Booking created successfully'), this._translateService.instant('Close'), {
                            duration: 2000,
                        });
                        this.changedProperties = { booking : 'Booking was created' }
                        this.saveHistory(this.bookings.get('property').value)
                        // this.saveGuestSignatures(data._id).then();
                    },
                    error: (e) => {
                        this.loadingResults = false;
                    },
            });
        }
    }

    calculate(): void {
        const deep = cloneDeep(this.bookings.getRawValue());
        this._bookingsService.calculate(deep).subscribe((data: any) => {
            this.bookings.patchValue(data);
        });
    }
    ngAfterViewInit(): void {
        if (!this.id) {
            // this.bookings.get('date_from').setValue(moment());
            // this.bookings.get('date_until').setValue(moment());
            this.bookings.get('created_at').setValue(moment());
            this.bookings.get('created_by').setValue(this.user.full_name);
        }
    }
    ngAfterViewChecked(): void {
        this.cdRef.detectChanges();
    }
    delete(data: any): void {
        const booking = data.value;
        booking.date_from = Date.parse(booking.date_from) / 1000;
        booking.date_until = Date.parse(booking.date_until) / 1000;

        const dialog = this._matDialog.open(ConfirmDialogComponent, {
            disableClose: false
        });
        dialog.componentInstance.confirmMessage = 'why_to_delete';
        dialog.componentInstance.reason = true;
        dialog.afterClosed().subscribe(result => {
            if (result) {
                this.loadingResults = true;
                booking.comments = dialog.componentInstance.comments;
                booking.status = 'archived';
                this._bookingsService.update(booking, booking._id)
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe((response: any) => {
                        this.loadingResults = false;
                        this._snackBar.open(this._translateService.instant('Booking archived successfully'), this._translateService.instant('Close'), {
                            duration: 2000,
                        });
                        // this.dataReload.setValue(true);
                        this.matDialogRef.close('refresh');
                        this.changedProperties = { status : 'archived' };
                        this.saveHistory(this.bookings.get('property').value);

                    });
                    this.loadingResults = false;

            }
        });
    }

    saveHistory(propertyId): void {
        let property;
        if (!isEmpty(this.propertyId)) {
            this._commercialService.getPropertyData(propertyId).subscribe((propertyData:any)=>{
                if (propertyData) {
                    if (!isEmpty(this.changedProperties)) {
                        this._dropdownService
                            .savePropertiesHistory(
                                propertyData._id,
                                propertyData.reference,
                                this.changedProperties,
                                'commercial_property'
                            )
                            .subscribe((res) => {
                                this.changedProperties = {} as any;
                            });
                    }
                }
            })
    }
    }
}
