import { Component, OnInit, ViewEncapsulation, forwardRef, HostBinding, Input, EventEmitter, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import * as _ from 'lodash';
import * as moment from 'moment';

@Component({
    selector: 'app-time-picker',
    templateUrl: './time-picker.component.html',
    styleUrls: ['./time-picker.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TimePickerComponent),
            multi: true
        }
    ]
})
export class TimePickerComponent implements OnInit, ControlValueAccessor {

    @HostBinding('attr.id')
    externalId = '';
    date: Date;
    @Output() refresh = new EventEmitter();
    @Output() timeChange: EventEmitter<Date> = new EventEmitter<Date>();
    @Input() returnFormate: string;
    @Input() title: string;
    // @Input() allow_time_zone: boolean;
    @Input() disable: boolean = false;
    @Input()
    set id(value: string) {
        this._ID = value;
        this.externalId = null;
    }

    get id(): string {
        return this._ID;
    }

    private _ID = '';

    // tslint:disable-next-line: no-input-rename
    _value: any;
    onChange: any = () => { };
    onTouched: any = () => { };

    constructor() { }

    ngOnInit(): void {
    }

    registerOnChange(fn): void {
        this.onChange = fn;
    }

    writeValue(value): void {
        if (value) {
            // https://gitlab.optimasit.com/arsl/optima-crm-v2/-/issues/3966
            // if(!this.allow_time_zone)
            this.date = new Date(value);
        } else {
            this.date = new Date();
        }
        if (value) {
            // if(this.allow_time_zone) {
            //     this._value = moment.tz(value, "Asia/Dubai").format("HH:mm");
            // }else {
            this._value = this.date.getHours() + ':' + this.date.getMinutes();
            // }
        }
    }

    registerOnTouched(fn): void {
        this.onTouched = fn;
    }

    onChangeValue(): void {
        const split: any = _.split(this._value, ':', 2);
        const H = split[0] * 1;
        const M = split[1] * 1;
        this.date?.setHours(H);
        this.date?.setMinutes(M);
        this.onChange(this.date); // #3765
        this.onTouched();
        this.timeChange.emit(this.date);
        this.refresh.next(true);
    }

}
