import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import {
    FormArray,
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { FuseUtils } from '@fuse/utils';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService, DropdownsService } from 'app/services';
import { OwnersService } from 'app/services/owners.service';
import { GlobalFuntions } from 'app/_helpers';
import { forEach, isEmpty, uniq } from 'lodash';
import { merge, Subject } from 'rxjs';
import { startWith, switchMap, tap } from 'rxjs/operators';
@Component({
    selector: 'quick-add-owners',
    templateUrl: './quick-add-owners.component.html',
    styleUrls: ['./quick-add-owners.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class QuickAddOwnersComponent implements OnInit {
    form: FormGroup;
    partners: FormArray;
    viewRecord: boolean;
    public nationalities: any = [];
    public nationalitiesSearch: FormControl = new FormControl();
    searching: boolean;
    officeData: any;
    currentUser: any;
    countries: any = [];
    public offices: any = [];
    officeGroups = [] as any;
    searchOffices$ = new Subject<string>();
    isClick = false as boolean;
    systemLang: any;
    sources: any;

    public cities: any = [];
    public citySearch: FormControl = new FormControl();
    public communities: any = [];
    public communitySearch: FormControl = new FormControl();
    public subCommunities: any = [];
    public subCommunitySearch: FormControl = new FormControl();

    constructor(
        private _dropdownsService: DropdownsService,
        public dialogRef: MatDialogRef<QuickAddOwnersComponent>,
        private _globalFunction: GlobalFuntions,
        @Inject(MAT_DIALOG_DATA) public _data: any,
        private _formBuilder: FormBuilder,
        private ownersService: OwnersService,
        private route: ActivatedRoute,
        public _authenticationService: AuthenticationService,
        private router: Router,
        public _translateService: TranslateService,
        private _globalFunctions: GlobalFuntions
    ) {
        let officeRecord = false;
        if (!isEmpty(this._data)) {
            this.viewRecord = true;
            officeRecord = true;
        }
        this.currentUser = this._authenticationService.currentUserValue;
        this.officeData = this._authenticationService.currentOfficeValue;
        this.systemLang = this._translateService.currentLang;
        if (this._globalFunctions.getUserRole(this.currentUser.user_role)) {
            officeRecord = true;
        }

        this.form = this._formBuilder.group({
            id: [{ value: '', disabled: true }],
            type: [{ value: '', disabled: this.viewRecord }],
            forename: [
                { value: '', disabled: this.viewRecord }
            ],
            surname: [
                { value: '', disabled: this.viewRecord }
            ],
            date_of_birth: [{ value: '', disabled: this.viewRecord }],
            document_type: [{ value: '', disabled: this.viewRecord }],
            civil_status: { value: '', disabled: this.viewRecord },
            children: { value: '', disabled: this.viewRecord },
            title: { value: '', disabled: this.viewRecord },
            gender: { value: '', disabled: this.viewRecord },
            document_number: [
                { value: '', disabled: this.viewRecord }
            ],
            search: [{ value: '', disabled: this.viewRecord }],
            home_phone: [{ value: '', disabled: this.viewRecord }],
            work_phone: [{ value: '', disabled: this.viewRecord }],
            mobile: [
                { value: '', disabled: this.viewRecord },
                Validators.required,
            ],
            email: [
                { value: '', disabled: this.viewRecord },
                Validators.required,
            ],
            skype_id: [{ value: '', disabled: this.viewRecord }],
            street: [{ value: '', disabled: this.viewRecord }],
            streetnumber: [{ value: '', disabled: this.viewRecord }],
            comments: [{ value: '', disabled: this.viewRecord }],
            source: [{ value: '', disabled: this.viewRecord }],
            // gdpr_comment: [{ value: '', disabled: this.viewRecord }],
            options: [{ value: '', disabled: this.viewRecord }],
            complete_address: [{ value: '', disabled: this.viewRecord }],
            complete_address_country: [
                { value: '', disabled: this.viewRecord },
            ],
            complete_address_region: [{ value: '', disabled: this.viewRecord }],
            complete_address_city: [{ value: '', disabled: this.viewRecord }],
            postcode: [{ value: '', disabled: this.viewRecord }],
            complete_address_province: [
                { value: '', disabled: this.viewRecord },
            ],
            status: [{ value: '', disabled: this.viewRecord }],
            regionSearch: [{ value: '', disabled: this.viewRecord }],
            listing: this._formBuilder.array([]),
            custom_settings: this._formBuilder.array([]),
            financial_accounts: this._formBuilder.array([]),
            account_holder: [{ value: '', disabled: this.viewRecord }],
            bic_swift: [{ value: '', disabled: this.viewRecord }],
            iban: [{ value: '', disabled: this.viewRecord }],
            avg_balance: [{ value: '', disabled: this.viewRecord }],
            key: [{ value: '', disabled: this.viewRecord }],
            value: [{ value: '', disabled: this.viewRecord }],
            company_name: [
                { value: '', disabled: this.viewRecord }
            ],
            legal_form: [
                { value: '', disabled: this.viewRecord }
            ],
            tax_id: [
                { value: '', disabled: this.viewRecord }
            ],
            nationality: [{ value: '', disabled: this.viewRecord }],
            language: [{ value: '', disabled: this.viewRecord }],
            address: [{ value: '', disabled: this.viewRecord }],
            country: [{ value: '', disabled: this.viewRecord }],
            city: [{ value: '', disabled: this.viewRecord }],
            community: [{ value: '', disabled: this.viewRecord }],
            sub_community: [{ value: '', disabled: this.viewRecord }],
            location: [{ value: '', disabled: this.viewRecord }],
            properties: [{ value: '', disabled: this.viewRecord }],
            communication_language: [{ value: '', disabled: this.viewRecord }],
            offices: [{ value: [this.officeData._id], disabled: officeRecord }],
            probability: [{ value: '', disabled: this.viewRecord }],
            // gdpr_status: [{ value: '', disabled: this.viewRecord }],
            company: [{ value: '', disabled: this.viewRecord }],
            company_contact: [{ value: '', disabled: this.viewRecord }],
            partners: this._formBuilder.array([]),
        });
    }

    ngOnInit(): void {
        this.form.get('type').setValue('individual');
        this.form.get('status').setValue('lead');
        if (!isEmpty(this._data)) {
            this.ownersService
                .getById(this._data)
                .subscribe((response: any) => {
                    this.form.patchValue(response);
                    this.form.get('address').setValue(response?.address);
                    if(response?.address?.formatted_address){
                        this.form.get('address').setValue(response?.address?.formatted_address);
                    }
                });
        }
        this.partners = this.form.get('partners') as FormArray;
        if (this.partners.length === 0) {
            this.partners.push(this.createPartners());
        }
        this._dropdownsService.getSources('Owners').subscribe((data: any) => {
            this.sources = data.body;
        });
        this.searchOffices$
            .pipe(
                startWith(''),
                switchMap((search: string) => {
                    return this._dropdownsService.getOffices(search);
                })
            )
            .subscribe((data: any) => {
                this.offices = data.body;
                forEach(this.offices, (value) => {
                    if (value.office_groups) {
                        if (typeof value.office_groups == 'object') {
                            value.office_groups.forEach((group) => {
                                this.officeGroups.push(group);
                            });
                        } else {
                            this.officeGroups.push(value.office_groups);
                        }
                    }
                });
                this.officeGroups = uniq(this.officeGroups);
                this.officeGroups.sort();
            });

        merge(
            this.nationalitiesSearch.valueChanges,
            this.form.get('nationality').valueChanges
        )
            .pipe(
                startWith(''),
                tap(() => (this.searching = true)),
                switchMap(() => {
                    return this._dropdownsService.getNationalities();
                })
            )
            .subscribe((data: any) => {
                let search = this.form.get('nationality').value;
                if (this.nationalitiesSearch.value) {
                    search = this.nationalitiesSearch.value;
                }
                this.searching = false;
                this.nationalities = data.body;
                this.nationalities = FuseUtils.filterArrayByString(
                    this.nationalities,
                    search.toLowerCase()
                );
            });
        
        this._dropdownsService.getUaeCountries().subscribe((data: any) => {
            this.countries = data;
        });

        merge(this.citySearch.valueChanges, this.form.get('city').valueChanges)
            .pipe(
                startWith(''),
                tap(() => this.searching = true),
                switchMap(() => {
                    let search = this.form.get('city').value;
                    if (this.citySearch.value) {
                        search = this.citySearch.value;
                    }
                    return this._dropdownsService.getUaeLocations(search, 0, 0);
                })
            )
            .subscribe((data: any) => {
                this.searching = false;
                this.cities = data.docs;
            });
        
        merge(this.communitySearch.valueChanges, this.form.get('city').valueChanges, this.form.get('community').valueChanges)
            .pipe(
                startWith(''),
                tap(() => this.searching = true),
                switchMap(() => {
                    let search = this.form.get('community').value;
                    if (this.communitySearch.value) {
                        search = this.communitySearch.value;
                    }
                    return this._dropdownsService.getUaeLocations(search, this.form.get('city').value, 1);
                })
            )
            .subscribe((data: any) => {
                this.searching = false;
                this.communities = data.docs;
            });
        
        merge(this.subCommunitySearch.valueChanges, this.form.get('community').valueChanges, this.form.get('sub_community').valueChanges)
            .pipe(
                startWith(''),
                tap(() => this.searching = true),
                switchMap(() => {
                    let search = this.form.get('sub_community').value;
                    if (this.subCommunitySearch.value) {
                        search = this.subCommunitySearch.value;
                    }
                    return this._dropdownsService.getUaeLocations(search, this.form.get('community').value, 2);
                })
            )
            .subscribe((data: any) => {
                this.searching = false;
                this.subCommunities = data.docs;
            });

    }
    submitForm(): void {
        this.isClick = true;
        const ownerData = this._globalFunction.clearObject(
            this.form.getRawValue()
        ) as any;
        this.ownersService.create(ownerData).subscribe(
            (data: any) => {
                this.dialogRef.close(data);
            },
            (err: any) => {
                this.isClick = false;
            }
        );
    }
    onClickClose(): void {
        this.dialogRef.close();
    }
    createPartners(): FormGroup {
        return this._formBuilder.group({
            title: { value: '', disabled: false },
            forename: { value: '', disabled: false },
            surname: { value: '', disabled: false },
            date_of_birth: { value: '', disabled: false },
            age: { value: '', disabled: false },
            civil_status: { value: '', disabled: false },
            nationality: { value: '', disabled: false },
            document_type: { value: '', disabled: false },
            document_number: { value: '', disabled: false },
            document_type_two: { value: '', disabled: false },
            document_number_two: { value: '', disabled: false },
            children: { value: '', disabled: false },
            gender: { value: '', disabled: false },
            phone: { value: '', disabled: false },
            email: { value: '', disabled: false },
        });
    }
    onChangetitle(): void {
        const title = this.form.get('title').value;
        if (title === 'Mr.') {
            this.form.get('gender').setValue('Male');
        }
        if (title === 'Ms.' || title === 'Mrs.' || title === 'Miss.') {
            this.form.get('gender').setValue('Female');
        }
    }
    onChangePartnertitle(index: any): void {
        const title = this.form.get(`partners.${index}.title`).value;
        if (title === 'Mr.') {
            this.form.get(`partners.${index}.gender`).setValue('Male');
        }
        if (title === 'Ms.' || title === 'Mrs.' || title === 'Miss.') {
            this.form.get(`partners.${index}.gender`).setValue('Female');
        }
    }

    citySelect(): void {
        this.form.patchValue({
            'community':null,
            'sub_community':null
        })
    }
}
