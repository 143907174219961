import { Component, OnInit, ViewEncapsulation, EventEmitter } from '@angular/core';
import { EmailsService } from 'app/services/emails.service';
import { MatDialogRef } from '@angular/material/dialog';
import { Subject, merge } from 'rxjs';
import { fuseAnimations } from '@fuse/animations';
import { takeUntil, startWith, switchMap, tap, filter, map } from 'rxjs/operators';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-email-drafts',
  templateUrl: './email-drafts.component.html',
  styleUrls: ['./email-drafts.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class EmailDraftsComponent implements OnInit {

  private _unsubscribeAll: Subject<any>;
  drafts = [] as any;
  searchFilter: FormGroup;
  displayedColumns = ['date', 'subject', 'from', 'to', 'action'];
  searchColumns: string[] = ['search_subject', 'search_from', 'search_to'];
  dataReload: EventEmitter<any>;
  loadingResults = false;
  constructor(
    private _formBuilder: FormBuilder,
    private _emailsService: EmailsService,
    public matDialogRef: MatDialogRef<EmailDraftsComponent>,
    private mediaObserver: MediaObserver
  ) {
    this._unsubscribeAll = new Subject();
    this.dataReload = new EventEmitter();
    this.searchFilter = this._formBuilder.group({
      'draft_data.from': [],
      'draft_data.to': [],
      'draft_data.subject': []
    });
   }

  ngOnInit(): void {

    this.mediaObserver.asObservable()
      .pipe(
          takeUntil(this._unsubscribeAll),
          filter((changes: MediaChange[]) => changes.length > 0),
          map((changes: MediaChange[]) => changes[0])
      )
      .subscribe((change: MediaChange) => {
          if (change.mqAlias === 'xs') {
              this.displayedColumns = ['from', 'to', 'action'];
              this.searchColumns = ['search_from', 'search_to'];
          } else {
              this.displayedColumns = ['date', 'subject', 'from', 'to', 'action'];
              this.searchColumns = ['search_subject', 'search_from', 'search_to'];
          }
      });

    merge(this.dataReload, this.searchFilter.valueChanges)
    .pipe(
      tap(() => this.loadingResults = true),
      takeUntil(this._unsubscribeAll),
      startWith(''),
      switchMap((search: any) => {
        return this._emailsService.loadDrafts(this.searchFilter.getRawValue());
      })
    )
    .subscribe((data: any) => {
      this.drafts = data;
      for (const item of data)
      {
        const mailString = [];
        for (const emailObj of item.draft_data.to) {
          mailString.push(emailObj.email);
        }
        item.draft_data.to = mailString;
      }
      this.loadingResults = false;
    }, () => this.loadingResults = false);
  }
  
  delete(id): void {
    this._emailsService.deleteDraft(id)
    .pipe(
      tap(() => this.loadingResults = true),
      takeUntil(this._unsubscribeAll)
    )
    .subscribe(() => {
      this.loadingResults = false;
      this.searchFilter.reset();
      this.dataReload.next(true);
    }, () => this.loadingResults = false);
  }

  loadEmail(data, id): void {
    this._emailsService.deleteDraft(id)
    .pipe(
      tap(() => this.loadingResults = true),
      takeUntil(this._unsubscribeAll)
    )
    .subscribe(() => {
      this.loadingResults = false;
      this.matDialogRef.close(data);
    }, () => this.loadingResults = false);
  }

}
