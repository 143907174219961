import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { DropdownsService } from 'app/services';
import { FormGroup, FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subject, merge } from 'rxjs';
import { takeUntil, switchMap } from 'rxjs/operators';
import { includes, remove, cloneDeep } from 'lodash';
import { OwnerConfirmModalComponent } from './owner-confirm-modal/owner-confirm-modal.component';
import { GlobalFuntions } from 'app/_helpers';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { OwnersService } from 'app/services/owners.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-owner-mass-update',
  templateUrl: './owner-mass-update.component.html',
  styleUrls: ['./owner-mass-update.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class OwnerMassUpdateComponent implements OnInit {

  gdpr = [
    {
      value: '0',
      name: this._translateService.instant('No value')
    },
    {
      value: '1',
      name: this._translateService.instant('Accepted')
    },
    {
      value: '2',
      name: this._translateService.instant('Pending')
    },
    {
      value: '3',
      name: this._translateService.instant('no-permission')
    }];

  massUpdate: FormGroup;
  searchOffices$ = new Subject<string>();
  offices: any;
  searchAsignee$ = new Subject<string>();
  asignee: any;
  selectedOffices = [];
  selectedAssignee = [];
  searchDialogRef: MatDialogRef<OwnerConfirmModalComponent, any>;
  isHideGdpr = false as boolean;

  private _unsubscribeAll: Subject<any>;

  constructor(
    public matDialogRef: MatDialogRef<OwnerMassUpdateComponent>,
    @Inject(MAT_DIALOG_DATA) public _data: any,
    private _dropdownsService: DropdownsService,
    private formBuilder: FormBuilder,
    private _translateService: TranslateService,
    private _matDialog: MatDialog,
    private _globalFuntions: GlobalFuntions,
    private _ownerService: OwnersService,
    private _snackBar: MatSnackBar
  ) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this.massUpdate = this.formBuilder.group({
      offices: (''),
      assign_agent: (''),
      // gdpr_status: (''),
      delete_flag: (''),
      office_append: (''),
      assigne_append: ('')
    });

    this.searchOffices$
      .pipe(
        takeUntil(this._unsubscribeAll),
        switchMap((search: string) => {
          return this._dropdownsService.getOffices(search);
        }),
      )
      .subscribe((data: any) => {
        this.offices = data.body;
      });

    merge(this.searchAsignee$, this.massUpdate.get('offices').valueChanges)
      .pipe(
        takeUntil(this._unsubscribeAll),
        switchMap((search: string) => {
          return this._dropdownsService.usersAssignee(this.massUpdate.get('offices').value);
        }),
      )
      .subscribe((data: any) => {
        this.asignee = data;
      });

  }

  doMassUpdate(): void {
    const data = cloneDeep(this.massUpdate.value);
    data.selected_offices = this.selectedOffices;
    data.selected_assignee = this.selectedAssignee;
    this.searchDialogRef = this._matDialog.open(OwnerConfirmModalComponent, {
      disableClose: false,
      data: {
        owners: this._data.owners,
        data: data
      }
    });
    this.searchDialogRef.afterClosed()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((response: any) => {
          if (response) {
              this._ownerService
                  .massUpdate({ ids: this._data.owners, data: data })
                  .pipe(takeUntil(this._unsubscribeAll))
                  .subscribe((data: any) => {
                      if (data.error) {
                          this._snackBar.open(
                              this._translateService.instant(
                                  'error occoured while updating!'
                              ),
                              this._translateService.instant('Close'),
                              {
                                  duration: 2000,
                              }
                          );
                      } else {
                          this._snackBar.open(
                              data.nModified +
                              ' ' +
                              this._translateService.instant(
                                  'records successfully'
                              ),
                              this._translateService.instant('Close'),
                              {
                                  duration: 2000,
                              }
                          );
                      }
                      this.matDialogRef.close(
                          this._globalFuntions.cleanObject(
                              this.massUpdate.value
                          )
                      );
                  }, (err) => {
                      console.error(err);
                  });
              //   this.matDialogRef.close(this._globalFuntions.cleanObject(this.massUpdate.value));
          }
      });
  }

  onAddRemoveOffices(event: any, toDo: boolean): void {
    if (toDo && !includes(this.selectedOffices, event.name)) {
      this.selectedOffices = [...this.selectedOffices, event.name];
    }
    if (!toDo) {
      remove(this.selectedOffices, (n) => n === event.value.name);
    }
  }

  onAddRemoveAssignee(event: any, toDo: boolean): void {
    if (toDo && !includes(this.selectedAssignee, event.full_name)) {
      this.selectedAssignee = [...this.selectedAssignee, event.full_name];
    }
    if (!toDo) {
      remove(this.selectedAssignee, (n) => n === event.value.full_name);
    }
  }

}
