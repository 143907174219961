import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MeetingCreateFormComponent } from './meeting-create-form.component';
import { FuseSharedModule } from '@fuse/shared.module';
import { TimePickerModule } from 'app/components/time-picker/time-picker.module';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule, MatRippleModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatChipsModule } from '@angular/material/chips';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSnackBarModule } from '@angular/material/snack-bar';

@NgModule({
  declarations: [
    MeetingCreateFormComponent
  ],
  imports: [
    CommonModule,
    FuseSharedModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatOptionModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    MatMenuModule,
    MatToolbarModule,
    MatChipsModule,
    MatExpansionModule,
    MatRippleModule,
    MatSnackBarModule,
    NgxMatSelectSearchModule,
    TimePickerModule
  ], 
  exports: [
    MeetingCreateFormComponent
  ],
  entryComponents: [
    MeetingCreateFormComponent
  ]
})
export class MeetingCreateFormModule { }
