import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OffersModalComponent } from './offers-modal.component';
import { FuseSharedModule } from '@fuse/shared.module';
import { CounterOffersModule } from '../offers-create/tabs/counter-offers/counter-offers.module';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatDialogModule } from '@angular/material/dialog';



@NgModule({
  declarations: [OffersModalComponent],
  imports: [
    CommonModule,
    FuseSharedModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatToolbarModule,
    MatDialogModule,
    CounterOffersModule
  ],
  exports: [OffersModalComponent],
  entryComponents: [OffersModalComponent]
})
export class OffersModalModule { }
