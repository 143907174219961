import { Component, OnInit, ViewEncapsulation, ViewChild, Inject, ElementRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { fuseAnimations } from '@fuse/animations';
// import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { MatPaginator } from '@angular/material/paginator';
import { environment } from 'environments/environment';
import { filter, map } from 'rxjs/operators';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { ViewingToursService } from 'app/services/viewing-tours.service';
import { forEach, map as lodashMAp } from 'lodash';
import { AuthenticationService, ProvincesService, CountriesService, CitiesService, LocationsService } from 'app/services';
import { TranslateService } from '@ngx-translate/core';
import { GlobalFuntions } from 'app/_helpers';
@Component({
    selector: 'cheat-sheet',
    templateUrl: './cheat-sheet.component.html',
    styleUrls: ['./cheat-sheet.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class CheatSheetComponent implements OnInit {

    env = environment;
    loadingResults = false;
    displayedColumns: string[] = ['images', 'reference', 'price', 'formatted_address', 'property', 'bedrooms', 'built', 'plot', 'basura', 'community_fees', 'key_reference'];
    dataReload: FormControl = new FormControl(null);
    properties: any = {
        data: []
    };
    filter: any = [];
    viewing: any;
    user: any;
    searchDialogRef: any;
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild('wc', { static: false }) wc: any;
    windowCard: any;
    agency: any;

    constructor(
        // private mediaObserver: MediaObserver,
        public matDialogRef: MatDialogRef<CheatSheetComponent>,
        private _viewingToursService: ViewingToursService,
        private _authenticationService: AuthenticationService,
        private _translateService: TranslateService,
        private _countriesService: CountriesService,
        private _provincesService: ProvincesService,
        private _citiesService: CitiesService,
        private _locationService: LocationsService,
        private _globalFunctions: GlobalFuntions,
        @Inject(MAT_DIALOG_DATA) public _data: any
    ) {
        this.viewing = _data;
        this.user = this._authenticationService.currentUserValue;
        this.agency = this._authenticationService.currentAgencyValue;
    }

    ngOnInit(): void {
        if (this.user.user_office_data) {
            if (this.user.user_office_data.country) {
                // this.getCountry(this.user.user_office_data.country);
                this.user.user_office_data.country_name = "United Arab Emirates"
            }
            if (this.user.user_office_data.community) {
                this.getCommunity(this.user.user_office_data.community);
            }
            if (this.user.user_office_data.city) {
                this.getCity(this.user.user_office_data.city);
            }
        }
        this.windowCard = this.viewing;
        this.windowCard.agency_data = this.agency;
        this.windowCard.office_data = this.user.user_office_data;
        if (this.viewing.list) {
            const reference = lodashMAp(this.viewing.list, 'commercial_reference');
            this._viewingToursService.getListedProperties(reference).subscribe((data: any) => {
                let properties = data.body;
                this.windowCard.properties_data = properties.map(property => ({
                    ...property,
                    address: this._globalFunctions.getFallbackAddress(property)
                }))
            });
        }
    }

    domPdfDownload(): void {
        console.log(this.windowCard);
        this._viewingToursService.getSheetPdf(this.windowCard)
            .subscribe((data: any) => {
                if (this.viewing && this.viewing._id && this.viewing._id !== 'undefined') {
                    window.open(`${environment.cdnUrl}viewing_docs/${this.viewing._id}/${data.file_name}`, '_blank');
                } else {
                    window.open(`${environment.cdnUrl}viewing_docs/${data.file_name}`, '_blank');
                }
            });
    }

    // getCountry(key): any {
    //     const filter = {
    //         options: {
    //             limit: 30,
    //             page: 1
    //         },
    //         query: {
    //             key: key
    //         }
    //     };
    //     this._countriesService.getData(filter)
    //     .subscribe((data: any) => {
    //         this.user.user_office_data.country_name = data?.docs[0]?.value[this._translateService.currentLang];
    //     });
    // }

    getCommunity(key): any {
        const filter = {
            options: {
                limit: 30,
                page: 1
            },
            query: {
                key: key
            }
        };
        this._locationService.getData(filter)
        .subscribe((data: any) => {
            this.user.user_office_data.community_name = data?.docs[0]?.value[this._translateService.currentLang];
        });
    }

    getCity(key): any {
        const filter = {
            options: {
                limit: 30,
                page: 1
            },
            query: {
                key: key
            }
        };
        this._locationService.getData(filter)
        .subscribe((data: any) => {
            this.user.user_office_data.city_name = data?.docs[0]?.value[this._translateService.currentLang];
        });
    }

}






