import { Component, OnInit, ViewEncapsulation, Input, Injector, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, FormControl } from '@angular/forms';
import * as _ from 'lodash';
import { fuseAnimations } from '@fuse/animations';
import { DropdownsService } from 'app/services/dropdowns.service';
import { TranslateService } from '@ngx-translate/core';
import { merge, Subject } from 'rxjs';
import { startWith, tap, switchMap, takeUntil } from 'rxjs/operators';
import { AccountsCreateComponent } from '../../accounts-create.component';
import { map } from 'lodash';

@Component({
    selector: 'tab-contact',
    templateUrl: './tab-contact.component.html',
    styleUrls: ['./tab-contact.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})

export class TabContactComponent implements OnInit, AfterViewChecked {

    @Input() form: FormGroup;
    languages: any;
    currentLang: string;
    partners: FormArray;
    searching: boolean;
    countries: any;
    // countrySearch: FormControl = new FormControl('');
    // regions: any;
    // regionSearch: FormControl = new FormControl('');
    // provinces: any;
    // provinceSearch: FormControl = new FormControl('');
    // citySearch: FormControl = new FormControl('');
    cities: any;
    communities: any;
    subCommunities: any;
    locations: any;
    stateSearch: FormControl = new FormControl('');
    communitySearch: FormControl = new FormControl('');
    subCommunitySearch: FormControl = new FormControl('');
    locationSearch: FormControl = new FormControl('');
    _accountsCreateComponent: AccountsCreateComponent;
    private _unsubscribeAll: Subject<any>;

    constructor(
        private formBuilder: FormBuilder,
        private _translateService: TranslateService,
        private _dropdownsService: DropdownsService,
        private cdRef: ChangeDetectorRef,
        private injector: Injector,
    ) {
        this.currentLang = this._translateService.currentLang === 'es' ? 'es_AR' : this._translateService.currentLang;
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {

        this._translateService.onLangChange
            .pipe(startWith(''), takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this.currentLang = this._translateService.currentLang === 'es' ? 'es_AR' : this._translateService.currentLang;
                this.getLanguages();
            });

        if (!this.form) {
            this._accountsCreateComponent = this.injector.get(AccountsCreateComponent) as AccountsCreateComponent;
            this.form = this._accountsCreateComponent.form;
        }
        this.partners = this.form.get('partners') as FormArray;

        // this._dropdownsService.getCommunicationLanguages().subscribe((data: any) => {
        //     this.comLang = data.body;
        // });

        this._dropdownsService.getUaeCountries().subscribe((data: any) => {
            this.countries = data;
        });

        merge(this.stateSearch.valueChanges, this.form.get('city').valueChanges)
            .pipe(
                startWith(''),
                tap(() => this.searching = true),
                switchMap(() => {
                    let search = this.form.get('city').value;
                    if (this.stateSearch.value) {
                        search = this.stateSearch.value;
                    }
                    return this._dropdownsService.getUaeLocations(search, 0, 0);
                })
            )
            .subscribe((data: any) => {
                this.cities = data.docs;
                this.searching = false;
            });

        merge(this.communitySearch.valueChanges, this.form.get('city').valueChanges, this.form.get('community').valueChanges)
            .pipe(
                startWith(''),
                tap(() => this.searching = true),
                switchMap(() => {
                    let search = this.form.get('community').value;
                    if (this.communitySearch.value) {
                        search = this.communitySearch.value;
                    }
                    return this._dropdownsService.getUaeLocations(search, this.form.get('city').value, 1);
                })
            )
            .subscribe((data: any) => {
                this.communities = data.docs;
                this.searching = false;
            });
            
        merge(this.subCommunitySearch.valueChanges, this.form.get('community').valueChanges, this.form.get('sub_community').valueChanges)
            .pipe(
                startWith(''),
                tap(() => this.searching = true),
                switchMap(() => {
                    let search = this.form.get('sub_community').value;
                    if (this.subCommunitySearch.value) {
                        search = this.subCommunitySearch.value;
                    }
                    return this._dropdownsService.getUaeLocations(search, this.form.get('community').value, 2);
                })
            )
            .subscribe((data: any) => {
                this.subCommunities = data.docs;
                this.searching = false;
            });

        merge(this.locationSearch.valueChanges, this.form.get('sub_community').valueChanges, this.form.get('location').valueChanges)
            .pipe(
                startWith(''),
                tap(() => this.searching = true),
                switchMap(() => {
                    let search = this.form.get('location').value;
                    
                    if (this.locationSearch.value) {
                        search = this.locationSearch.value;
                    }
                    return this._dropdownsService.getUaeLocations(search, this.form.get('sub_community').value, 3);
                })
            )
            .subscribe((data: any) => {
                this.locations = data?.docs;
                this.searching = false;
            });
    }

    getLanguages(): void {
        this._dropdownsService.getLanguages()
            .subscribe((data: any) => {
                const languages = map(data.body, (body) => {
                    body.label = body.value[this.currentLang];
                    body.value = body.key;
                    return body;
                });
                this.languages = languages;
            });
    }

    citySelect(): void {
        this.form.patchValue({
            'community':null,
            'sub_community':null,
            'location':null
        })
    }

    communitySelect(): void {
        this.form.patchValue({
            'sub_community':null,
            'location':null
        })
    }

    subCommunitySelect():void{
        this.form.patchValue({
            'location':null
        })
    }

    ngAfterViewChecked(): void {
        this.cdRef.detectChanges();
    }
}
