import { Component, OnInit, ViewEncapsulation, ChangeDetectorRef, Inject, ChangeDetectionStrategy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { merge } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResetPasswordComponent implements OnInit {

  form: FormGroup;
  strength: number;
  disable = true as boolean;
  currentLang: any;
  constructor(
    public matDialogRef: MatDialogRef<ResetPasswordComponent>,
    private _formBuilder: FormBuilder,
    private cdRef: ChangeDetectorRef,
    private _translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public _data: any
  ) {
    this.form = this._formBuilder.group({
      _id: (''),
      agency: (''),
      username: { value: '', disabled: false },
      role: { value: '', disabled: false },
      firstname: { value: '', disabled: false },
      lastname: { value: '', disabled: false },
      phone: { value: '', disabled: false },
      email: { value: '', disabled: false },
      commercial_name: { value: '', disabled: false },
      fax: { value: '', disabled: false },
      status: { value: '', disabled: false },
      website: { value: '', disabled: false },
      owner_name: { value: '', disabled: false },
      owner_phone: { value: '', disabled: false },
      fiscal_name: { value: '', disabled: false },
      name: { value: '', disabled: false },
      postal_code: { value: '', disabled: false },
      manager_name: { value: '', disabled: false },
      manager_phone: { value: '', disabled: false },
      manager_email: { value: '', disabled: false },
      password_hash: [{ value: '', disabled: false }, [Validators.required, Validators.minLength(8)]],
      password_repeat: [{ value: '', disabled: false }, [Validators.required]]
    },
      {
        validator: MustMatch('password_hash', 'password_repeat')
      });

    this.form.patchValue(this._data);
    this.form.get('password_hash').setValue(null);
  }

  ngOnInit(): void {

    this._translateService.onLangChange
      .pipe(startWith(''))
      .subscribe(() => {
        this.currentLang = this._translateService.currentLang === 'es' ? 'es_AR' : this._translateService.currentLang;
      });

    merge(this.form.get('password_hash').valueChanges, this.form.get('password_repeat').valueChanges)
      .subscribe(() => {
        if (this.strength === 100 && this.form.get('password_hash').value === this.form.get('password_repeat').value) {
          this.disable = false;
        } else {
          this.disable = true;
        }
      });
  }
  onStrengthChanged(event: any): void {
    this.strength = event;
  }

}
export function MustMatch(controlName: string, matchingControlName: string): any {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      // return if another validator has already found an error on the matchingControl
      return;
    }

    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  };
}
