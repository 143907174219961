import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonDialogComponent } from './common-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FuseSharedModule } from '@fuse/shared.module';



@NgModule({
  declarations: [CommonDialogComponent],
  imports: [
    CommonModule,

    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    FuseSharedModule
  ],
  entryComponents: [CommonDialogComponent]
})
export class CommonDialogModule { }
