import {
    Component,
    Injector,
    Input,
    OnDestroy,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { TranslateService } from '@ngx-translate/core';
import { GLOBALS } from 'app/config/globals';
import { AgenciesService, AuthenticationService, DropdownsService } from 'app/services';
import { AccountsService } from 'app/services/accounts.service';
import { CrmMenuService } from 'app/services/crm-menu.service';
import { BaseService } from 'app/_helpers/base/base.service';
import * as _ from 'lodash';
import { forEach, uniq } from 'lodash';
import { merge, Subject } from 'rxjs';
import {
    distinctUntilChanged,
    first,
    startWith,
    switchMap,
    takeUntil,
    tap,
    debounceTime
} from 'rxjs/operators';
import { AccountsCreateComponent } from '../../accounts-create.component';
import { GlobalFuntions } from 'app/_helpers';
import { GetCommercialReferencePipe } from 'app/pipes/get-commercial-reference.pipe';

@Component({
    selector: 'tab-agency',
    templateUrl: './tab-agency.component.html',
    styleUrls: ['./tab-agency.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
    providers: [GetCommercialReferencePipe]
})
export class TabAgencyComponent implements OnInit, OnDestroy {
    @Input() form: FormGroup;
    // form: FormGroup;
    residentialPropertySwitch: any;
    tab: number;
    value: FormControl = new FormControl('');
    id: string;
    selectedProbability: any;
    searchOffices$ = new Subject<string>();
    offices: any = [];
    officeGroups = [] as any;
    searchAssignedTo$ = new Subject<string>();
    assignedTo: any = [];
    defaultRef: any;
    currentAgency: any;
    searchProperties$ = new Subject<string>();
    searchCommercials$ = new Subject<string>();
    searchMoorings$ = new Subject<string>();
    searchBoats$ = new Subject<string>();
    properties: any = [];
    commercials: any = [];
    mooringsData: any = [];
    boatsData: any = [];
    // public officeSearch: FormControl = new FormControl();
    public invoiceSeries: any = [];
    seriesSearch: FormControl = new FormControl('');
    public companies: any = [];
    public companiesSearch: FormControl = new FormControl();
    public Accountscompanies: any = [];
    public AccountscompaniesSearch: FormControl = new FormControl();
    public sources: any = [];
    public sourceSearch: FormControl = new FormControl();
    public leadstatus: any;
    UsersByAgencies: any;
    public originated: any = [];
    originatedSearch: FormControl = new FormControl();
    searching: boolean;
    currentLang: string;
    surname: string;
    viewRecord: boolean;
    displayedColumns: string[] = [
        'first_name',
        'middle_name',
        'surname',
        'search_at',
    ];
    dataSource = null;
    user: any;
    searchProbability: FormControl = new FormControl('');
    relatedCompanies: FormArray;
    customSettings: FormArray;
    systemLang: any;
    searchCompany: FormControl = new FormControl('');
    isInvoiceModule: any;
    isBoatModule: any;
    isMooringModule: any;
    isHideGdpr = false as boolean;

    public queryOptions: any;
    public paymentMethods = [];
    public loaders = { ...GLOBALS.loaders }; // To display/hide loading spinner

    private _unsubscribeAll: Subject<any>;

    probabilities = [
        { value: '1', name: '0-20%', colour: 'blue' },
        { value: '2', name: '21-50%', colour: 'green' },
        { value: '3', name: '51-70%', colour: 'yellow' },
        { value: '4', name: '71-99%', colour: 'red' },
        { value: '5', name: '100%', colour: 'white' },
    ];
    _accountsCreateComponent: AccountsCreateComponent;
    currentOffice: any;
    isReadOnly = false;
    showOfficeMenu = false;
    contactStatuses: any = [];

    constructor(
        private _authenticationService: AuthenticationService,
        private accountsService: AccountsService,
        private _dropdownsService: DropdownsService,
        private _formBuilder: FormBuilder,
        private _translateService: TranslateService,
        private route: ActivatedRoute,
        private router: Router,
        private injector: Injector,
        private _crmMenuService: CrmMenuService,
        private _gService: BaseService,
        private agenciesService: AgenciesService,
        private _globalFunctions: GlobalFuntions,
        private cp_reference: GetCommercialReferencePipe,
    ) {
        this.id = this.route.snapshot.paramMap.get('id');
        this.systemLang =
            this._translateService.currentLang === 'es'
                ? 'es_AR'
                : this._translateService.currentLang;

        let viewRecord = false;
        this.isInvoiceModule = this._crmMenuService.isInvoiceModule;
        if (_.includes(this.router.url, 'view')) {
            viewRecord = true;
        }

        this.user = _authenticationService.currentUserValue;
        this.currentOffice = _authenticationService.currentOfficeValue;
        this.currentAgency = this._authenticationService.currentAgencyValue;
        if (this.currentAgency.pdf_external_ref === 'external_reference') {
            this.defaultRef = this.currentAgency.pdf_external_ref;
        } else if (this.currentAgency.pdf_external_ref === 'agency_reference') {
            this.defaultRef = this.currentAgency.pdf_external_ref;
        } else if (this.currentAgency.pdf_external_ref === 'reference') {
            this.defaultRef = this.currentAgency.pdf_external_ref;
        } else {
            this.defaultRef = 'reference';
        }
        this.currentLang =
            this._translateService.currentLang === 'es'
                ? 'es_AR'
                : this._translateService.currentLang;
        this._unsubscribeAll = new Subject();
        this.isMooringModule = this._crmMenuService.isMooringModule;
        this.isBoatModule = this._crmMenuService.isBoatModule;
    }

    ngOnInit(): void {
        this._dropdownsService.getContactStatuses().subscribe((statuses) =>{
            this.contactStatuses = statuses;
        })
        this.residentialPropertySwitch = this.currentAgency?.residential_properties;
        this._translateService.onLangChange
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this.systemLang =
                    this._translateService.currentLang === 'es'
                        ? 'es_AR'
                        : this._translateService.currentLang;
            });
        if (!this.form) {
            this._accountsCreateComponent = this.injector.get(
                AccountsCreateComponent
            ) as AccountsCreateComponent;
            this.form = this._accountsCreateComponent.form;
        }
        if (!this?.user?.user_master_office) {
            this.isReadOnly = true;
        }
        this.searchOffices$
            .pipe(
                startWith(''),
                distinctUntilChanged(),
                takeUntil(this._unsubscribeAll),
                switchMap((search: string) => {
                    return this._dropdownsService.getOffices(search);
                })
            )
            .subscribe((data: any) => {
                this.offices = data.body;
                this.offices.sort((a, b) => {
                    if (a.name.toUpperCase() < b.name.toUpperCase()) {
                        return -1;
                    }
                    return 1;
                });
                forEach(this.offices, (value) => {
                    if (value.office_groups) {
                        if (typeof value.office_groups == 'object') {
                            value.office_groups.forEach((group) => {
                                this.officeGroups.push(group);
                            });
                        } else {
                            this.officeGroups.push(value.office_groups);
                        }
                    }
                });
                this.officeGroups = uniq(this.officeGroups);
                this.officeGroups.sort();
            });

        this.searchAssignedTo$
            .pipe(
                startWith(''),
                distinctUntilChanged(),
                takeUntil(this._unsubscribeAll),
                switchMap((search: string) => {
                    return this._dropdownsService.getAllAgencyUsers(search);
                })
            )
            .subscribe((data: any) => {
                _.forEach(data, (value) => {
                    value.disabled =
                        !this.currentOffice.master_office &&
                            this.currentOffice._id !== value.office
                            ? true
                            : (
                                this.user?.user_role == 'agency_manager' ||
                                    this.currentOffice.master_office ? false : true
                            );
                    value.label = _.isEmpty(_.trim(value.full_name))
                        ? value.username
                        : value.full_name;
                });
                this.assignedTo = data;
            });

        this.form
            .get('properties_interested')
            .valueChanges.pipe(first())
            .subscribe(() => {
                this.searchProperties$.next('');
            });
        this.form
            .get('commercials_interested')
            .valueChanges.pipe(first())
            .subscribe(() => {
                this.searchCommercials$.next('');
            });
        this.form
            .get('moorings')
            .valueChanges.pipe(first())
            .subscribe(() => {
                this.searchMoorings$.next('');
            });
        this.form
            .get('boats')
            .valueChanges.pipe(first())
            .subscribe(() => {
                this.searchBoats$.next('');
            });
        merge(
            this.searchProperties$,
            this.form.get('properties_interested').valueChanges
        )
            .pipe(
                startWith(''),
                distinctUntilChanged(),
                takeUntil(this._unsubscribeAll),
                switchMap((search: any) => {
                    const pi = this.form.get('properties_interested').value;
                    if (typeof search !== 'string') {
                        search = '';
                    }
                    return this._dropdownsService.getProperty(
                        search,
                        this.form.get('properties_interested').value
                    );
                })
            )
            .subscribe((data: any) => {
                const properties = _.map(data.body, (property) => {
                    if (
                        this.currentAgency.pdf_external_ref ===
                        'external_reference' &&
                        property.agency_reference
                    ) {
                        property.label = property.agency_reference;
                    } else if (
                        this.currentAgency.pdf_external_ref ===
                        'agency_reference' &&
                        property.external_reference
                    ) {
                        property.label = property.external_reference;
                    } else if (
                        this.currentAgency.pdf_external_ref === 'reference'
                    ) {
                        property.label = property.reference;
                    } else {
                        property.label = property.reference;
                    }
                    if (property.hasOwnProperty('type_one')) {
                        property.label =
                            property.label + '-' + property.type_one;
                    }
                    if (property.hasOwnProperty('location')) {
                        property.label =
                            property.label + '-' + property.location;
                    }
                    return property;
                });
                this.properties = properties;
            });

        this.searchCommercials$
            .pipe(
                startWith(''),
                distinctUntilChanged(),
                takeUntil(this._unsubscribeAll),
                switchMap((search: string) => {
                    const filterQuery = {} as any;
                    if (!search) {
                        search = '';
                    }
                    search = search;
                    const queryOr = [];
                    if (Array.isArray(search)) {
                        queryOr.push({ reference: {'$in' : search} });
                    } else {
                        queryOr.push({ reference: Number(search) });
                        queryOr.push({
                            external_reference: {
                                $regex: '.*' + search + '.*',
                                $options: 'i',
                            },
                        });
                        queryOr.push({
                            other_reference: {
                                $regex: '.*' + search + '.*',
                                $options: 'i',
                            },
                        });
                    }
                    filterQuery.$or = queryOr;
                    return this._dropdownsService.getCommercialProperties(
                        filterQuery
                    );
                })
            )
            .subscribe((data: any) => {
                const commercials = _.map(data.body, (property) => {
                    property.label = this.cp_reference.transform(property)
                    if (property.hasOwnProperty('property_type_one') && property?.property_type_one?.value) {
                        property.label = property.label + '-' + property?.property_type_one?.value[this.systemLang];
                    }
                    if (property.hasOwnProperty('property_location') && property?.property_location?.value) {
                        property.label = property.label + '-' + property?.property_location?.value[this.systemLang];
                    }
                    return property;
                });
                this.commercials = commercials;
            });
            if (this.form.get('commercials_interested').value && this.form.get('commercials_interested').value != '' && Array.isArray(this.form.get('commercials_interested').value)) {
                let arrMerge = [];
                arrMerge = _.union(arrMerge, this.form.get('commercials_interested').value);
                if(this.form.get('properties_interested').value && this.form.get('properties_interested').value != '' && Array.isArray(this.form.get('properties_interested').value)) {
                    arrMerge = _.uniq(_.union(arrMerge, this.form.get('properties_interested').value));
                }   
                this.form.get('commercials_interested').setValue(arrMerge);
                this.searchCommercials$.next(this.form.get('commercials_interested').value)
            }else {
                if(this.form.get('properties_interested').value && this.form.get('properties_interested').value != '' && Array.isArray(this.form.get('properties_interested').value)) {
                    this.form.get('commercials_interested').setValue(this.form.get('properties_interested').value);
                    this.searchCommercials$.next(this.form.get('properties_interested').value)
                }
            }
        merge(
            this.searchMoorings$,
            this.form.get('moorings').valueChanges
        )
            .pipe(
                startWith(''),
                distinctUntilChanged(),
                takeUntil(this._unsubscribeAll),
                switchMap((search: string) => {
                    const filter = {
                        query: {
                            archived: { $ne: true },
                        } as any,
                        options: {} as any,
                    } as any;

                    if (!search) {
                        search = '';
                    }
                    search = search;
                    const queryOr = [];
                    queryOr.push({
                        reference: Number(search),
                    });
                    queryOr.push({
                        type_one: {
                            $regex:
                                '.*' + search + '.*',
                            $options: 'i',
                        },
                    });
                    // queryOr.push({
                    //     property_city: {
                    //         $regex:
                    //             '.*' + search + '.*',
                    //         $options: 'i',
                    //     },
                    // });
                    filter.query.$or = queryOr;

                    return this._dropdownsService.getMoorings('mooring_properties', filter);
                }),
            )
            .subscribe((data: any) => {
                const moorings = _.map(data.body, (mooring) => {
                    if (
                        this.currentAgency.pdf_external_ref ===
                        'external_reference' &&
                        mooring.agency_reference
                    ) {
                        mooring.label = mooring.agency_reference;
                    } else if (
                        this.currentAgency.pdf_external_ref ===
                        'agency_reference' &&
                        mooring.external_reference
                    ) {
                        mooring.label = mooring.external_reference;
                    } else if (
                        this.currentAgency.pdf_external_ref === 'reference'
                    ) {
                        mooring.label = mooring.reference;
                    } else {
                        mooring.label = mooring.reference;
                    }
                    if (mooring.hasOwnProperty('type_one')) {
                        mooring.label =
                            mooring.label + '-' + mooring.type_one;
                    }
                    if (mooring.hasOwnProperty('property_city')) {
                        mooring.label =
                            mooring.label + '-' + mooring.property_city.value[this.currentLang];
                    }
                    return mooring;
                })
                this.mooringsData = moorings;
            });

        merge(
            this.searchBoats$,
            this.form.get('boats').valueChanges
        )
            .pipe(
                startWith(''),
                distinctUntilChanged(),
                takeUntil(this._unsubscribeAll),
                switchMap((search: string) => {
                    const filter = {
                        query: {
                            archived: { $ne: true },
                        } as any,
                        options: {} as any,
                    } as any;

                    const queryOr = [];
                    if (search) {
                        queryOr.push({
                            reference: Number(search),
                        });
                        queryOr.push({
                            type_one: {
                                $regex:
                                    '.*' + search + '.*',
                                $options: 'i',
                            },
                        });
                        filter.query.$or = queryOr;
                    }
                    // queryOr.push({
                    //     property_city: {
                    //         $regex:
                    //             '.*' + search + '.*',
                    //         $options: 'i',
                    //     },
                    // });

                    return this._dropdownsService.getMoorings('boats', filter);
                }),
            )
            .subscribe((data: any) => {
                const boats = _.map(data.body, (boat) => {
                    if (
                        this.currentAgency.pdf_external_ref ===
                        'external_reference' &&
                        boat.agency_reference
                    ) {
                        boat.label = boat.agency_reference;
                    } else if (
                        this.currentAgency.pdf_external_ref ===
                        'agency_reference' &&
                        boat.external_reference
                    ) {
                        boat.label = boat.external_reference;
                    } else if (
                        this.currentAgency.pdf_external_ref === 'reference'
                    ) {
                        boat.label = boat.reference;
                    } else {
                        boat.label = boat.reference;
                    }
                    if (boat.hasOwnProperty('boat_type')) {
                        boat.label =
                            boat.label + '-' + boat.boat_type.value[this.currentLang];
                    }
                    if (boat.hasOwnProperty('property_city')) {
                        boat.label =
                            boat.label + '-' + boat.property_city.value[this.currentLang];
                    }
                    return boat;
                })
                this.boatsData = boats;
            });
        merge(
            this.companiesSearch.valueChanges,
            this.form.get('linked_company').valueChanges
        )
            .pipe(
                startWith(''),
                tap(() => (this.searching = true)),
                switchMap(() => {
                    const search = this.companiesSearch.value
                        ? this.companiesSearch.value
                        : '';
                    return this._dropdownsService.getCompanies(
                        search,
                        this.form.get('linked_company').value
                    );
                })
            )
            .subscribe((data: any) => {
                this.searching = false;
                this.companies = data.body;
            });

        merge(this.searchCompany.valueChanges)
            .pipe(
                startWith(''),
                tap(),
                switchMap(() => {
                    return this._dropdownsService.getAccountsCompanies(
                        this.searchCompany.value
                    );
                })
            )
            .subscribe((data: any) => {
                this.Accountscompanies = data.body;
                this.Accountscompanies = _.orderBy(
                    this.Accountscompanies,
                    ['commercial_name'],
                    ['asc']
                );
            });
        this._dropdownsService.getUsersByAgencies().subscribe((data: any) => {
            this.UsersByAgencies = data.body;
        });

        this.sourceSearch.valueChanges
            .pipe(
                startWith(''),
                tap(() => (this.searching = true)),
                switchMap(() => {
                    let search: any;
                    if (this.sourceSearch.value) {
                        search = this.sourceSearch.value;
                    }
                    return this._dropdownsService.getSources(
                        'Accounts',
                        search
                    );
                })
            )
            .subscribe((data: any) => {
                this.searching = false;
                this.sources = data.body;
            });

        this.seriesSearch.valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                startWith(''),
                debounceTime(400),
                distinctUntilChanged(),
                tap(() => (this.searching = true)),
                switchMap((search) => {
                    if (search && search !== '') {
                        // Required in this way to perform search on backend.
                        const description = 'description.' + this.systemLang;
                        this.queryOptions = { [description]: { $regex: '.*' + this._globalFunctions.caseAndAccentInsensitive(search) + '.*', $options: 'i' } };
                    }
                    return this._gService.post(`invoice-series`, { query: this.queryOptions }, 'nodejs');
                })
            )
            .subscribe((data: any) => {
                this.searching = false;
                this.invoiceSeries = data.docs;
                this.loaders['invoiceSeries'] = false;
            });

        this._dropdownsService.getLeadStatuses().subscribe((data: any) => {
            this.leadstatus = data;
        });

        this.relatedCompanies = this.form.get('related_companies') as FormArray;
        // this.relatedCompanies.push(this.addRelatedCompanies());

        this.customSettings = this.form.get('custom_settings') as FormArray;
        // this.customSettings.push(this.addCustomSettings());
        this._initPage();
    }

    isAgencyInclude(item) {
        if (item.agency == this.currentAgency._id) {
            return true
        } else {
            return false
        }
    }

    addRelatedCompanies(): FormGroup {
        return this._formBuilder.group({
            company: { value: '', disabled: this.viewRecord },
            company_type: { value: '', disabled: this.viewRecord },
            company_comment: { value: '', disabled: this.viewRecord },
            company_name: { value: '', disabled: this.viewRecord },
        });
    }

    setCompanyData(data: any, i: any): void {
        if (data.type) {
            this.form
                .get('related_companies.' + i + '.company_type')
                .setValue(data.type);
        }
        if (data.commercial_name) {
            this.form
                .get('related_companies.' + i + '.company_name')
                .setValue(data.commercial_name);
        }
    }

    addCustomSettings(): FormGroup {
        return this._formBuilder.group({
            key: { value: '', disabled: this.viewRecord },
            value: { value: '', disabled: this.viewRecord },
        });
    }

    selectProbability(e: any): void {
        if (e.value === '1') {
            this.form.get('status').setValue('lead');
        }
        if (e.value === '2' || e.value === '3' || e.value === '4') {
            this.form.get('status').setValue('prospect');
        }
        if (e.value === '5') {
            this.form.get('status').setValue('client');
        }
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
    async _initPage(): Promise<any> {
        this._findPaymentMethods();
    }
    private _findPaymentMethods(): void {
        this.loaders['paymentMethod'] = true;
        const queryOptions = {
            where: {},
            select: 'description',
            options: { sort: { description: 1 } },
        };
        this._gService
            .post(`payment-modes`, { queryOptions: queryOptions }, 'nodejs')
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((data: any) => {
                this.paymentMethods = data.docs;
                this.loaders['paymentMethod'] = false;
            });
    }
}
