import { Directive, Input, HostListener } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { filter, forEach, union } from 'lodash';

@Directive({
  selector: '[appOfficeGroup]'
})
export class OfficeGroupDirective {

  @Input() form: FormGroup;
  @Input() officeGroup: any;
  @Input() offices: any;

  constructor() { }

  @HostListener('click') onClick(): void {
    const officeData = filter(this.offices, ['office_groups', this.officeGroup]);
    const officeID = [];
    const offices = filter(this.offices, ['office_groups', [this.officeGroup]]);
    if(offices){
      forEach(offices, (office) => {
        officeData.push(office);
      });
    }
    forEach(officeData, (value) => {
      officeID.push(value._id);
    });
    this.form.get('offices').patchValue(union(officeID, this.form.get('offices').value));
  }

}
