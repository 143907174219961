import { Component, HostBinding, Inject, OnDestroy, OnInit, Renderer2, ViewEncapsulation, Input, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DOCUMENT } from '@angular/common';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { fuseAnimations } from '@fuse/animations';
import { FuseConfigService } from '@fuse/services/config.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { OfficesService, AuthenticationService } from 'app/services';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'fuse-theme-options',
    templateUrl: './theme-options.component.html',
    styleUrls: ['./theme-options.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class FuseThemeOptionsComponent implements OnInit, OnDestroy, AfterViewChecked {

    @Input() viewRecord = false;
    @Input() office: FormGroup;
    fuseConfig: any;
    form: FormGroup;

    @HostBinding('class.bar-closed')
    barClosed: boolean;

    // Private
    private _unsubscribeAll: Subject<any>;
    currentOffice: any;

    /**
     * Constructor
     *
     * @param {DOCUMENT} document
     * @param {FormBuilder} _formBuilder
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseNavigationService} _fuseNavigationService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {Renderer2} _renderer
     */
    constructor(
        @Inject(DOCUMENT) private document: any,
        private _formBuilder: FormBuilder,
        private cdRef: ChangeDetectorRef,
        public _fuseConfigService: FuseConfigService,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseSidebarService: FuseSidebarService,
        private _officesService: OfficesService,
        private _authenticationService: AuthenticationService,
        private router: Router,
        private _renderer: Renderer2,
        private _snackBar: MatSnackBar,
        private _translateService: TranslateService,
    ) {
        this.currentOffice = this._authenticationService.currentOfficeValue;
        // Set the defaults
        this.barClosed = true;

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {

        this._authenticationService.currentOffice
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((data: any) => {
                this.currentOffice = data;
            });
        // noinspection TypeScriptValidateTypes
        this.form = this._formBuilder.group({
            colorTheme: [{ value: '', disabled: this.viewRecord }],
            customScrollbars: [{ value: '', disabled: this.viewRecord }],
            layout: this._formBuilder.group({
                style: [{ value: '', disabled: this.viewRecord }],
                width: [{ value: '', disabled: this.viewRecord }],
                navbar: this._formBuilder.group({
                    primaryBackground: [{ value: '', disabled: this.viewRecord }],
                    secondaryBackground: [{ value: '', disabled: this.viewRecord }],
                    folded: [{ value: '', disabled: this.viewRecord }],
                    hidden: [{ value: '', disabled: this.viewRecord }],
                    position: [{ value: '', disabled: this.viewRecord }],
                    variant: [{ value: '', disabled: this.viewRecord }]
                }),
                toolbar: this._formBuilder.group({
                    background: [{ value: '', disabled: this.viewRecord }],
                    customBackgroundColor: [{ value: '', disabled: this.viewRecord }],
                    hidden: [{ value: '', disabled: this.viewRecord }],
                    position: [{ value: '', disabled: this.viewRecord }]
                }),
                footer: this._formBuilder.group({
                    background: [{ value: '', disabled: this.viewRecord }],
                    customBackgroundColor: [{ value: '', disabled: this.viewRecord }],
                    hidden: [{ value: '', disabled: this.viewRecord }],
                    position: [{ value: '', disabled: this.viewRecord }]
                }),
                sidepanel: this._formBuilder.group({
                    hidden: [{ value: '', disabled: this.viewRecord }],
                    position: [{ value: '', disabled: this.viewRecord }]
                })
            })
        });
        
        this._fuseConfigService.getConfig().subscribe((config: any) => {
            this.office.value.theme_options = config;
        });

        const office = this.office.getRawValue();
        if (office.theme_options.colorTheme) {
            this._fuseConfigService.config = office.theme_options;
            this.fuseConfig = office.theme_options;
            this.form.setValue(office.theme_options, { emitEvent: false });
        }
        // Subscribe to the config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {

                // Update the stored config
                this.fuseConfig = config;

                // Set the config form values without emitting an event
                // so that we don't end up with an infinite loop
                this.form.setValue(config, { emitEvent: false });
            });

        // Subscribe to the specific form value changes (layout.style)
        this.form.get('layout.style').valueChanges
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((value) => {

                // Reset the form values based on the
                // selected layout style
                this.saveOptions();
            });

        // Subscribe to the form value changes
        this.form.valueChanges
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {

                // Update the config
                this._fuseConfigService.config = config;
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {

        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();

        // Remove the custom function menu
        this._fuseNavigationService.removeNavigationItem('custom-function');
    }

    ngAfterViewChecked(): void {
        this.cdRef.detectChanges();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Reset the form values based on the
     * selected layout style
     *
     * @param value
     * @private
     */
    private _resetFormValues(value): void {
        switch (value) {
            // Vertical Layout #1
            case 'vertical-layout-1':
                {
                    this.form.patchValue({
                        layout: {
                            width: 'fullwidth',
                            navbar: {
                                primaryBackground: 'fuse-navy-700',
                                secondaryBackground: 'fuse-navy-900',
                                folded: false,
                                hidden: false,
                                position: 'left',
                                variant: 'vertical-style-1'
                            },
                            toolbar: {
                                background: 'fuse-white-500',
                                customBackgroundColor: false,
                                hidden: false,
                                position: 'below-static'
                            },
                            footer: {
                                background: 'fuse-navy-900',
                                customBackgroundColor: true,
                                hidden: false,
                                position: 'below-static'
                            },
                            sidepanel: {
                                hidden: true,
                                position: 'right'
                            }
                        }
                    });

                    break;
                }

            // Vertical Layout #2
            case 'vertical-layout-2':
                {
                    this.form.patchValue({
                        layout: {
                            width: 'fullwidth',
                            navbar: {
                                primaryBackground: 'fuse-navy-700',
                                secondaryBackground: 'fuse-navy-900',
                                folded: false,
                                hidden: false,
                                position: 'left',
                                variant: 'vertical-style-1'
                            },
                            toolbar: {
                                background: 'fuse-white-500',
                                customBackgroundColor: false,
                                hidden: false,
                                position: 'below'
                            },
                            footer: {
                                background: 'fuse-navy-900',
                                customBackgroundColor: true,
                                hidden: false,
                                position: 'below'
                            },
                            sidepanel: {
                                hidden: true,
                                position: 'right'
                            }
                        }
                    });

                    break;
                }

            // Vertical Layout #3
            case 'vertical-layout-3':
                {
                    this.form.patchValue({
                        layout: {
                            width: 'fullwidth',
                            navbar: {
                                primaryBackground: 'fuse-navy-700',
                                secondaryBackground: 'fuse-navy-900',
                                folded: false,
                                hidden: false,
                                position: 'left',
                                layout: 'vertical-style-1'
                            },
                            toolbar: {
                                background: 'fuse-white-500',
                                customBackgroundColor: false,
                                hidden: false,
                                position: 'above-static'
                            },
                            footer: {
                                background: 'fuse-navy-900',
                                customBackgroundColor: true,
                                hidden: false,
                                position: 'above-static'
                            },
                            sidepanel: {
                                hidden: true,
                                position: 'right'
                            }
                        }
                    });

                    break;
                }

            // Horizontal Layout #1
            case 'horizontal-layout-1':
                {
                    this.form.patchValue({
                        layout: {
                            width: 'fullwidth',
                            navbar: {
                                primaryBackground: 'fuse-navy-700',
                                secondaryBackground: 'fuse-navy-900',
                                folded: false,
                                hidden: false,
                                position: 'top',
                                variant: 'vertical-style-1'
                            },
                            toolbar: {
                                background: 'fuse-white-500',
                                customBackgroundColor: false,
                                hidden: false,
                                position: 'above'
                            },
                            footer: {
                                background: 'fuse-navy-900',
                                customBackgroundColor: true,
                                hidden: false,
                                position: 'above-fixed'
                            },
                            sidepanel: {
                                hidden: true,
                                position: 'right'
                            }
                        }
                    });

                    break;
                }
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    /**
     * Saving Theme Options to offices 
     */
    saveOptions(): void {
        const office = this.office.getRawValue();
        office.theme_options = this.form.getRawValue();
        this._officesService.update(office).subscribe((data: any) => {
            this._snackBar.open(this._translateService.instant('Updated successfully'), this._translateService.instant('Close'), {
                duration: 2000,
            });
            this.office.setValue(office);
            if (office._id === this.currentOffice._id) {
                this._authenticationService.setCurrentOffice(office);
            }
        });
    }
}
