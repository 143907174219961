import {
    Component,
    OnInit,
    Input,
    AfterViewChecked,
    ChangeDetectorRef,
    OnDestroy,
    Output,
    EventEmitter,
} from '@angular/core';
import { FormGroup, FormControl, FormArray, FormBuilder } from '@angular/forms';
import {
    AuthenticationService,
    SalesService,
    DropdownsService,
    UserService,
} from 'app/services';
import { ActivatedRoute, Router } from '@angular/router';
import { merge, Subject } from 'rxjs';
import { startWith, tap, switchMap, takeUntil } from 'rxjs/operators';
import * as _ from 'lodash';
import { GlobalFuntions } from 'app/_helpers';
import { CommercialPropertiesService } from 'app/services/commercial-properties.service';
import { MooringsService } from 'app/services/moorings.service';
import { TranslateService } from '@ngx-translate/core';
import { PropertiesService } from 'app/services/properties.service';
import { round, forEach, isArray, isEmpty, includes, map } from 'lodash';
import { SalesCreateComponent } from '../../sales-create.component';
import { OwnersService } from 'app/services/owners.service';
import { CrmMenuService } from 'app/services/crm-menu.service';
import { BaseService } from 'app/_helpers/base/base.service';

@Component({
    selector: 'app-basic-info',
    templateUrl: './basic-info.component.html',
    styleUrls: ['./basic-info.component.scss'],
})
export class BasicInfoComponent implements OnInit, AfterViewChecked, OnDestroy {
    @Input() sale: FormGroup;
    @Output() formUpdate: EventEmitter<any>;
    private _unsubscribeAll: Subject<any> = new Subject();
    searching: boolean;
    propertySearch: FormControl = new FormControl('');
    properties: any;
    cpropertySearch: FormControl = new FormControl('');
    mooringSearch: FormControl = new FormControl('');
    commercialProperties: any;
    moorings: any;
    offerSearch: FormControl = new FormControl('');
    offers: any;
    companySearch$ = new Subject<string>();
    companies: any;
    currentUser: any;
    currentAgency: any;
    officesData: any;
    currentOffice: any;
    ownerSearch: FormControl = new FormControl('');
    owners: any;
    accountSearch: FormControl = new FormControl('');
    accounts: any;
    masterdataTypes: any;
    formArraycompanies: FormArray;
    currentLang: any;
    searchCompaniesByType: FormControl = new FormControl('');
    companyIndex: number;
    companiesByType: any;
    contactSearch: FormControl = new FormControl('');
    contacts: any;
    userAgencies: any;
    agentPayable$ = new Subject<string>();
    payableData: any;
    sellingAgentCommissions: FormArray;
    id: string;
    selectedProperty: any;
    viewRecord: boolean;
    relatedTo = true;
    relatedToMooringLabel: any;
    readOnly: boolean = false;
    currencies: any = [];
    // companiesSearch$ = new Subject<string>();
    // id: any;
    // _id: any;
    // user: any;
    // searching: boolean;
    // propertySearch: FormControl = new FormControl('');
    // properties: any;
    // cpropertySearch: FormControl = new FormControl('');
    // commercialProperties: any;
    // offerSearch: FormControl = new FormControl('');
    // offers: any;
    // companySearch: FormControl = new FormControl('');
    // companySearch$ = new Subject<string>();
    // company: any;
    // userSearch: FormControl = new FormControl('');
    // users: any;

    // office: any;

    // compType: any;

    // index: any;
    // initial: any;
    // currentAgency: any;
    // defaultRef: any;
    // viewRecord: boolean;
    // filter = {
    //     query: {
    //         archived: { $ne: true }
    //     } as any,
    //     options: {} as any
    // } as any;
    // userAgencies: any;
    // companiesData: any;
    // payableData: any;
    // property: any;
    constructor(
        private _salesService: SalesService,
        private _authenticationService: AuthenticationService,
        private route: ActivatedRoute,
        private _dropdownsService: DropdownsService,
        private cdRef: ChangeDetectorRef,
        private formBuilder: FormBuilder,
        private router: Router,
        public _globalFuntions: GlobalFuntions,
        private _commercialService: CommercialPropertiesService,
        public _translateService: TranslateService,
        public _saleCreateComponent: SalesCreateComponent,
        private _userService: UserService,
        public _crmMenuService: CrmMenuService,
        private _gService: BaseService
    ) {
        this.formUpdate = new EventEmitter();
        this.id = this.route.snapshot.paramMap.get('id');
        this.currentUser = this._authenticationService.currentUserValue;
        this.currentAgency = _authenticationService.currentAgencyValue;
        this.currentOffice = _authenticationService.currentOfficeValue;
        this.userAgencies = [this.currentAgency];
        this._translateService.onLangChange
            .pipe(startWith(''), takeUntil(this._unsubscribeAll))
            .subscribe((data: any) => {
                this.currentLang =
                    this._translateService.currentLang === 'es'
                        ? 'es_AR'
                        : this._translateService.currentLang;
            });
        if (includes(this.router.url, 'view/')) {
            this.viewRecord = true;
        }
    }

    ngOnInit(): void {
        this.formArraycompanies = this.sale.get('companies') as FormArray;
        this.sellingAgentCommissions = this.sale.get(
            'selling_agent_commissions'
        ) as FormArray;

        if (history && history.state) {
            if (history.state.type === 'residential' && history.state.data) {
                const property = history.state.data;
                this.sale.get('property_reference').setValue(property.reference);
                this.autoListPrice(property, true);
            }else if(history.state.type === 'commercial' && history.state.data) {
                const property = history.state.data;
                this.sale.get('property_reference').setValue(property.reference);
                this.sale.get('related_to').setValue('non_residental_properties');
                this.autoListPriceCommecrial(property, true)
            }else if(history.state.type === 'Moorings' || history.state.type === 'Boats'){
                this.relatedToMooringLabel = 'Available '+ history.state.type.toLowerCase();
                this.sale.get('related_to').setValue(history.state.type);
            }
            if (history.state.offer) {
                if (
                    history.state.offer.all_offers &&
                    isArray(history.state.offer.all_offers) &&
                    history.state.offer.all_offers.length
                ) {
                    const acceptedOffer = history.state.offer.all_offers[0];
                    this.sale.get('sales_price').setValue(acceptedOffer.amount * 1);
                    this.sale.get('offer_id').setValue(acceptedOffer?.property_offer_id);
                    this.readOnly = true;
                }
                this.onSelectOffer(history.state.offer);
            }
        }

        this._dropdownsService.getCurrencies().subscribe((data: any) => {
            this.currencies = data;
        });

        if(this.sale.get('related_to').value == 'residental_properties') {
            this.sale.get('related_to').setValue('non_residental_properties');
        }
        merge(
            this.propertySearch.valueChanges,
            this.sale.get('property_reference').valueChanges
        )
            .pipe(
                startWith(''),
                tap(() => (this.searching = true)),
                switchMap(() => {
                    let search = '';
                    let status = 'Available';
                    if (this.propertySearch.value) {
                        search = this.propertySearch.value;
                    } else if (
                        this.sale.get('property_reference').value !== ''
                    ) {
                        search = this.sale.get('property_reference').value;
                        status = '';
                    }
                    return this._dropdownsService.getSalesProperties(
                        search,
                        status
                    );
                })
            )
            .subscribe((data: any) => {
                this.searching = false;
                this.properties = data.body;
            });

        merge(this.cpropertySearch.valueChanges,
            this.sale.get('property_reference').valueChanges
        )
        .pipe(
            startWith(''),
            tap(() => (this.searching = true)),
            switchMap(() => {
                const filter = {} as any;
                let search = '';
                if (this.cpropertySearch.value) {
                    search = this.cpropertySearch.value;
                } else if (
                    this.sale.get('property_reference').value !== ''
                ) {
                    search = this.sale.get('property_reference').value;
                }
                if (search) {
                    const queryOr = [];
                    queryOr.push({
                        reference: Number(search),
                    });
                    queryOr.push({
                        external_reference: {
                            $regex:
                                '.*' + search + '.*',
                            $options: 'i',
                        },
                    });
                    queryOr.push({
                        other_reference: {
                            $regex:
                                '.*' + search + '.*',
                            $options: 'i',
                        },
                    });
                    filter.$or = queryOr;
                    filter.allow_extra = true;
                }
                return this._dropdownsService.getCommercialProperties(filter);
            })
        )
        .subscribe((data: any) => {
            this.searching = false;
            this.commercialProperties = data.body;
        });

        merge(this.mooringSearch.valueChanges)
            .pipe(
                startWith(''),
                tap(() => (this.searching = true)),
                switchMap(() => {
                    const filter = {
                        query: {
                            archived: { $ne: true },
                        } as any,
                        options: {} as any,
                    } as any;
                    if (this.mooringSearch.value) {                        
                        const queryOr = [];
                        queryOr.push({
                            reference: Number(this.mooringSearch.value),
                        });
                        queryOr.push({
                            type_one: {
                                $regex:
                                    '.*' + this.mooringSearch.value + '.*',
                                $options: 'i',
                            },
                        });
                        // queryOr.push({
                        //     property_city: {
                        //         $regex:
                        //             '.*' + this.mooringSearch.value + '.*',
                        //         $options: 'i',
                        //     },
                        // });
                        filter.query.$or = queryOr;
                    }                  
                    var modelType = 'mooring_properties';
                    if(this.sale.get('related_to').value == 'Boats'){
                        modelType = 'boats';
                    }                    
                   return this._dropdownsService.getMoorings(modelType, filter);                     
                })
            )
            .subscribe((data: any) => {
                this.searching = false;
                this.moorings = data.body;                
            });

        merge(
            this.offerSearch.valueChanges,
            this.sale.get('offer_id').valueChanges,
            this.sale.get('related_to').valueChanges
        )
            .pipe(
                startWith(''),
                tap(() => (this.searching = true)),
                switchMap(() => {
                    let search = '';
                    if (this.offerSearch.value) {
                        search = this.offerSearch.value;
                    } else if (this.sale.get('offer_id').value) {
                        search = this.sale.get('offer_id').value;
                    }
                    return this._dropdownsService.getPropertyOffers(
                        search,
                        this.sale.get('related_to').value
                    );
                })
            )
            .subscribe((data: any) => {
                this.searching = false;
                this.offers = data.body;
            });

        this.companySearch$
            .pipe(
                startWith(''),
                tap(() => (this.searching = true)),
                switchMap((search: string) => {
                    return this._dropdownsService.getOwnCompanies(search ? search : '');
                })
            )
            .subscribe((data: any) => {
                if(data) {
                    this.searching = false;
                    this.companies = data;
                    this.companies.push(this.currentAgency);
                }
                // https://gitlab.optimasit.com/arsl/optima-crm-v2/-/issues/2957#note_53934
                // if (!this.sale.get('listing_agency_id').value) {
                //     forEach(data, (company) => {
                //         if (
                //             company.company_of_agency == this.currentAgency._id
                //         ) {
                //             this.sale.get('listing_agency_id').setValue(company._id);
                //             this.sale.get('company_agency_id').setValue(this.currentAgency._id);
                //             this.sellingCompanySelect(company);
                //         }
                //     });
                // }
            });

        this._dropdownsService
            .getOffices()
            .pipe(startWith(''), takeUntil(this._unsubscribeAll))
            .subscribe((data: any) => {
                this.officesData = data.body;
            });

        merge(
            this.ownerSearch.valueChanges,
            this.sale.get('owner').valueChanges
        )
            .pipe(
                startWith(''),
                tap(() => (this.searching = true)),
                takeUntil(this._unsubscribeAll),
                switchMap(() => {
                    let search = '';
                    if (this.ownerSearch.value) {
                        search = this.ownerSearch.value;
                    }
                    return this._dropdownsService.getOwners(
                        search,
                        this.sale.get('owner').value
                    );
                })
            )
            .subscribe((data: any) => {
                this.searching = false;
                this.owners = data.body;
            });

        merge(
            this.accountSearch.valueChanges,
            this.sale.get('account').valueChanges
        )
            .pipe(
                startWith(''),
                tap(() => (this.searching = true)),
                takeUntil(this._unsubscribeAll),
                switchMap(() => {
                    let search = '';
                    if (this.accountSearch.value) {
                        search = this.accountSearch.value;
                    }
                    return this._dropdownsService.getOffersAccount(
                        search,
                        this.sale.get('account').value
                    );
                })
            )
            .subscribe((data: any) => {
                this.searching = false;
                const accounts = map(data.body, (element) => {
                    element.label = element?.full_name ? element.full_name.trim() : '';
                    if (!element.label) {
                        element.label = element?.mobile_phone && element.mobile_phone.length > 0 ? element.mobile_phone[0] : '';
                    }
                    return element;
                });
                this.accounts = accounts;
            });

        this._translateService.onLangChange
            .pipe(
                startWith(''),
                takeUntil(this._unsubscribeAll),
                switchMap(() => {
                    return this._dropdownsService.getMasterdataTypes();
                })
            )
            .subscribe((data: any) => {
                this.masterdataTypes = data.body;
            });

        merge(this.searchCompaniesByType.valueChanges)
            .pipe(
                startWith(''),
                tap(() => (this.searching = true)),
                takeUntil(this._unsubscribeAll),
                switchMap(() => {
                    let search = '';
                    let type = '';
                    if (this.companyIndex > -1) {
                        type = this.sale.get(
                            'companies.' + this.companyIndex + '.type'
                        ).value;
                    }
                    if (this.searchCompaniesByType.value) {
                        search = this.searchCompaniesByType.value;
                    }
                    return this._dropdownsService.getCompanyTypes(search, type);
                })
            )
            .subscribe((data: any) => {
                this.searching = false;
                this.companiesByType = data.body;
            });

        merge(this.contactSearch.valueChanges)
            .pipe(
                startWith(''),
                tap(() => (this.searching = true)),
                takeUntil(this._unsubscribeAll),
                switchMap(() => {
                    let search = '';
                    let id = '';
                    if (this.companyIndex > -1) {
                        id = this.sale.get(
                            'companies.' + this.companyIndex + '.company'
                        ).value;
                    }
                    if (this.contactSearch.value) {
                        search = this.contactSearch.value;
                    }
                    return this._dropdownsService.getContacts(search, id);
                })
            )
            .subscribe((data: any) => {
                this.searching = false;
                this.contacts = data.body;
            });

        this.agentPayable$
            .pipe(
                startWith(''),
                tap(() => (this.searching = true)),
                takeUntil(this._unsubscribeAll),
                switchMap((search: string) => {
                    return this._dropdownsService.getUsers(
                        search,
                        false,
                        false
                    );
                })
            )
            .subscribe((data: any) => {
                this.searching = false;
                this.payableData = data.body;
            });

        merge(
            this.sale.get('commission_in_euro').valueChanges,
            this.sale.get('listing_agent_payable').valueChanges
        ).subscribe(() => {
            const commissionAmount =
                (this.sale.get('commission_in_euro').value || 0) * 1;
            const promise = this.getCommission();
            promise.then((commission: any) => {
                this.sale
                    .get('commission_out_listing_percent')
                    .setValue(commission.listing);
                this.sale
                    .get('commission_out_selling_percent')
                    .setValue(commission.sales);
                this.sale
                    .get('commission_out_listing_agent_net')
                    .setValue(
                        round(
                            (commissionAmount * 1 * commission.listing) / 100,
                            2
                        )
                    );
                this.sale
                    .get('commission_out_selling_agent_net')
                    .setValue(
                        round(
                            (commissionAmount * 1 * commission.sales) / 100,
                            2
                        )
                    );
            });
        });

        merge(
            this.sale.get('vat').valueChanges,
            this.sale.get('tottal_commission_amount').valueChanges,
            this.sale.get('commission_in_euro').valueChanges,
            this.sale.get('commission_out_one_euro').valueChanges,
            this.sale.get('commission_out_two_euro').valueChanges,
            this.sale.get('selling_agent_commissions').valueChanges,
            this.sale.get('commission_out_listing_agent_net').valueChanges,
            this.sale.get('commission_net').valueChanges,
            this.sale.get('commission_out_three_euro').valueChanges
        ).subscribe(() => {
            const vat = this.sale.get('vat').value
                ? this.sale.get('vat').value
                : 0;
            const tottalCommissionAmount = this.sale.get(
                'tottal_commission_amount'
            ).value
                ? this.sale.get('tottal_commission_amount').value * 1
                : 0;
            const commissionInEuro = this.sale.get('commission_in_euro').value
                ? this.sale.get('commission_in_euro').value * 1
                : 0;
            const commissionOutOneEuro = this.sale.get(
                'commission_out_one_euro'
            ).value
                ? this.sale.get('commission_out_one_euro').value * 1
                : 0;
            const commissionOutYwoEuro = this.sale.get(
                'commission_out_two_euro'
            ).value
                ? this.sale.get('commission_out_two_euro').value * 1
                : 0;
            const sellingAgentCommissions = this.sale.get(
                'selling_agent_commissions'
            ).value;
            const commissionOutListingAgentNet = this.sale.get(
                'commission_out_listing_agent_net'
            ).value
                ? this.sale.get('commission_out_listing_agent_net').value * 1
                : 0;
            const commissionNet = this.sale.get('commission_net').value
                ? this.sale.get('commission_net').value * 1
                : 0;
            const commissionOutThreeEuro = this.sale.get(
                'commission_out_three_euro'
            ).value
                ? this.sale.get('commission_out_three_euro').value * 1
                : 0;

            const vattottalCommissionAmount =
                tottalCommissionAmount * (vat / 100);
            const totalvattottalCommissionAmount =
                tottalCommissionAmount + vattottalCommissionAmount;
            this.sale
                .get('tottal_vat_on_commission')
                .setValue(round(vattottalCommissionAmount, 2), {
                    emitEvent: false,
                });
            this.sale
                .get('tottal_commission_payout')
                .setValue(round(totalvattottalCommissionAmount, 2), {
                    emitEvent: false,
                });

            const vatcommissionInEuro = commissionInEuro * (vat / 100);
            const totalvatcommissionInEuro =
                commissionInEuro + vatcommissionInEuro;
            this.sale
                .get('commission_in_iva')
                .setValue(round(vatcommissionInEuro, 2), { emitEvent: false });
            this.sale
                .get('commission_payout')
                .setValue(round(totalvatcommissionInEuro, 2), {
                    emitEvent: false,
                });

            const vatcommissionOutOneEuro = commissionOutOneEuro * (vat / 100);
            const totalvatcommissionOutOneEuro =
                commissionOutOneEuro + vatcommissionOutOneEuro;
            this.sale
                .get('commission_out_one_iva')
                .setValue(round(vatcommissionOutOneEuro, 2), {
                    emitEvent: false,
                });
            this.sale
                .get('commission_payout_one')
                .setValue(round(totalvatcommissionOutOneEuro, 2), {
                    emitEvent: false,
                });

            const vatcommissionOutYwoEuro = commissionOutYwoEuro * (vat / 100);
            const totalvatcommissionOutYwoEuro =
                commissionOutYwoEuro + vatcommissionOutYwoEuro;
            this.sale
                .get('commission_out_two_iva')
                .setValue(round(vatcommissionOutYwoEuro, 2), {
                    emitEvent: false,
                });
            this.sale
                .get('commission_payout_two')
                .setValue(round(totalvatcommissionOutYwoEuro, 2), {
                    emitEvent: false,
                });

            forEach(sellingAgentCommissions, (sellingAgentCommission, key) => {
                const netCommission = sellingAgentCommission.net_commission
                    ? sellingAgentCommission.net_commission
                    : 0;
                const vatCommission = netCommission * (vat / 100);
                const totlaVatCommission = netCommission + vatCommission;
                this.sale
                    .get('selling_agent_commissions.' + key + '.vat_commission')
                    .setValue(round(vatCommission, 2), { emitEvent: false });
                this.sale
                    .get(
                        'selling_agent_commissions.' + key + '.total_commission'
                    )
                    .setValue(round(totlaVatCommission, 2), {
                        emitEvent: false,
                    });
            });

            const vatcommissionOutListingAgentNet =
                commissionOutListingAgentNet * (vat / 100);
            const totvatcommissionOutListingAgentNet =
                commissionOutListingAgentNet + vatcommissionOutListingAgentNet;
            this.sale
                .get('commission_out_listing_agent_vat')
                .setValue(round(vatcommissionOutListingAgentNet, 2), {
                    emitEvent: false,
                });
            this.sale
                .get('commission_out_listing_agent_total')
                .setValue(round(totvatcommissionOutListingAgentNet, 2), {
                    emitEvent: false,
                });

            const vatcommissionNet = commissionNet * (vat / 100);
            const totalvatcommissionNet = commissionNet + vatcommissionNet;
            this.sale
                .get('commission_net_iva')
                .setValue(round(vatcommissionNet, 2), { emitEvent: false });
            this.sale
                .get('commission_total_vat')
                .setValue(round(totalvatcommissionNet, 2), {
                    emitEvent: false,
                });

            const vatcommissionOutThreeEuro =
                commissionOutThreeEuro * (vat / 100);
            const totalvatcommissionOutThreeEuro =
                commissionOutThreeEuro + vatcommissionOutThreeEuro;
            this.sale
                .get('commission_out_three_iva')
                .setValue(round(vatcommissionOutThreeEuro, 2), {
                    emitEvent: false,
                });
            this.sale
                .get('commission_payout_three')
                .setValue(round(totalvatcommissionOutThreeEuro, 2), {
                    emitEvent: false,
                });
        });

        merge(
            this.sale.get('own_property').valueChanges,
            this.sale.get('company_agency_id').valueChanges,
            this.sale.get('tottal_commission_amount').valueChanges,
            this.sale.get('commission_in_euro').valueChanges,
            this.sale.get('commission_out_one_euro').valueChanges,
            this.sale.get('commission_out_two_euro').valueChanges,
            this.sale.get('selling_agent_commissions').valueChanges,
            this.sale.get('commission_out_listing_agent_net').valueChanges
        ).subscribe(() => {
            this.calculateTotals();
        });

        if (this.id) {
            this._salesService.getById(this.id).subscribe((data: any) => {
                const sale = data.body;
                this.sale.patchValue(sale);
                if(this.sale.get('related_to').value == 'residental_properties') {
                    this.sale.get('related_to').setValue('non_residental_properties');
                }
                this.clearFormArray(this.formArraycompanies);
                if (sale.companies && sale.companies.length) {
                    _.forEach(sale.companies, (company, key) => {
                        this.formArraycompanies.push(this.addCompanies());
                        this.sale.get('companies.' + key).patchValue(company);
                    });
                } else {
                    this.formArraycompanies.push(this.addCompanies());
                }
                this.clearFormArray(this.sellingAgentCommissions);
                if (
                    sale.selling_agent_commissions &&
                    sale.selling_agent_commissions.length
                ) {
                    _.forEach(
                        sale.selling_agent_commissions,
                        (sellingAgentCommission, key) => {
                            this.sellingAgentCommissions.push(
                                this._saleCreateComponent.addAgentCommision()
                            );
                            this.sale
                                .get('selling_agent_commissions.' + key)
                                .patchValue(sellingAgentCommission);
                        }
                    );
                } else {
                    this.sellingAgentCommissions.push(
                        this._saleCreateComponent.addAgentCommision()
                    );
                }
                this.formUpdate.next(data.body);
            });
        }

        this.sale.get('sales_price').valueChanges.subscribe(() => {
            if (this.sale.get('tottal_commission_value').value) {
                this.sale
                    .get('tottal_commission_value')
                    .setValue(this.sale.get('tottal_commission_value').value);
            }
            if (this.sale.get('commission').value) {
                this.sale
                    .get('commission')
                    .setValue(this.sale.get('commission').value);
            }
            if (this.sale.get('commission_value_one').value) {
                this.sale
                    .get('commission_value_one')
                    .setValue(this.sale.get('commission_value_one').value);
            }
            if (this.sale.get('commission_value_two').value) {
                this.sale
                    .get('commission_value_two')
                    .setValue(this.sale.get('commission_value_two').value);
            }
            if (this.sale.get('commission_out_listing_percent').value) {
                this.sale
                    .get('commission_out_listing_percent')
                    .setValue(
                        this.sale.get('commission_out_listing_percent').value
                    );
            }
        });

        this.sale
            .get('tottal_commission_value')
            .valueChanges.subscribe((val) => {
                this.sale
                    .get('tottal_commission_amount')
                    .setValue((val / 100) * this.sale.get('sales_price').value);
            });

        this.sale.get('commission').valueChanges.subscribe((val) => {
            this.sale
                .get('commission_in_euro')
                .setValue((val / 100) * this.sale.get('sales_price').value);
        });

        this.sale.get('commission_value_one').valueChanges.subscribe((val) => {
            this.sale
                .get('commission_out_one_euro')
                .setValue((val / 100) * this.sale.get('sales_price').value);
        });

        this.sale.get('commission_value_two').valueChanges.subscribe((val) => {
            this.sale
                .get('commission_out_two_euro')
                .setValue((val / 100) * this.sale.get('sales_price').value);
        });

        this.sale
            .get('commission_out_listing_percent')
            .valueChanges.subscribe((val) => {
                this.sale
                    .get('commission_out_listing_agent_net')
                    .setValue((val / 100) * this.sale.get('commission_in_euro').value);
            });
        if (this.currentAgency && this.currentAgency.residential_properties && this.currentAgency.commercial_properties){
            this.relatedTo = true;
        } else if (this.currentAgency && !this.currentAgency.residential_properties && !this.currentAgency.commercial_properties){
            this.relatedTo = false;
        } else if(this.currentAgency && this.currentAgency.residential_properties){
            this.sale.get('related_to').setValue('residental_properties');
            this.relatedTo = false;
        } else if (this.currentAgency && this.currentAgency.commercial_properties){
            this.sale.get('related_to').setValue('non_residental_properties');
            this.relatedTo = false;
        }
    }

    calculateTotals(): any {
        const own = this.sale.get('own_property').value;
        const caId = this.sale.get('company_agency_id').value;
        const tottalCommissionAmount = this.sale.get('tottal_commission_amount')
            .value
            ? this.sale.get('tottal_commission_amount').value * 1
            : 0;
        const commissionInEuro = this.sale.get('commission_in_euro').value
            ? this.sale.get('commission_in_euro').value * 1
            : 0;
        const commissionOutOneEuro = this.sale.get('commission_out_one_euro')
            .value
            ? this.sale.get('commission_out_one_euro').value * 1
            : 0;
        const commissionOutTwoEuro = this.sale.get('commission_out_two_euro')
            .value
            ? this.sale.get('commission_out_two_euro').value * 1
            : 0;
        const sellingAgentCommissions = this.sale.get(
            'selling_agent_commissions'
        ).value;
        const commissionOutListingAgentNet = this.sale.get(
            'commission_out_listing_agent_net'
        ).value
            ? this.sale.get('commission_out_listing_agent_net').value * 1
            : 0;
        let totalSellingAgentCommissions = 0;
        forEach(sellingAgentCommissions, (sellingAgentCommission) => {
            totalSellingAgentCommissions =
                totalSellingAgentCommissions +
                (sellingAgentCommission.net_commission
                    ? sellingAgentCommission.net_commission * 1
                    : 0);
        });

        let commissionNet = 0;
        if (own && caId === this.currentAgency._id) {
            /*
             * update acording as mentioned in
             * https://gitlab.optimasit.com/arsl/optima-crm-v2/-/issues/858#note_12908
             */
            // commissionNet = commissionInEuro - (totalSellingAgentCommissions + commissionOutListingAgentNet);
            commissionNet =
                totalSellingAgentCommissions + commissionOutListingAgentNet;
        } else if (own && caId !== this.currentAgency._id) {
            commissionNet =
                commissionOutOneEuro +
                commissionOutTwoEuro +
                commissionOutListingAgentNet;
        } else {
            commissionNet = totalSellingAgentCommissions;
        }

        this.sale.get('commission_net').setValue(commissionNet);
        this.sale
            .get('commission_out_three_euro')
            .setValue(tottalCommissionAmount - commissionNet);
    }

    recalculate(): any {}

    getCommission(): any {
        return new Promise((resolve, reject) => {
            const price =
                (this.sale.get('sales_price').value ||
                    this.sale.get('listed_price').value ||
                    0) * 1;
            const data = {
                listing: 0,
                sales: 0,
            };
            if (!_.isEmpty(this.currentUser._id)) {
                let userId = this.currentUser._id;
                if (this.sale.get('listing_agent_payable').value) {
                    userId = this.sale.get('listing_agent_payable').value;
                }
                this._userService.getById(userId).subscribe((userData: any) => {
                    let commission = this.currentAgency.commission;
                    if (userData.commission) {
                        if (userData.commission.listing) {
                            if (userData.commission.listing[0]) {
                                if (
                                    userData.commission.listing[0]
                                        .listing_commission &&
                                    userData.commission.listing[0]
                                        .listing_commission > 0
                                ) {
                                    commission = userData.commission;
                                }
                            }
                        }
                    }
                    if (typeof commission !== 'undefined') {
                        if (typeof commission.listing !== 'undefined') {
                            forEach(commission.listing, (list) => {
                                if (
                                    price * 1 >= list.sale_value_from &&
                                    price * 1 <= list.sale_value_to
                                ) {
                                    if (
                                        this.sale.get('exclusive_property')
                                            .value &&
                                        list.exclusive_listing_commission
                                    ) {
                                        data.listing =
                                            list.exclusive_listing_commission;
                                    } else {
                                        data.listing = list.listing_commission;
                                    }
                                }
                            });
                        }
                        if (typeof commission.sales !== 'undefined') {
                            forEach(commission.sales, (sale) => {
                                if (
                                    price * 1 >= sale.sale_value_from &&
                                    price * 1 <= sale.sale_value_to
                                ) {
                                    data.sales = sale.sales_commission;
                                }
                            });
                        }
                    }
                    setTimeout(() => {
                        resolve(data);
                    });
                });
            }
        });
    }

    ngAfterViewChecked(): void {
        this.cdRef.detectChanges();
        if(this.sale.get('property_reference').value && includes(this.router.url, 'update/') && !this.readOnly) {
            this.readOnly = true;
        }
    }

    addCompanies(): FormGroup {
        return this.formBuilder.group({
            type: { value: '', disabled: false },
            company: { value: '', disabled: false },
            contact: { value: '', disabled: false },
            phone: { value: '', disabled: false },
            email: { value: '', disabled: false },
        });
    }

    clearFormArray = (formArray: FormArray) => {
        while (formArray.length !== 0) {
            formArray.removeAt(0);
        }
    };

    setCompanyIndex(i: any): any {
        this.companyIndex = i;
        this.searchCompaniesByType.setValue('');
        this.contactSearch.setValue('');
    }

    onSelectCompanies(company: any): void {
        this.sale
            .get('companies.' + this.companyIndex + '.phone')
            .setValue(company.phone);
        this.sale
            .get('companies.' + this.companyIndex + '.email')
            .setValue(company.email);
    }

    onSelectAccount(data: any): any {
        const price =
            (this.sale.get('sales_price').value ||
                this.sale.get('listed_price').value ||
                0) * 1;
        const commissionAmount =
            (this.sale.get('commission_in_euro').value || 0) * 1;
        this.clearFormArray(this.sellingAgentCommissions);
        if (data && isArray(data.assigned_to) && data.assigned_to.length) {
            this._userService
                .searchUsers({ username: data.assigned_to })
                .subscribe((users) => {
                    forEach(users, (user: any, key) => {
                        this.sellingAgentCommissions.push(
                            this._saleCreateComponent.addAgentCommision()
                        );
                        let commission = [];
                        if (
                            user.commission &&
                            user.commission.sales &&
                            isArray(user.commission.sales) &&
                            user.commission.sales[0].sales_commission
                        ) {
                            commission = user.commission.sales;
                        }
                        if (
                            isEmpty(commission) &&
                            this.currentAgency.commission &&
                            this.currentAgency.commission.sales &&
                            isArray(this.currentAgency.commission.sales)
                        ) {
                            commission = this.currentAgency.commission.sales;
                        }
                        let salesCommission = 0;
                        forEach(commission, (sale) => {
                            if (
                                price * 1 >= sale.sale_value_from &&
                                price * 1 <= sale.sale_value_to
                            ) {
                                salesCommission = sale.sales_commission
                                    ? sale.sales_commission
                                    : 0;
                            }
                        });
                        this.sale
                            .get(
                                'selling_agent_commissions.' +
                                    key +
                                    '.payable_to'
                            )
                            .setValue(user._id, { emitEvent: false });
                        this.sale
                            .get(
                                'selling_agent_commissions.' +
                                    key +
                                    '.commission_percent'
                            )
                            .setValue(salesCommission, { emitEvent: false });
                        this.sale
                            .get(
                                'selling_agent_commissions.' +
                                    key +
                                    '.net_commission'
                            )
                            .setValue(
                                round(
                                    (commissionAmount * 1 * salesCommission) /
                                        100,
                                    2
                                )
                            );
                    });
                });
        }
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    getDate(date: any): any {
        if (typeof date === 'string') {
            return date;
        } else {
            return date * 1000;
        }
    }

    onChangeCommissionPerc(index): void {
        let val = this.sale.get('selling_agent_commissions.' + index + '.commission_percent').value;
        this.sale.get('selling_agent_commissions.' + index + '.net_commission')
            .setValue((val / 100) * this.sale.get('commission_in_euro').value);
    }

    sellingCompanySelect(event): any {
        if (event && event.company_of_agency) {
            this.sale
                .get('company_agency_id')
                .setValue(event.company_of_agency);
        } else {
            this.sale.get('company_agency_id').reset();
        }
        if (event && this.sale.get('company_agency_id').value !== this.currentAgency._id) {
            this.sale.get('commission_two_payable').setValue(event._id);
            // this.sale.get('company_agency_id').setValue(event._id);
        }

        if(this.sale.get('related_to').value == 'non_residental_properties') {
            this.autoListPriceCommecrial(this.selectedProperty, true)
        }else {
            this.autoListPrice(this.selectedProperty, true);
        }
        this.calculateTotals();
    }

    autoListPrice(property: any, calculate: boolean): void {
        if (this.sale.get('related_to').value === 'residental_properties') {
            if (property) {
                this.selectedProperty = property;
                this.sale
                    .get('own_property')
                    .setValue(property && property.own ? property.own : false);
                this.sale
                    .get('exclusive_property')
                    .setValue(
                        property && property.exclusive ? property.exclusive : false
                    );
                this.sale
                    .get('currency')
                    .setValue(property.currency ? property.currency : 'EUR');
                this.sale
                    .get('commission_one_payable')
                    .setValue(this.currentAgency._id);
    
                if (this.sale.get('related_to').value === 'residental_properties') {
                    this.sale.get('listed_price').setValue(property.currentprice);
                    if (!this.sale.get('sales_price').value) {
                        this.sale
                            .get('sales_price')
                            .setValue(property.currentprice);
                    }
                }
    
                if (
                    property.private_info_object &&
                    property.private_info_object[this.currentAgency._id]
                ) {
                    const pio =
                        property.private_info_object[this.currentAgency._id];
                    if (pio.owner) {
                        this.sale.get('owner').setValue(pio.owner);
                    }
    
                    if (!property.own) {
                        if (pio.commission_unit) {
                            this.sale
                                .get('tottal_commission_value')
                                .setValue(pio.commission_unit);
                            this.sale
                                .get('commission')
                                .setValue(pio.commission_unit);
                        }
                        if (pio.commission) {
                            this.sale
                                .get('tottal_commission_amount')
                                .setValue(pio.commission);
                            this.sale
                                .get('commission_in_euro')
                                .setValue(pio.commission);
                        }
                    }
    
                    if (property.own) {
                        if (!_.isEmpty(property.listing_agent)) {
                            this._userService
                                .getById(property.listing_agent)
                                .subscribe((data: any) => {
                                    if (data._id) {
                                        this.sale
                                            .get('listing_agent')
                                            .setValue(data.username);
                                        this.sale
                                            .get('listing_agent_id')
                                            .setValue(data._id);
                                        this.sale
                                            .get('listing_agent_payable')
                                            .setValue(data._id);
                                    }
                                });
                        }
    
                        if (pio.commission_unit) {
                            this.sale
                                .get('tottal_commission_value')
                                .setValue(pio.commission_unit);
                        }
                        if (pio.commission) {
                            this.sale
                                .get('tottal_commission_amount')
                                .setValue(pio.commission);
                        }
    
                        if (
                            this.sale.get('own_property').value &&
                            this.sale.get('company_agency_id').value ===
                                this.currentAgency._id
                        ) {
                            if (pio.commission_unit_owner) {
                                this.sale
                                    .get('commission')
                                    .setValue(pio.commission_unit);
                            }
                            if (pio.commission_owner) {
                                this.sale
                                    .get('commission_in_euro')
                                    .setValue(pio.commission);
                            }
                        } else {
                            if (pio.commission_unit_owner) {
                                this.sale
                                    .get('commission')
                                    .setValue(pio.commission_unit_owner);
                            }
                            if (pio.commission_owner) {
                                this.sale
                                    .get('commission_in_euro')
                                    .setValue(pio.commission_owner);
                            }
                        }
    
                        if (pio.commission_unit_split) {
                            this.sale
                                .get('commission_value_one')
                                .setValue(pio.commission_unit_split);
                        }
                        if (pio.commission_split) {
                            this.sale
                                .get('commission_out_one_euro')
                                .setValue(pio.commission_split);
                        }
                    }
                }
            }
        }
    }

    autoListPriceCommecrial(property: any, calculate: boolean): void {
        if (property) {
            this.selectedProperty = property;
            this.sale.get('own_property').setValue(property && property.own ? property.own : false);
            this.sale.get('exclusive_property').setValue(property && property.exclusive ? property.exclusive : false);
            this.sale.get('currency').setValue(property.currency ? property.currency : 'EUR');
            this.sale.get('commission_one_payable').setValue(this.currentAgency._id);
            if (this.sale.get('related_to').value === 'non_residental_properties') {
                this.sale.get('listed_price').setValue(property.current_price);
                if (!this.sale.get('sales_price').value) {
                    this.sale.get('sales_price').setValue(property.current_price);
                }
            }

            if (property.private_info_object && property.private_info_object[this.currentAgency._id]) {
                const pio = property.private_info_object[this.currentAgency._id];
                if (pio.share_owners && pio.share_owners.length > 0 && pio?.share_owners[0] && pio?.share_owners[0]?.owner) {
                    this.sale.get('owner').setValue(pio.share_owners[0]?.owner);
                }

                if (!property.own) {
                    if (pio.commission_unit) {
                        this.sale.get('tottal_commission_value').setValue(pio.commission_unit);
                        this.sale.get('commission').setValue(pio.commission_unit);
                    }
                    if (pio.commission) {
                        this.sale.get('tottal_commission_amount').setValue(pio.commission);
                        this.sale.get('commission_in_euro').setValue(pio.commission);
                    }
                }

                if (property.own) {
                    if(this.sale.get('related_to').value === 'non_residental_properties'){
                        if(!_.isEmpty(property.listing_agent_id)) {
                            this._userService.getById(property.listing_agent_id).subscribe((data: any) => {
                                if (data._id) {
                                    this.sale.get('listing_agent').setValue(data.username);
                                    this.sale.get('listing_agent_id').setValue(data._id);
                                    this.sale.get('listing_agent_payable').setValue(data._id);
                                }
                            });
                        }
                    }
                    else if (!_.isEmpty(property.listing_agent)) {
                        this._userService.getById(property.listing_agent).subscribe((data: any) => {
                            if (data._id) {
                                this.sale.get('listing_agent').setValue(data.username);
                                this.sale.get('listing_agent_id').setValue(data._id);
                                this.sale.get('listing_agent_payable').setValue(data._id);
                            }
                        });
                    }

                    if (pio.commission_unit) {
                        this.sale.get('tottal_commission_value').setValue(pio.commission_unit);
                    }
                    if (pio.commission) {
                        this.sale.get('tottal_commission_amount').setValue(pio.commission);
                    }

                    if ( this.sale.get('own_property').value &&this.sale.get('company_agency_id').value === this.currentAgency._id) {
                        if (pio.commission_unit_owner) {
                            this.sale.get('commission').setValue(pio.commission_unit);
                        }
                        if (pio.commission_owner) {
                            this.sale.get('commission_in_euro').setValue(pio.commission);
                        }
                    } else {
                        if (pio.commission_unit_owner) {
                            this.sale.get('commission').setValue(pio.commission_unit_owner);
                        }
                        if (pio.commission_owner) {
                            this.sale.get('commission_in_euro').setValue(pio.commission_owner);
                        }
                    }

                    if (pio.commission_unit_split) {
                        this.sale.get('commission_value_one').setValue(pio.commission_unit_split);
                    }
                    if (pio.commission_split) {
                        this.sale.get('commission_out_one_euro').setValue(pio.commission_split);
                    }
                }
            }
        }
    }

    boatMooringAutoListPrice(property: any, calculate: boolean): void {
        const filter: any = { param: { boat: this.sale.get('related_to').value == 'Moorings' ? false : true } };
        this._gService.post(`mooring_properties/view/${property._id}`, filter, 'nodejs')
            .subscribe((data: any) => {
                property = data;                
                if (property) {
                    this.selectedProperty = property;
                    this.sale
                        .get('own_property')
                        .setValue(property && property.own ? property.own : false);
                    this.sale
                        .get('exclusive_property')
                        .setValue(
                            property && property.exclusive ? property.exclusive : false
                        );
                    this.sale
                        .get('currency')
                        .setValue(property.currency ? property.currency : 'EUR');
                    this.sale
                        .get('commission_one_payable')
                        .setValue(this.currentAgency._id);

                    this.sale.get('listed_price').setValue(property.current_price);
                    if (!this.sale.get('sales_price').value) {
                        this.sale
                            .get('sales_price')
                            .setValue(property.current_price);
                    }
                    if (
                        property.private_info_object &&
                        property.private_info_object[this.currentAgency._id]
                    ) {
                        const pio = property.private_info_object[this.currentAgency._id];
                        if (pio.owner) {
                            this.sale.get('owner').setValue(pio.owner);
                        }
                    }
                    if (!property.own) {
                        if (property.commission_unit) {
                            this.sale
                                .get('tottal_commission_value')
                                .setValue(property.commission_unit);
                            this.sale
                                .get('commission')
                                .setValue(property.commission_unit);
                        }
                        if (property.commission) {
                            this.sale
                                .get('tottal_commission_amount')
                                .setValue(property.commission);
                            this.sale
                                .get('commission_in_euro')
                                .setValue(property.commission);
                        }
                    }
                    if (property.own) {
                        if (!_.isEmpty(property.agent)) {
                            this._userService
                                .getById(property.agent)
                                .subscribe((data: any) => {
                                    if (data._id) {
                                        this.sale
                                            .get('listing_agent')
                                            .setValue(data.username);
                                        this.sale
                                            .get('listing_agent_id')
                                            .setValue(data._id);
                                        this.sale
                                            .get('listing_agent_payable')
                                            .setValue(data._id);
                                    }
                                });
                        }
                        if (property.commission_unit) {
                            this.sale
                                .get('tottal_commission_value')
                                .setValue(property.commission_unit);
                        }
                        if (property.commission) {
                            this.sale
                                .get('tottal_commission_amount')
                                .setValue(property.commission);
                        }
                        if (
                            this.sale.get('own_property').value &&
                            this.sale.get('company_agency_id').value ===
                            this.currentAgency._id
                        ) {
                            if (property.commission_unit_owner) {
                                this.sale
                                    .get('commission')
                                    .setValue(property.commission_unit);
                            }
                            if (property.commission_owner) {
                                this.sale
                                    .get('commission_in_euro')
                                    .setValue(property.commission);
                            }
                        } else {
                            if (property.commission_unit_owner) {
                                this.sale
                                    .get('commission')
                                    .setValue(property.commission_unit_owner);
                            }
                            if (property.commission_owner) {
                                this.sale
                                    .get('commission_in_euro')
                                    .setValue(property.commission_owner);
                            }
                        }
                        if (property.commission_unit_split) {
                            this.sale
                                .get('commission_value_one')
                                .setValue(property.commission_unit_split);
                        }
                        if (property.commission_split) {
                            this.sale
                                .get('commission_out_one_euro')
                                .setValue(property.commission_split);
                        }
                    }
                }
            });
    }

    onSelectOffer(offer: any): any {
        this.sale
            .get('property_reference')
            .setValue(offer.property_data.reference);
        this.autoListPrice(offer.property_data, true);
        this.sale.get('owner').setValue(offer.property_owner);
        this.sale.get('account').setValue(offer.account);
        this.onSelectAccount(offer.account_data);
        this.sale.get('sales_price').setValue(offer?.accepted_offer?.amount);
    }

    updatePropertyRef(relatedTo){        
        if(relatedTo == 'Moorings' || relatedTo == 'Boats'){
            let modelType = 'mooring_properties';
            if(relatedTo == 'Boats')
                modelType = 'boats';
            this.relatedToMooringLabel = 'Available '+ relatedTo.toLowerCase();
            let filter = {
                query: {
                    archived: { $ne: true },
                } as any,
                options: {} as any,
            } as any;
            this._dropdownsService.getMoorings(modelType, filter)
            .subscribe((data: any) => {                               
                this.searching = false;
                this.moorings = data.body;                
            });           
        }
    }
}
 