import { Pipe, PipeTransform } from '@angular/core';
import { toUpper, isEmpty, isString } from 'lodash';
import { TranslateService } from '@ngx-translate/core';
@Pipe({
    name: 'getPropertyAddress'
})
export class GetPropertyAddressPipe implements PipeTransform {

    constructor(
        private _translateService: TranslateService,
    ) { }

    transform(property: any): any {
        const currentLang = this._translateService.currentLang === 'es' ? 'es_AR' : this._translateService.currentLang;
        let address = '';
        
        if (property?.address && property.address !== "") {
            address = property.address;
        }
        if (isEmpty(address)) {
            let path = []
            if (property?.property_location?.value?.en) {
                path.push(property?.property_location?.value?.en)
            } 
            if (property?.property_sub_community?.value?.en) {
                path.push(property?.property_sub_community?.value?.en);
            } 
            address = path.join('-');
        }
        return address;
    }

}
