import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScreenHelpComponent } from './screen-help.component';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FroalaViewModule } from 'angular-froala-wysiwyg/view/view.module';



@NgModule({
  declarations: [ScreenHelpComponent],
  imports: [
    CommonModule,
    FuseSharedModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    MatToolbarModule,
    FroalaViewModule
  ],
  entryComponents: [ScreenHelpComponent]
})
export class ScreenHelpModule { }
