import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-whatsapp-confirm-send-dialog',
  templateUrl: './whatsapp-confirm-send-dialog.component.html',
  styleUrls: ['./whatsapp-confirm-send-dialog.component.scss']
})
export class WhatsappConfirmSendDialogComponent implements OnInit {

  count: Number = 0
  template: string

  constructor(
    public matDialogRef: MatDialogRef<WhatsappConfirmSendDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public _data: any,

  ) {
    this.count = _data.count
    this.template = _data.template
  }

  ngOnInit(): void {
  }

}
