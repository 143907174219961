import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { OffersService } from 'app/services';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-offers-modal',
  templateUrl: './offers-modal.component.html',
  styleUrls: ['./offers-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OffersModalComponent implements OnInit {

  counterOffers: any;
  update: FormControl = new FormControl('');
  constructor(
    public matDialogRef: MatDialogRef<OffersModalComponent>,
    private _offersService: OffersService,
    @Inject(MAT_DIALOG_DATA) public _data: any,
  ) { }

  ngOnInit(): void {
    this._offersService.getById(this._data._id).subscribe((data: any) => {
      this.counterOffers = data.body;
    });

    this.update.valueChanges
      .pipe(
        switchMap(() => {
          return this._offersService.getById(this._data._id);
        })
      )
      .subscribe((data: any) => {
        this.counterOffers = data.body;
      });
  }

}
