import {
    AfterViewChecked,
    ChangeDetectorRef,
    Component,
    Inject,
    OnDestroy,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { fuseAnimations } from '@fuse/animations';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { TranslateService } from '@ngx-translate/core';
import {
    AgenciesService,
    AuthenticationService,
    DropdownsService,
    OfficesService,
} from 'app/services';
import { CrmMenuService } from 'app/services/crm-menu.service';
import { CronJobsService } from 'app/services/cron-jobs.service';
import { AppProviders } from 'app/_helpers';
import { BaseService } from 'app/_helpers/base/base.service';
import * as _ from 'lodash';
import { find, forEach, indexOf, map, remove, sortBy, uniq } from 'lodash';
import { forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'mls-wizard',
    templateUrl: './mls-wizard.component.html',
    styleUrls: ['./mls-wizard.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class MlsWizardComponent implements OnInit, AfterViewChecked, OnDestroy {
    private _unsubscribeAll: Subject<any>;
    systemLanguages: any;
    languageToAdd: any;
    currentLang: string;
    currencies: Array<object>;

    commonSystemLanguages = [
        { key: 'CA', value: 'Catalan' },
        { key: 'DK', value: 'Danish' },
        { key: 'NL', value: 'Dutch' },
        { key: 'EN', value: 'English' },
        { key: 'FR', value: 'French' },
        { key: 'FI', value: 'Finish' },
        { key: 'DE', value: 'German' },
        { key: 'HU', value: 'Hungarian' },
        { key: 'IT', value: 'Italian' },
        { key: 'MA', value: 'Mandarin' },
        { key: 'NO', value: 'Norwegian' },
        { key: 'PT', value: 'Portuguese' },
        { key: 'PO', value: 'Polish' },
        { key: 'RO', value: 'Romanian' },
        { key: 'RU', value: 'Russian' },
        { key: 'ES', value: 'Spanish' },
        { key: 'SV', value: 'Swedish' },
        { key: 'TK', value: 'Turkish' },
    ];
    feeds = [
        { key: 'ro', value: 'Resale Online own properties feed' },
        { key: 'optima', value: 'Optima-crm properties feed' },
        { key: 'kyero', value: 'Kyero own properties feed' },
        { key: 'zimmo', value: 'Zimmo properties feed' },
    ];
    frequencies = [
        { key: 'nightly', value: 'Nightly' },
        { key: 'weekly', value: 'Weekly' },
    ];

    currency: any;
    defaultMeasure: any;
    currentUser: any;
    currentOffice: any;
    currentAgency: any;
    mapLat: any;
    mapLng: any;
    mapZoom: any;
    savedLanguages: any = [];
    siteLanguages: any;
    keyToAdd: string;
    residentialSale: boolean;
    residentialRentals: boolean;
    nonResidentialSale: boolean;
    nonResidentialLeases: boolean;
    userMenu: any;
    navigation: any;

    form: FormGroup;

    constructor(
        public matDialogRef: MatDialogRef<MlsWizardComponent>,
        @Inject(MAT_DIALOG_DATA) public _data: any,
        private _authenticationService: AuthenticationService,
        private _translateService: TranslateService,
        private _baseService: BaseService,
        private _officesService: OfficesService,
        private _agenciesService: AgenciesService,
        private cdRef: ChangeDetectorRef,
        private _appProviders: AppProviders,
        private _crmMenuService: CrmMenuService,
        private _fuseNavigationService: FuseNavigationService,
        private _dropdownsService: DropdownsService,
        private _cronJobsService: CronJobsService
    ) {
        this._unsubscribeAll = new Subject();
        this.siteLanguages = this._appProviders.siteLanguages;
        this.currentUser = this._authenticationService.currentUserValue;
        this.currentAgency = this._authenticationService.currentAgencyValue;
        this.currentOffice = this._authenticationService.currentOfficeValue;
    }

    ngOnInit(): void {
        this.form = new FormGroup({
            import_feeds: new FormArray([this.createImportFeedFormGroup()]),
        });

        this.currentLang = this._translateService.currentLang;
        this._cronJobsService
            .getAdminJobs({ title: 'mls-import-feed' })
            .subscribe((data: any) => {
                if (data?.body?.length) {
                    for (let i = 1; i < data.body.length; i++) {
                        this.addDynamicField(
                            'import_feeds',
                            this.createImportFeedFormGroup()
                        );
                    }
                    this.form.patchValue({ import_feeds: data?.body });
                }
            });
        this._dropdownsService.getCurrencies().subscribe((data: any) => {
            this.currencies = data;
        });
        this._authenticationService.currentUser
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((currentUser: any) => {
                if (currentUser) {
                    this.currentUser = currentUser;
                }
            });
        this._authenticationService.currentAgency
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((currentAgency: any) => {
                if (currentAgency) {
                    this.currentAgency = currentAgency;
                    if (!this.currentAgency.map) {
                        this.currentAgency.map = {};
                    }
                }
            });
        this._authenticationService.currentOffice
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((currentOffice: any) => {
                if (currentOffice) {
                    this.currentOffice = currentOffice;
                    this.currency = this.currentOffice.currency
                        ? this.currentOffice.currency
                        : 'EUR';
                    this.defaultMeasure = this.currentOffice.default_measure
                        ? this.currentOffice.default_measure
                        : 'Metres';
                    if (!this.currentOffice.map) {
                        this.currentOffice.map = {};
                    }
                    this.mapLat =
                        this.currentOffice.map && this.currentOffice.map.lat
                            ? this.currentOffice.map.lat
                            : '';
                    this.mapLng =
                        this.currentOffice.map && this.currentOffice.map.lng
                            ? this.currentOffice.map.lng
                            : '';
                    this.mapZoom =
                        this.currentOffice.map && this.currentOffice.map.zoom
                            ? this.currentOffice.map.zoom
                            : '14';
                }
            });
        setTimeout(() => {
            if (this.currentAgency) {
                this.loadLanguages();
                this.loadMenu();
            }
        }, 3000);
    }

    loadMenu(): void {
        const postData: any = {
            access_level: 'agency',
            agency: this.currentAgency._id,
            office: null,
        };
        this._baseService
            .post(`crm_menu/get_access`, postData, 'nodejs')
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((data: any) => {
                this.userMenu = data;
                if (
                    indexOf(data.menu_ids, 67) > -1 &&
                    indexOf(data.menu_ids, 68) > -1
                ) {
                    this.residentialSale = true;
                }
                if (
                    indexOf(data.menu_ids, 67) > -1 &&
                    indexOf(data.menu_ids, 68) > -1 &&
                    indexOf(data.menu_ids, 115) > -1
                ) {
                    this.residentialRentals = true;
                }
                if (
                    indexOf(data.menu_ids, 67) > -1 &&
                    indexOf(data.menu_ids, 2) > -1
                ) {
                    this.nonResidentialSale = true;
                }
                if (
                    indexOf(data.menu_ids, 67) > -1 &&
                    indexOf(data.menu_ids, 2) > -1 &&
                    indexOf(data.menu_ids, 119) > -1
                ) {
                    this.nonResidentialLeases = true;
                }
            });
    }

    loadLanguages(): void {
        this._baseService
            .get('languages/for-system?get_all=true&expand=is_active')
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((data: any) => {
                this.systemLanguages = sortBy(data, ['value.en']);
                this.savedLanguages = map(this.systemLanguages, 'key');
            });
    }

    createImportFeedFormGroup(): FormGroup {
        return new FormGroup({
            _id: new FormControl(''),
            type: new FormControl('', Validators.required),
            frequency: new FormControl('nightly'),
            file_url: new FormControl('', Validators.required),
            status: new FormControl(true),
        });
    }

    public addDynamicField(
        formArrayName: string,
        field: FormGroup | FormControl
    ): void {
        (this.form.get(formArrayName) as FormArray).push(field);
    }

    public removeDynamicField(formArrayName: string, index: number): void {
        const id = this.form.get(formArrayName + '.' + index + '._id').value;
        if (id) {
            this._cronJobsService
                .deleteData({ _id: id })
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((data) => {
                    console.log(`Cron removed ${id}`);
                });
        }
        (this.form.get(formArrayName) as FormArray).removeAt(index);
    }

    includeExclude(event: MatSlideToggleChange, data: any): void {
        const postData = {
            for: 'System',
            id: data._id,
            key: data.key,
            model: 'languages',
            to_exclude: !event.checked,
        };
        this._baseService
            .post('agencies/model-exclusions', postData)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this._baseService.succussMessage('updated sucessfuly', 'ok');
            });
    }

    saveAndClose(): void {
        this.currentOffice.currency = this.currency;
        this.currentOffice.default_measure = this.defaultMeasure;
        this.currentOffice.map.lat = this.mapLat;
        this.currentOffice.map.lng = this.mapLng;
        this.currentOffice.map.zoom = this.mapZoom;

        this.currentAgency.currency = this.currency;
        this.currentAgency.uom = this.defaultMeasure;
        this.currentAgency.map.lat = this.mapLat;
        this.currentAgency.map.lng = this.mapLng;
        this.currentAgency.map.zoom = this.mapZoom;

        forkJoin([
            this._officesService.update(this.currentOffice),
            this._agenciesService.update(this.currentAgency),
            this._baseService.post(
                `crm_menu/set_access`,
                this.userMenu,
                'nodejs'
            ),
        ])
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                (data: any) => {
                    this._authenticationService.setCurrentOffice(
                        this.currentOffice
                    );
                    this._authenticationService.setCurrentAgency(
                        this.currentAgency
                    );
                    this.reloadMenu();
                    this.addCron();
                    this._baseService.succussMessage(
                        'updated sucessfuly',
                        'ok'
                    );
                },
                () => {}
            );
    }

    addCron(): void {
        if (this.form.status === 'VALID') {
            const postData: any = this.form.get('import_feeds').value;
            this._cronJobsService
                .createMultiCrons(postData)
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((data) => {
                    this.matDialogRef.close();
                });
        } else {
            this.form.markAllAsTouched();
            this.matDialogRef.close();
        }
    }

    dontShow(): void {
        this.currentAgency.mls_wizard = false;
        this._agenciesService
            .update(this.currentAgency)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this._authenticationService.setCurrentAgency(
                    this.currentAgency
                );
            });
        this.matDialogRef.close();
    }

    removeLanguage(key: any): void {
        const languageToRemove = find(this.systemLanguages, { key: key });
        this._baseService
            .delete(
                `languages/delete/${languageToRemove._id}`,
                languageToRemove,
                'nodejs'
            )
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this._baseService.succussMessage('removed sucessfuly', 'ok');
                this.loadLanguages();
            });
    }

    addLanguage(): void {
        this.languageToAdd = find(this.commonSystemLanguages, {
            key: this.keyToAdd,
        });

        const postData = {
            value: {},
            key: this.languageToAdd.key,
            for: 'System',
            status: 'Active',
        };
        const value = {};
        forEach(this.siteLanguages, (lang) => {
            value[lang.id] = this.languageToAdd.value;
        });
        postData.value = value;
        this._baseService
            .post('languages/create', postData, 'nodejs')
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this.keyToAdd = '';
                this._baseService.succussMessage('add sucessfuly', 'ok');
                this.loadLanguages();
            });
    }

    updateMenu(event: MatCheckboxChange, model: any): void {
        remove(this.userMenu.menu_ids, (n) => {
            return (
                n === 67 ||
                n === 68 ||
                n === 115 ||
                n === 2 ||
                n === 119 ||
                n === 153
            );
        });

        if (this.residentialSale) {
            this.userMenu.menu_ids.push(67);
            this.userMenu.menu_ids.push(68);
        }
        if (this.residentialRentals) {
            this.userMenu.menu_ids.push(67);
            this.userMenu.menu_ids.push(68);
            this.userMenu.menu_ids.push(115);
        }
        if (this.nonResidentialSale) {
            this.userMenu.menu_ids.push(153);
            this.userMenu.menu_ids.push(2);
        }
        if (this.nonResidentialLeases) {
            this.userMenu.menu_ids.push(153);
            this.userMenu.menu_ids.push(2);
            this.userMenu.menu_ids.push(119);
        }
        this.userMenu.menu_ids = uniq(this.userMenu.menu_ids);
        this.userMenu.menu_ids = sortBy(this.userMenu.menu_ids);
    }

    reloadMenu(): void {
        this._crmMenuService
            .getMenuStructure()
            // .pipe(takeUntil(this._unsubscribeAll))
            .then((menu: any) => {
                this.navigation = menu;
                this._fuseNavigationService.unregister('main');
                this._fuseNavigationService.register('main', this.navigation);
                this._fuseNavigationService.setCurrentNavigation('main');
                this._translateService.reloadLang(
                    this._translateService.currentLang
                );
            });
    }

    ngAfterViewChecked(): void {
        this.cdRef.detectChanges();
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
