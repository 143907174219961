import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FuseSharedModule } from '@fuse/shared.module';
import { QuickAddComponent } from 'app/main/master-data/accounts/quick-add/quick-add.component';

import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule, MatRippleModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSortModule } from '@angular/material/sort';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatChipsModule } from '@angular/material/chips';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDividerModule } from '@angular/material/divider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TabProfileModule } from '../accounts-create/tabs/tab-profile/tab-profile.module';
import { TabProfileNonresidentialModule } from '../accounts-create/tabs/tab-profile-nonresidential/tab-profile-nonresidential.module';
import { MooringProfileModule } from '../accounts-create/tabs/mooring-profile/mooring-profile.module';
import { BoatProfileModule } from '../accounts-create/tabs/boat-profile/boat-profile.module';


@NgModule({
  declarations: [

    QuickAddComponent
  ],
  entryComponents: [QuickAddComponent],
  imports: [
    CommonModule,
    FuseSharedModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatOptionModule,
    MatSelectModule,
    MatPaginatorModule,
    MatTableModule,
    MatTabsModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    MatMenuModule,
    MatToolbarModule,
    MatChipsModule,
    MatExpansionModule,
    MatRippleModule,
    MatSnackBarModule,
    NgxMatSelectSearchModule,
    MatButtonToggleModule,
    MatDividerModule,
    MatTooltipModule,
    NgxMatSelectSearchModule,
    TabProfileModule,
    TabProfileNonresidentialModule,
    MooringProfileModule,
    BoatProfileModule
  ]
})
export class QuickAddModule { }
