import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Agencies } from '../models';

@Injectable({
  providedIn: 'root'
})
export class AgenciesService {

  constructor(
    private http: HttpClient,
  ) { }

  getAll(data: any, page: any, perPage?: any): Observable<Agencies[]> {
    page = page ? page : 1;
    perPage = perPage ? perPage : 10;
    return this.http.post<Agencies[]>(`${environment.yiiUrl}agencies/limited-agencies?page=${page}&per-page=${perPage}`, data, { observe: 'response' as 'body' })
      .pipe(map(agencies => {
        return agencies;
      }));
  }

  getById(_id: string): Observable<object> {
    return this.http.get(`${environment.yiiUrl}agencies/${_id}`, {});
  }

  getAgencyEmail(_id: string) {
    return this.http.get<string>(`${environment.yiiUrl}agencies/get-email?id=${_id}`, {});
  }

  create(agency: Agencies): Observable<object> {
    return this.http.post(`${environment.yiiUrl}agencies`, agency);
  }

  update(agency: Agencies): Observable<object> {
    return this.http.put(`${environment.yiiUrl}agencies/${agency._id}`, agency);
  }

  delete(_id: number, createdBy: any): Observable<object> {
    return this.http.delete(`${environment.yiiUrl}agencies/${_id}?created_by=${createdBy}`);
  }

  searchAgencies(page: string, data: any): Observable<Agencies[]> {
    return this.http.post<Agencies[]>(`${environment.yiiUrl}agencies/search?page=` + page, data, { observe: 'response' as 'body' })
      .pipe(map(agencies => {
        return agencies;
      }));
  }

  uploadImage(id: string, data: any, model: any, watermark?: boolean): Observable<any> {
    const formData: any = new FormData();
    formData.append('id', id);
    formData.append('model', model);
    if (watermark) {
      formData.append('watermark', 'true');
    }
    formData.append('file', data);
    return this.http.post(`${environment.yiiUrl}users/upload-single`, formData);
  }

  getSearchedAgencies(page: any, data: any, perPage: any): Observable<any> {
    return this.http.post(`${environment.yiiUrl}agencies/get-searched?page=${page}&per-page=${perPage}`, data, { observe: 'response' as 'body' });
  }

  getFeedSystems(): Observable<object> {
    return this.http.get(`${environment.yiiUrl}agencies?search=1`, {});
  }

  getZones(search?: string): Observable<object> {
    return this.http.get(`${environment.yiiUrl}agencies/get-zones?search=${search}`);
  }

  deactiveAgency(_id: string, data: any): Observable<object> {
    return this.http.get(`${environment.yiiUrl}agencies/deactive-agency?id=${_id}&status=${data}`);
  }

  getLogo(_id: string) {
    return this.http.get(`${environment.apiUrl}agencies/logo/${_id}`);
  }

}
