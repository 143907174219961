import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { map, expand } from 'rxjs/operators';
import { environment } from './../../environments/environment';
import { User } from './../models';
import { AsyncValidatorFn, AbstractControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '.';

@Injectable({ providedIn: 'root' })
export class UserService {
    private appSearchDataSubject: BehaviorSubject<any> =
        new BehaviorSubject<any>([]);
    public appSearchData: Observable<any> =
        this.appSearchDataSubject.asObservable();

    constructor(
        private http: HttpClient,
        private _translateService: TranslateService
    ) {}

    public get appSearchDataValue(): any {
        return this.appSearchDataSubject.value;
    }

    public setAppSearchDataValue(data: any): any {
        this.appSearchDataSubject.next(data);
    }

    getAll(
        data: any,
        page: any,
        perPage?: any,
        isSupport?: any
    ): Observable<User[]> {
        perPage = perPage ? perPage : '';
        if (isSupport) {
            return this.http
                .post<User[]>(
                    `${environment.yiiUrl}users/mongo-search?page=` +
                        page +
                        `&per-page=${perPage}&support_user=false`,
                    data,
                    { observe: 'response' as 'body' }
                )
                .pipe(
                    map((users) => {
                        return users;
                    })
                );
        } else {
            return this.http
                .post<User[]>(
                    `${environment.yiiUrl}users/mongo-search?page=` +
                        page +
                        `&expand=agency_name,office_name,reports_to_data&per-page=${perPage}`,
                    data,
                    { observe: 'response' as 'body' }
                )
                .pipe(
                    map((users) => {
                        return users;
                    })
                );
        }
    }

    getById(_id: string): Observable<object> {
        return this.http.get(`${environment.yiiUrl}users/${_id}`);
    }

    create(user: User): Observable<object> {
        return this.http.post(`${environment.yiiUrl}users`, user);
    }

    update(user: any): Observable<object> {
        return this.http.put(`${environment.yiiUrl}users/${user._id}`, user);
    }

    deleteUserWithUpdate(id: any, newId: any): Observable<object> {
        return this.http.get(
            `${environment.yiiUrl}users/user-on-delete?old_user=${id}&new_user=${newId}`
        );
    }

    delete(_id: any, createdBy: any, agency: any): Observable<any> {
        return this.http.delete(
            `${environment.yiiUrl}users/${_id}?created_by=${createdBy}&agency=${agency}`
        );
    }

    terminateSession(_id: any): Observable<any> {
        return this.http.get(
            `${environment.yiiUrl}users/terminate-session?id=${_id}`
        );
    }

    getHistory(_id: any): Observable<any> {
        return this.http.post<User[]>(
            `${environment.yiiUrl}users/history?id=${_id}`,
            {}
        );
    }

    searchUser(page: string, data: any): Observable<User[]> {
        return this.http
            .post<User[]>(
                `${environment.yiiUrl}users/search?page=` + page,
                data,
                { observe: 'response' as 'body' }
            )
            .pipe(
                map((user) => {
                    return user;
                })
            );
    }

    searchUsers(data: any): Observable<User[]> {
        return this.http.post<User[]>(
            `${environment.yiiUrl}users/search?per-page=false`,
            data
        );
    }

    getOutLookLoginUrl(): Observable<any> {
        return this.http.get(
            `${environment.yiiUrl}users/get-out-look-login-url`
        );
    }

    getGmailLoginUrl(): Observable<any> {
        return this.http.get(`${environment.yiiUrl}users/get-url`);
    }

    postFile(fileToUpload: File, id: any): Observable<any> {
        const formData: FormData = new FormData();
        formData.append('photo', fileToUpload);
        return this.http.post(
            `${environment.apiUrl}attachments/single-upload/users/` + id,
            formData
        );
    }

    getFav(): Observable<any> {
        return this.http.get(`${environment.yiiUrl}users/get-fav`);
    }

    savFav(data: any): Observable<any> {
        return this.http.post(`${environment.yiiUrl}users/save-fav`, data);
    }

    uploadImage(id: string, data: any, model: any): Observable<any> {
        const formData: any = new FormData();
        formData.append('id', id);
        formData.append('model', model);
        formData.append('file', data);
        return this.http.post(
            `${environment.yiiUrl}users/upload-single`,
            formData
        );
    }

    getCounts(): Observable<object> {
        return this.http.get(`${environment.yiiUrl}users/get-counts`);
    }

    searchData(text: string): Observable<object> {
        return this.http
            .post(`${environment.apiUrl}app/search?text=${text}`, {})
            .pipe(
                map((data) => {
                    this.setAppSearchDataValue(data);
                    return data;
                })
            );
    }

    getSystemMessages(page?: any): Observable<object> {
        page = page ? page : 1;
        return this.http.get(
            `${environment.yiiUrl}system-messages/load-messages?page=${page}`,
            { observe: 'response' as 'body' }
        );
    }

    messageGotIt(id: any): Observable<object> {
        return this.http.get(
            `${environment.yiiUrl}system-messages/got-it?message_id=${id}`
        );
    }

    getRoles(): Observable<object> {
        return this.http.get(`${environment.yiiUrl}users/get-roles`);
    }

    getSites(): Observable<object> {
        return this.http.get(`api/web-sites`);
    }

    getCountryDials(): Observable<object> {
        return this.http.get(`api/country-dial-codes`);
    }

    registerFreemiumUse(data: any): Observable<object> {
        return this.http.post(
            `${environment.yiiUrl}users/register?lang=${this._translateService.currentLang}`,
            data
        );
    }

    getProducts(): Observable<object> {
        return this.http.post(`${environment.apiUrl}app/get-products`, {});
    }

    subscribeUser(data): Observable<object> {
        return this.http.post(`${environment.apiUrl}users/subscribe`, data);
    }

    userValidator(): AsyncValidatorFn {
        return (
            control: AbstractControl
        ): Observable<{ [key: string]: any } | null> => {
            return this.http
                .get(
                    `${environment.yiiUrl}users/validate-user?email=${control.value}`
                )
                .pipe(
                    map((user) => {
                        if (user) {
                            return { userNameExists: true };
                        }
                    })
                );
        };
    }
}
