import { Directive, ElementRef, Renderer2, HostListener, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PdfListDialogComponent } from './pdf-list-dialog/pdf-list-dialog.component';
import { PdfViewDialogComponent } from './pdf-view-dialog/pdf-view-dialog.component';

@Directive({
    selector: '[pdfTemplates]'
})
export class PdfTemplatesDirective {

    @Input() propertyId: string;
    @Input() viewingToursId: string;
    @Input() viewRecord = false;
    @Input() properties = [];
    @Input() type: string;

    constructor(
        private el: ElementRef,
        private renderer: Renderer2,
        public _matDialog: MatDialog
    ) {
        this.renderer.setStyle(this.el.nativeElement, 'cursor', 'pointer');
    }

    @HostListener('click') onClick(): void {
        this.openListDialog()
    }
    
    openListDialog(){
        const dialogRef = this._matDialog.open(PdfListDialogComponent, {
            panelClass: 'pdf-list-dialog',
            data: {
                viewRecord: this.viewRecord,
                properties: this.properties,
                type: this.type
            },
        });
        dialogRef.afterClosed()
            .subscribe(response => {
                if (response) {
                    this.viewTemplate(response);
                }
            });
    }

    viewTemplate(template: any): void {
        const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const dialogRef = this._matDialog.open(PdfViewDialogComponent, {
            panelClass: 'pdf-view-dialog',
            data: {
                property_id: this.propertyId,
                viewing_tours_id: this.viewingToursId,
                template_id: template.key,
                company_id: template.company,
                type: this.type,
                userTimeZone: userTimeZone
            },
        });
        dialogRef.afterClosed()
            .subscribe(response => {
                this.openListDialog()
            });
    }
}
