import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PreviewEmailComponent } from './preview-email.component';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseDirectivesModule } from '@fuse/directives/directives';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';


@NgModule({
  declarations: [PreviewEmailComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    MatTooltipModule,
    MatToolbarModule,
    TranslateModule,
    FlexLayoutModule,
    FuseDirectivesModule,
    MatProgressSpinnerModule
  ],
  exports: [PreviewEmailComponent],
  entryComponents: [PreviewEmailComponent]
})
export class PreviewEmailModule { }
