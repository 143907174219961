import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder } from '@angular/forms';
import { isEmpty } from 'lodash';
import { takeUntil, switchMap, startWith } from 'rxjs/operators';
import { DropdownsService, AuthenticationService } from 'app/services';
import { Subject } from 'rxjs';
@Component({
  selector: 'grid-board-create-dialog',
  templateUrl: './grid-board-create-dialog.component.html',
  styleUrls: ['./grid-board-create-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GridBoardCreateDialogComponent implements OnInit {

  title: string;
  form: FormGroup;
  isUpdate = false;
  offices: any;
  currentOffice: any;
  searchOffices$ = new Subject<string>();
  private _unsubscribeAll: Subject<any>;
  constructor(
    public dialogRef: MatDialogRef<GridBoardCreateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public _data: any,
    public _formBuilder: FormBuilder,
    private _dropdownsService: DropdownsService,
    private _authenticationService: AuthenticationService,
  ) {

    this.currentOffice = _authenticationService.currentOfficeValue;
    this.form = this._formBuilder.group({
      _id: [],
      title: [],
      offices: { value: [this.currentOffice._id], disabled: false },
    });
    this._unsubscribeAll = new Subject();
  }
  ngOnInit(): void {
    this.searchOffices$
      .pipe(
        takeUntil(this._unsubscribeAll),
        startWith(''),
        switchMap((search: string) => {
          return this._dropdownsService.getPropertiesOffices(search);
        }),
      )
      .subscribe((data: any) => {
        this.offices = data.body;
      });
    if (this._data && !isEmpty(this._data)) {
      this.isUpdate = true;
      this.form.patchValue(this._data);
    }

  }
  onNoClick(): void {
    this.dialogRef.close();
  }

}
