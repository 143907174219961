import { Pipe, PipeTransform } from '@angular/core';
import { AuthenticationService } from 'app/services';
import { CurrencyPipe } from '@angular/common';

@Pipe({
  name: 'getFormattedPrice'
})
export class GetFormattedPricePipe implements PipeTransform {
  user: any;

  constructor(
    private _authenticationService: AuthenticationService,
    private _currencyPipe: CurrencyPipe) {
    this.user = this._authenticationService.currentUserValue;
  }

  transform(price: any, from?:any): any {
    if (price) {
      const defaultSeparators = this.user.default_separators ? this.user.default_separators : 'en';
      // const result = this._currencyPipe.transform(price.toString().replace(/\./g, ''), '', '', '1.0-0', defaultSeparators);
      let result = '';
      if(from && from =='average_price'){
        result = this._currencyPipe.transform(price, '', '', '1.0-0', defaultSeparators);
      } else {
        result = this._currencyPipe.transform(price, '', '', '1.0-2', defaultSeparators);
      }
      return result.trim();
    }
  }

}
