import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JsonViewerComponent } from './json-viewer.component';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatToolbarModule } from '@angular/material/toolbar';



@NgModule({
  declarations: [JsonViewerComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    MatToolbarModule,
    NgxJsonViewerModule,
    TranslateModule
  ],
  exports: [
    JsonViewerComponent
  ],
  entryComponents: [
    JsonViewerComponent
  ]
})
export class JsonViewerModule { }
