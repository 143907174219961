import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { round } from 'lodash';
@Directive({
  selector: '[appNonDigitDecimaNumber]',
})

export class AppNonDigitDecimaNumberDirective {

  @Input() numberValue: FormControl = new FormControl(0);

  constructor(private el: ElementRef) {
  }

  @HostListener('change')
  // tslint:disable-next-line: use-lifecycle-interface
  ngOnChanges(): any {
    if (this.numberValue && this.numberValue.value) {
      this.numberValue.setValue(parseInt(this.numberValue.value));
    }
  }
}
