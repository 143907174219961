import { Directive, ElementRef, Renderer2, HostListener, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DrawMapComponent } from './draw-map/draw-map.component';
import { isEmpty, forEach } from 'lodash';
import { DropdownsService, AuthenticationService } from 'app/services';
@Directive({
    selector: '[drawMap]'
})
export class DrawMapDirective {

    @Input() view = false;
    @Input() polygons: any = [];
    @Input() type: any = [];
    @Output() getPolygons: EventEmitter<any>;
    currentAgency: any;

    constructor(
        private el: ElementRef,
        private renderer: Renderer2,
        public _matDialog: MatDialog,
        public _dropdownService: DropdownsService,
        private _authenticationService: AuthenticationService
    ) {
        this.renderer.setStyle(this.el.nativeElement, 'cursor', 'pointer');
        this.getPolygons = new EventEmitter();
    }

    @HostListener('click') onClick(): void {
        if (isEmpty(this.polygons)) {
            this.polygons = [];
        }
        const dialogRef = this._matDialog.open(DrawMapComponent, {
            panelClass: 'drawMap-dialog',
            data: {
                polygons: this.polygons,
                view: this.view,
                type: this.type,
            },
        });
        dialogRef.afterClosed()
            .subscribe((response: any) => {
                if (response) {
                    if (!isEmpty(this.type) && this.type === 'commercial') {
                        let filter = {
                            "query": {
                                "archived": {
                                    "$ne": true
                                },
                                "status": {
                                    "$in": [
                                        "Available",
                                        "Under Offer",
                                        "Valuation"
                                    ]
                                }
                            },
                            "options": {
                                "page": 1,
                                "limit": 20,
                                "sort": {
                                    "reference": -1
                                },
                                "rejection": false
                            },
                            "from_crm": 1,
                            "selectRecords": false
                        }
                        this._dropdownService.getCommercialWithAllLatlng(filter).subscribe((properties) => {

                            const pids = [];
                            this.currentAgency = this._authenticationService.currentAgencyValue._id;
                            const vertices_x = [];
                            const vertices_y = [];
                            let properties_exist = [];
                            const latlng = {};
                            response.forEach(polygon => {
                                polygon.forEach(value => {
                                    vertices_x.push(value.lat);
                                    vertices_y.push(value.lng);
                                });
                                const points_polygon = vertices_x.length; // number vertices
                                    properties.forEach(property => {
                                        if (property?.private_info_object[this.currentAgency]?.latitude && property?.private_info_object[this.currentAgency]?.longitude) {
                                            const longitude_x = property?.private_info_object[this.currentAgency]?.latitude;
                                            const latitude_y = property?.private_info_object[this.currentAgency]?.longitude;

                                            let isMatched = this.isInPolygon(points_polygon, vertices_x, vertices_y, longitude_x, latitude_y);
                                            if (isMatched) {
                                                properties_exist.push(property._id.toString());
                                                latlng[property._id.toString()] = [longitude_x, latitude_y];
                                            }
                                        }
                                    });
                            });
                            this.getPolygons.next(properties_exist);
                        });
                    }
                    else {
                        this.getPolygons.next(response);
                    }

                }
            });
    }

    isInPolygon(points_polygon: any, vertices_x: any, vertices_y: any, longitude_x: any, latitude_y: any): boolean {
        let c: boolean = false;
        for (let i = 0; i < points_polygon; i++) {
            const j = points_polygon - 1;
            if (vertices_y[i] < latitude_y && vertices_y[j] >= latitude_y || vertices_y[j] < latitude_y && vertices_y[i] >= latitude_y) {
                if ((vertices_x[i] + (latitude_y - vertices_y[i]) / (vertices_y[j] - vertices_y[i]) * (vertices_x[j] - vertices_x[i])) < longitude_x) {
                    c = !c;
                }
            }
        }
        return c;
    }

//     isLatLngInZone(latLngs: any, lat: any, lng: any): boolean {
//         const verticesY = [];
//         const verticesX = [];
//         const longitudeX = lng;
//         const longitudeY = lat;
//         let r = 0;
//         let i = 0;
//         let j = 0;
//         let c = false;
//         let point = 0;

//         for (r = 0; r < latLngs.length; r++) {
//             verticesY.push(latLngs[r].lat);
//             verticesX.push(latLngs[r].lng);
//         }
//         const pointsPolygon = verticesX.length;
//         for (i = 0, j = pointsPolygon; i < pointsPolygon; j = i++) {
//             point = i;
//             if (point === pointsPolygon) {
//                 point = 0;
//             }

//             if (((verticesY[point] > longitudeY !== (verticesY[j] > longitudeY)) &&
//                 (longitudeX < (verticesX[j] - verticesX[point]) * (longitudeY - verticesY[point]) / (verticesY[j] - verticesY[point]) + verticesX[point]))) {
//                 c = !c;
//             }

//         }
//         return c;
//     }

// }

}
