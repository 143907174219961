import { fuseAnimations } from '@fuse/animations';
import { Component, OnInit, ViewEncapsulation, Input, Injector, ChangeDetectorRef, EventEmitter } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { DropdownsService } from 'app/services';
import { TranslateService } from '@ngx-translate/core';
import { merge, Subject } from 'rxjs';
import * as _ from 'lodash';
import { startWith, tap, switchMap, takeUntil } from 'rxjs/operators';
import { AccountsCreateComponent } from '../../accounts-create.component';
import { map } from 'lodash';
import { BaseService } from 'app/_helpers/base/base.service';
@Component({
    selector: 'boat-profile',
    templateUrl: './boat-profile.component.html',
    styleUrls: ['./boat-profile.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class BoatProfileComponent implements OnInit {
    @Input() form: FormGroup;
    currentLang: string;
    transactionOptions = [];
    searchBoatTypes$ = new Subject<string>();
    boatTypes: any;
    buy: boolean;
    rent: boolean;
    systemLang: any;
    searching: boolean;
    countries: any;
    search = '';
    countriesSearch: FormControl = new FormControl('');
    boatTypeOneSearch: FormControl = new FormControl('');
    boatTypeTwoSearch: FormControl = new FormControl('');
    regions: any;
    regionsSearch: FormControl = new FormControl('');
    provinces: any;
    provincesSearch: FormControl = new FormControl('');
    cities: any;
    citiesSearch: FormControl = new FormControl('');
    boatCategories: any;
    private _unsubscribeAll: Subject<any>;
    _accountsCreateComponent: AccountsCreateComponent;
    langReload = new EventEmitter<any>();
    boatSubTypes: any;
    HullMaterialSearch: FormControl = new FormControl('');
    DriveTypeSearch: FormControl = new FormControl('');
    FueltypeSearch: FormControl = new FormControl('');
    allDriveType: any;
    filteredDriveTypes: any;
    hullMaterialData = [];
    allFuelType: any;
    filteredFuelTypes: any;
    constructor(
        private _dropdownsService: DropdownsService,
        private _translateService: TranslateService,
        private injector: Injector,
        private cdRef: ChangeDetectorRef,
        private _gService: BaseService
    ) {
        this.currentLang = this._translateService.currentLang === 'es' ? 'es_AR' : this._translateService.currentLang;
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        this._gService
        .post(`drive_types/all`, {}, 'nodejs')
        .subscribe((data: any) => {
          this.allDriveType = data;
          this.filteredDriveTypes = data;
        });
        this._gService
        .post(`fuel_types/all`, {}, 'nodejs')
        .subscribe((data: any) => {
          this.allFuelType = data;
          this.filteredFuelTypes = data;
        });
        this._translateService.onLangChange
            .pipe(startWith(''), takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this.systemLang = this._translateService.currentLang;
                this.currentLang =
                    this._translateService.currentLang === 'es'
                        ? 'es_AR'
                        : this._translateService.currentLang;
                this.loadDropdowns();
                this.langReload.next(true);
            });
        if (!this.form) {
            this._accountsCreateComponent = this.injector.get(AccountsCreateComponent) as AccountsCreateComponent;
            this.form = this._accountsCreateComponent.form;
        }

        this.langReload
            .pipe(startWith(''))
            .subscribe(() => {
                //let filter = any;
                let filter = { query: { relates_to: "Boats" } }
                this._dropdownsService.getMooringBoatCategories(filter)
                    .subscribe((data: any) => {
                        const boatCategories = map(data.body, (item) => {
                            item.label = item.value[this.systemLang];
                            return item;
                        });
                        this.boatCategories = boatCategories;
                    });
            });

        merge(this.countriesSearch.valueChanges)
            .pipe(
                startWith(''),
                tap(() => this.searching = true),
                switchMap(() => {
                    let search = this.form.get('boat_profile.countries').value;
                    if (this.countriesSearch.value) {
                        search = this.countriesSearch.value;
                    }
                    return this._dropdownsService.getCountries(search);
                })
            )
            .subscribe((data: any) => {
                this.searching = false;
                this.countries = data.docs;
            });
        merge(this.boatTypeOneSearch.valueChanges, this.form.get('boat_profile.type_one').valueChanges)
            .pipe(
                startWith(''),
                tap(() => this.searching = true),
                switchMap(() => {
                    let search = this.form.get('boat_profile.type_one').value;
                    if (this.boatTypeOneSearch.value) {
                        search = this.boatTypeOneSearch.value;
                    }
                    return this._gService.get(`boat_types/non-parent`, 'nodejs');
                })
            )
            .subscribe((data: any) => {
                this.searching = false;
                this.boatTypes = data;
                this.boatTypes = _.orderBy(data, [`value.${this.systemLang}`], ['asc']);
            });

        merge(this.boatTypeTwoSearch.valueChanges, this.form.get('boat_profile.type_one').valueChanges)
            .pipe(
                startWith(''),
                tap(() => this.searching = true),
                switchMap(() => {
                    let search = this.form.get('boat_profile.type_two').value;
                    if (this.boatTypeTwoSearch.value) {
                        search = this.boatTypeTwoSearch.value;
                    }
                    return this._gService.post(`boat_types/all`, { 'parent': this.form.get('boat_profile.type_one').value }, 'nodejs')
                })
            )
            .subscribe((data: any) => {
                this.searching = false;
                this.boatSubTypes = data;
                this.boatSubTypes = _.orderBy(data, [`value.${this.systemLang}`], ['asc']);
            });
            

        merge(this.regionsSearch.valueChanges, this.form.get('boat_profile.countries').valueChanges)
            .pipe(
                startWith(''),
                tap(() => this.searching = true),
                switchMap(() => {
                    let search = this.form.get('regions').value;
                    if (this.regionsSearch.value) {
                        search = this.regionsSearch.value;
                    }
                    return this._dropdownsService.getRegions(search, this.form.get('boat_profile.countries').value);
                })
            )
            .subscribe((data: any) => {
                this.searching = false;
                this.regions = data.docs;
            });

        merge(this.provincesSearch.valueChanges, this.form.get('boat_profile.regions').valueChanges)
            .pipe(
                startWith(''),
                tap(() => this.searching = true),
                switchMap(() => {
                    let search = this.form.get('boat_profile.provinces').value;
                    if (this.provincesSearch.value) {
                        search = this.provincesSearch.value;
                    }
                    return this._dropdownsService.getProvinces(search, false, this.form.get('boat_profile.regions').value);
                })
            )
            .subscribe((data: any) => {
                this.searching = false;
                this.provinces = data.docs;
            });

        merge(this.citiesSearch.valueChanges, this.form.get('boat_profile.provinces').valueChanges)
            .pipe(
                startWith(''),
                tap(() => this.searching = true),
                switchMap(() => {
                    let search = this.form.get('boat_profile.cities').value;
                    if (this.citiesSearch.value) {
                        search = this.citiesSearch.value;
                    }
                    return this._dropdownsService.getCities(search, false, false, this.form.get('boat_profile.provinces').value);
                })
            )
            .subscribe((data: any) => {
                this.searching = false;
                this.cities = data.docs;
            });

        this.form
            .get('boat_profile.transaction_types')
            .valueChanges.pipe(startWith(''))
            .subscribe(() => {
                this.buy = _.includes(
                    this.form.get('boat_profile.transaction_types').value,
                    'Buy'
                );
                this.rent = _.includes(
                    this.form.get('boat_profile.transaction_types').value,
                    'Rent'
                );
            });
            merge(this.HullMaterialSearch.valueChanges, this.form.get('boat_profile.hull_material').valueChanges)
            .pipe(
                startWith(''),
                tap(() => this.searching = true),
                switchMap(() => {
                    let search = this.form.get('boat_profile.hull_material').value;
                    if (this.boatTypeTwoSearch.value) {
                        search = this.boatTypeTwoSearch.value;
                    }
                    return this._gService.post(`hull_materials/all`, {search}, 'nodejs')
                })
            )
            .subscribe((data: any) => {
                this.searching = false;
                this.hullMaterialData = data;
            });

            merge(
                this.DriveTypeSearch.valueChanges,
                this.form.get('boat_profile.drive_type').valueChanges
              )
                .pipe(
                  startWith(''),
                  tap(() => (this.searching = true)),
                )
                .subscribe((data: any) => {
                  if (!this.allDriveType) {
                    return;
                  }
                  let search = this.form.get('boat_profile.drive_type').value;
                  if (this.DriveTypeSearch.value) {
                    search = this.DriveTypeSearch.value;
                  }
                  if (!search) {
                    this.filteredDriveTypes = this.allDriveType.slice();
                    return;
                  } else {
                    if (typeof (search) === 'number') { // when select option from item then key return
                      search = this.allDriveType.find(i => i.key == search).value[this.currentLang]
                    }
                    search = search.toLowerCase();
                  }
                  this.filteredDriveTypes = this.allDriveType.filter(type => type.value[this.currentLang].toLowerCase().indexOf(search) > -1);
                });
                merge(
                    this.FueltypeSearch.valueChanges,
                    this.form.get('boat_profile.fuel_type').valueChanges
                  )
                    .pipe(
                      startWith(''),
                      tap(() => (this.searching = true)),
                    )
                    .subscribe((data: any) => {
                      if (!this.allFuelType) {
                        return;
                      }
                      let search = this.form.get('boat_profile.fuel_type').value;
                      if (this.FueltypeSearch.value) {
                        search = this.FueltypeSearch.value;
                      }
                      if (!search) {
                        this.filteredFuelTypes = this.allFuelType.slice();
                        return;
                      } else {
                        if (typeof (search) === 'number') { // when select option from item then key return
                          search = this.allFuelType.find(i => i.key == search).value[this.currentLang]
                        }
                        search = search.toLowerCase();
                      }
                      this.filteredFuelTypes = this.allFuelType.filter(type => type.value[this.currentLang].toLowerCase().indexOf(search) > -1);
                    });
    }

    loadDropdowns(): any {
        this.transactionOptions = [
            {
                key: 'Buy',
                value: this._translateService.instant('Buy'),
            },
            {
                key: 'Rent',
                value: this._translateService.instant('Rent'),
            },
        ];
        this.transactionOptions = _.orderBy(
            this.transactionOptions,
            ['value'],
            ['asc']
        );
    }

    loadTypeTwo(parentValue: any) {
        if (parentValue)
            this.form.get('type_two').enable();
        else
            this.form.get('type_two').disable();
        this._gService.post(`boat_types/all`, { 'parent': parentValue }, 'nodejs')
            .subscribe((data: any) => {
                this.boatSubTypes = data;
            });
    }

    ngAfterViewChecked(): void {
        this.cdRef.detectChanges();
    }
}
