import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from 'environments/environment';
import { map, retry } from 'rxjs/operators';
import { GlobalFuntions } from 'app/_helpers';
import { isMoment } from 'moment';
@Injectable({
  providedIn: 'root'
})
export class AccountsService {
  private accountSearchFilter: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  public accountSearchData: Observable<any> = this.accountSearchFilter.asObservable();
  constructor(
    private http: HttpClient,
    private _globalFuntions: GlobalFuntions,
  ) { }
  public get getaccountSearchFilter(): any {
    return this.accountSearchFilter.value;
  }

  public setaccountSearchFilter(data: any): any {
      this.accountSearchFilter.next(data);
  }
  getAll(data: any, page: any, perPage?: any): Observable<object> {
    page = page ? page : 1;
    perPage = perPage ? perPage : 10;
    // tslint:disable-next-line: max-line-length
    return this.http.post<object>(`${environment.yiiUrl}accounts/search?expand=linked_company_data,users,agency_users,originated_by_name,favorites,language_data,assignedto_obj,updated_by_data,properties,activities_ids,owner&page=${page}&per-page=${perPage}`, data, { observe: 'response' as 'body' })
      .pipe(map(accounts => {
        return accounts;
      }));
  }
  getById(_id: string): Observable<object> {
    return this.http.get(`${environment.yiiUrl}accounts/${_id}?expand=linked_users_data`, {});
  }

  create(account: any): Observable<object> {
    const data = this._globalFuntions.cleanObject(account);
    return this.http.post(`${environment.yiiUrl}accounts`, data);
  }

  update(account: any): Observable<object> {
    // const data = this._globalFuntions.cleanObject(account);
    return this.http.put(`${environment.yiiUrl}accounts/${account._id}`, account);
  }

  archiveAccount(_id: number): Observable<object> {
    return this.http.get(`${environment.yiiUrl}accounts/status-update?account_id=${_id}&status=true`);
  }

  delete(_id: number): Observable<object> {
    return this.http.delete(`${environment.yiiUrl}accounts/${_id}`);
  }

  searchAgencies(page: string, data: any): Observable<any[]> {
    return this.http.post<any[]>(`${environment.yiiUrl}accounts/search?page=` + page, data, { observe: 'response' as 'body' })
      .pipe(map(accounts => {
        return accounts;
      }));
  }
  getFactivas(): Observable<object> {
    return this.http.get(`${environment.yiiUrl}factivas?page=1&account_id=undefined`);
  }
  addFavorites(modelId: string, modelName: string): Observable<object> {
    return this.http.get(`${environment.yiiUrl}accounts/add-favorites?model_id=${modelId}&model_name=${modelName}`);
  }
  removeFavorites(modelId: string): Observable<object> {
    return this.http.get(`${environment.yiiUrl}accounts/remove-favorites?model_id=${modelId}`);
  }
  getCsv(data: any): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}accounts/download-csv`, data);
  }
  massUpdate(data: any): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}accounts/mass-update`, data);
  }
  getBasketList(): Observable<any> {
    return this.http.get<any[]>(`${environment.yiiUrl}properties/get-buckets?model=accounts`, { observe: 'response' as 'body' });
  }
  deleteBucket(id: any): Observable<any> {
    return this.http.get<any[]>(`${environment.yiiUrl}properties/delete-bucket?id=${id}`, { observe: 'response' as 'body' });
  }
  saveBucket(data: any): Observable<any> {
    return this.http.post<any>(`${environment.yiiUrl}properties/save-bucket?name=${data.bucket_name}&model=accounts`, []);
  }

  excludeProperty(filter: any): Observable<any> {
    return this.http.post<any>(`${environment.yiiUrl}accounts/exclude-property`, filter);
  }

  excludeProperties(filter: any): Observable<any> {
    return this.http.post<any>(`${environment.yiiUrl}accounts/exclude-properties`, filter);
  }

  reIncludeProperty(filter: any): Observable<any> {
    return this.http.post<any>(`${environment.yiiUrl}accounts/reinclude-property`, filter);
  }

  getArchiveAccounts(filter: any, page?: any, perPage?: any): Observable<any> {
    page = page ? page : 1;
    perPage = perPage ? perPage : 10;
    return this.http.post<any>(`${environment.yiiUrl}accounts/deleted-accounts?page=${page}&expand=assigned_to_data&per-page=${perPage}`, filter, { observe: 'response' as 'body' });
  }
  restoreArchiveAccount(_id: number): Observable<object> {
    return this.http.get(`${environment.yiiUrl}accounts/status-update?account_id=${_id}&status=false`);
  }

  deleteArchiveAccount(_id: number, createdby: any, agency: any): Observable<object> {
    return this.http.delete(`${environment.yiiUrl}accounts/${_id}?created_by=${createdby}&status=&assigned_to=&agency=${agency}`);
  }

  restoreSelectedAccounts(filter: any): Observable<any> {
    return this.http.post<any>(`${environment.yiiUrl}accounts/restore-selected`, filter);
  }

  deleteSeletedArchiveAccounts(filter: any): Observable<object> {
    return this.http.post(`${environment.yiiUrl}accounts/delete-selection`, filter);
  }

  restoreAll(agency: any): Observable<object> {
    return this.http.get(`${environment.yiiUrl}accounts/restore-all?agency=${agency}`);
  }

  deleteAll(agency: any): Observable<object> {
    return this.http.delete(`${environment.yiiUrl}accounts/delete-all?agency=${agency}`);
  }
  insertCoustomOwner(data: any): Observable<object> {
    return this.http.post(`${environment.yiiUrl}owners/insert`, data);
  }

  deleteSelected(ids: any): Observable<object> {
    return this.http.post(`${environment.yiiUrl}accounts/update-all-accounts-status`, ids);
  }
  getTodayLeads(property: any, type: any): Observable<object> {
    return this.http.get(`${environment.yiiUrl}accounts/today-leads?property_reference=${property}&type=${type}`);
  }
  getExcludedCommercial(accountId: any): Observable<object> {
    return this.http.get(`${environment.yiiUrl}accounts/excluded-commercial?id=${accountId}`, { observe: 'response' as 'body' })
      .pipe(map(data => {
        return data;
      }));
  }
  getAllAccounts(page?: any , filter?: any, pagination?: any): Observable<object> {
    return this.http.post(`${environment.yiiUrl}accounts/search?expand=linked_company_data,users,agency_users,originated_by_name,favorites,language_data,
    assignedto_obj,updated_by_data,properties,activities_ids,owner&page=${page}'&pagination=${pagination}`, filter);
  }
  exportAttachments(data: any): Observable<any> {
    return this.http.post(`${environment.yiiUrl}accounts/export-attachments-xml`, data);
  }
}
