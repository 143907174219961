
import { fuseAnimations } from '@fuse/animations';
import { Component, OnInit, ViewEncapsulation, Input, Injector, AfterViewChecked, ChangeDetectorRef, EventEmitter } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { DropdownsService } from 'app/services';
import { TranslateService } from '@ngx-translate/core';
import { merge, Subject } from 'rxjs';
import * as _ from 'lodash';
import { startWith, tap, switchMap, distinctUntilChanged, delay, takeUntil } from 'rxjs/operators';
import { AccountsCreateComponent } from '../../accounts-create.component';
import { FuseUtils } from '@fuse/utils';
import { map } from 'lodash';
@Component({
  selector: 'mooring-profile',
  templateUrl: './mooring-profile.component.html',
  styleUrls: ['./mooring-profile.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class MooringProfileComponent implements OnInit {
  @Input() form: FormGroup;
  currentLang: string;
  transactionOptions = [];
  searchPropertyTypes$ = new Subject<string>();
  propertyTypes: any;
  buy: boolean;
  rent: boolean;
  systemLang: any;
  searching: boolean;
  countries: any;
  search = '';
  countriesSearch: FormControl = new FormControl('');
  regions: any;
  regionsSearch: FormControl = new FormControl('');
  provinces: any;
  provincesSearch: FormControl = new FormControl('');
  cities: any;
  citiesSearch: FormControl = new FormControl('');
  mooringCategories: any;
  private _unsubscribeAll: Subject<any>;
  _accountsCreateComponent: AccountsCreateComponent;
  langReload = new EventEmitter<any>();
  constructor(
    private _dropdownsService: DropdownsService,
    private _translateService: TranslateService,
    private injector: Injector,
    private cdRef: ChangeDetectorRef
  ) {
    this.systemLang = this._translateService.currentLang;
    this._unsubscribeAll = new Subject();
   }

  ngOnInit(): void {
    this._translateService.onLangChange
    .pipe(startWith(''), takeUntil(this._unsubscribeAll))
    .subscribe(() => {
        this.systemLang = this._translateService.currentLang;
        this.currentLang =
            this._translateService.currentLang === 'es'
                ? 'es_AR'
                : this._translateService.currentLang;
        this.loadDropdowns();
      this.langReload.next(true);
    });
    if (!this.form) {
      this._accountsCreateComponent = this.injector.get(AccountsCreateComponent) as AccountsCreateComponent;
      this.form = this._accountsCreateComponent.form;
  }

  this.langReload
      .pipe(startWith(''))
      .subscribe(() => {
          //let filter = any;
          let filter = { query: { relates_to: "Moorings"} }
          this._dropdownsService.getMooringBoatCategories(filter)
              .subscribe((data: any) => {
                  const mooringCategories = map(data.body, (item) => {
                      item.label = item.value[this.systemLang];
                      return item;
                  });
                  this.mooringCategories = mooringCategories;
              });
      });

  merge(this.countriesSearch.valueChanges)
      .pipe(
          startWith(''),
          tap(() => this.searching = true),
          switchMap(() => {
              let search = this.form.get('mooring_profile.countries').value;
              if (this.countriesSearch.value) {
                  search = this.countriesSearch.value;
              }
              return this._dropdownsService.getCountries(search);
          })
      )
      .subscribe((data: any) => {
          this.searching = false;
          this.countries = data.docs;
      });

  merge(this.regionsSearch.valueChanges, this.form.get('mooring_profile.countries').valueChanges)
      .pipe(
          startWith(''),
          tap(() => this.searching = true),
          switchMap(() => {
              let search = this.form.get('regions').value;
              if (this.regionsSearch.value) {
                  search = this.regionsSearch.value;
              }
              return this._dropdownsService.getRegions(search, this.form.get('mooring_profile.countries').value);
          })
      )
      .subscribe((data: any) => {
          this.searching = false;
          this.regions = data.docs;
      });

  merge(this.provincesSearch.valueChanges, this.form.get('mooring_profile.regions').valueChanges)
      .pipe(
          startWith(''),
          tap(() => this.searching = true),
          switchMap(() => {
              let search = this.form.get('mooring_profile.provinces').value;
              if (this.provincesSearch.value) {
                  search = this.provincesSearch.value;
              }
              return this._dropdownsService.getProvinces(search, false, this.form.get('mooring_profile.regions').value);
          })
      )
      .subscribe((data: any) => {
          this.searching = false;
          this.provinces = data.docs;
      });

  merge(this.citiesSearch.valueChanges, this.form.get('mooring_profile.provinces').valueChanges)
      .pipe(
          startWith(''),
          tap(() => this.searching = true),
          switchMap(() => {
              let search = this.form.get('mooring_profile.cities').value;
              if (this.citiesSearch.value) {
                  search = this.citiesSearch.value;
              }
              return this._dropdownsService.getCities(search, false, false, this.form.get('mooring_profile.provinces').value);
          })
      )
      .subscribe((data: any) => {
          this.searching = false;
          this.cities = data.docs;
      });

      this.form
      .get('mooring_profile.transaction_types')
      .valueChanges.pipe(startWith(''))
      .subscribe(() => {
          this.buy = _.includes(
              this.form.get('mooring_profile.transaction_types').value,
              'Buy'
          );
          this.rent = _.includes(
              this.form.get('mooring_profile.transaction_types').value,
              'Rent'
          );
      });
  }

  loadDropdowns(): any {
    this.transactionOptions = [
      {
          key: 'Buy',
          value: this._translateService.instant('Buy'),
      },
      {
          key: 'Rent',
          value: this._translateService.instant('Rent'),
      },
  ];
  this.transactionOptions = _.orderBy(
      this.transactionOptions,
      ['value'],
      ['asc']
  );
}

ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
}

}
